import React, { useEffect, useState } from 'react'
import SideBar from './SideBar';
import { useParams } from 'react-router-dom';
import loader from "../assets/images/loader.gif";
import { ReactComponent as Add } from "../assets/images/add.svg"
import JoditEditor from "jodit-react";
import ColorPickar from '../helperComponent/ColorPickar';
import AlertDialog from '../helperComponent/AlertDialog';
import { ReactComponent as Apple } from "../assets/images/apple.svg"
import { ReactComponent as Desktop } from "../assets/images/desktop.svg"
import { ReactComponent as Android } from "../assets/images/android.svg"
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import axiosClient from '../axios';
import { ReactComponent as Picadd } from "../assets/images/newAddPic.svg";
import Dialog from '@mui/material/Dialog';
import { ReactComponent as Edit } from "../assets/images/edit.svg";
import { ReactComponent as Delete } from "../assets/images/delete.svg"
import Slide from '@mui/material/Slide';
import { borderColor } from '@mui/system';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function EditPartner() {
    const { id } = useParams();
    const [partner, setPartner] = useState({
        name: "",
        trackLink: "",
        is_active: true,
        image: "",
        // recommendedImage: "",
        guaranteedImage: "",
        borderColor: "",
        is_static: false,
        is_popular: false,
        is_bonus: false,
        is_recommended: false,
        is_guaranteed: false,
        removal_limit: 0,
        removal_limit_gap: 48,
        recommended_screen: [1, 2, 3],
        description: "",
        on_mobile: true,
        partner_type: [1, 2, 3, 4],
        ratings: {
            "$numberDecimal": 5
        },
        showDetails: false,
        details: [],
        video: {
            videoId: "",
            link: ""
        },
        position: 0,
        swatToPosition: 0,
        open_value: 0,
        limit: 100,
        daily_limit: 0,
        amount_limit: 0,
        bgColor: ""
    })
    const [loading, setLoading] = useState(false)
    const [open, setOpen] = useState(false)
    const [detail, setDetail] = useState({})
    const [index, setIndex] = useState(0)
    const [editable, setEditbale] = useState(false)
    const options = [
        { value: 1, label: 'Offer', image: <Android /> },
        { value: 2, label: 'Trending', image: <Desktop /> },
        { value: 3, label: 'Games', image: <Apple /> }
    ];

    const [alertDialogOpen, setAlertDialogOpen] = useState(false)
    const [dialogMessage, setDialogMessage] = useState('');
    const [showAlertSign, setShowAlertSigh] = useState(false)



    const [colors, setColors] = useState({
        color1: "#2b579a",
        color2: "#2b579a"
    })

    const handleBorderColor = (event) => {
        setPartner({ ...partner, borderColor: event.target.value })
    };

    console.log("partner.bordercolor", partner.borderColor)


    const handlefileInput = async (e, type) => {
        const photo = new FormData();
        const selectedFile = e.target.files[0];

        const fileSizeInMb = selectedFile.size / (1024 * 1024);
        if (fileSizeInMb > 7) {
            return alert('File size is too large. Please choose a file smaller than 7 MB.');
        } else {
            photo.append("photo", selectedFile);

            await axiosClient
                .post(`/uploadImage`, photo)
                .then((res) => {
                    // setPartner({ ...partner, image: res.data.response.url })
                    if (type == 1) {
                        setPartner({ ...partner, image: res.data.response.url })
                    }
                    else if (type == 2) {
                        // setPartner({ ...partner, recommendedImage: res.data.response.url })
                        setPartner({ ...partner, guaranteedImage: res.data.response.url })
                    }
                })
                .catch((err) => console.log(err));
        }
    };

    const editPartner = async () => {
        setLoading(true)
        const formData = {
            id: id,
            name: partner.name,
            trackLink: partner.trackLink,
            is_active: partner.is_active,
            image: partner.image,
            // recommendedImage: partner.recommendedImage,
            guaranteedImage: partner.guaranteedImage,
            borderColor: partner.borderColor,
            singleOffer: partner.singleOffer,
            on_mobile: partner.on_mobile,
            partner_type: partner.partner_type,
            ratings: partner?.ratings?.$numberDecimal,
            is_popular: partner.is_popular,
            description: partner.description,
            is_static: partner.is_static,
            details: partner.details,
            showDetails: partner.showDetails,
            video: partner.video,
            is_recommended: partner.is_recommended,
            is_bonus: partner.is_bonus,
            is_guaranteed: partner.is_guaranteed,
            recommended_screen: partner.recommended_screen,
            position: partner.position,
            swatToPosition: partner.swatToPosition,
            open_value: partner.open_value,
            limit: partner.limit,
            daily_limit: partner.daily_limit,
            amount_limit: partner.amount_limit,
            removal_limit: partner.removal_limit,
            removal_limit_gap: partner.removal_limit_gap,
            // bgColor: partner.bgColor
        }

        if (formData.showDetails) {
            if (formData.details.length == 0) {
                // alert("Please Enter Heading And Data In Detail")
                setDialogMessage('Please Enter Heading And Data In Detail');
                setAlertDialogOpen(true);
                setLoading(false)
                return
            }
        }

        if (!formData?.name || !formData?.trackLink || !formData.image) {
            // alert("Please Enter All Field Value");
            setDialogMessage('Please Enter All Field Value');
            setAlertDialogOpen(true);
            setLoading(false)
            return
        }

        if (formData.ratings > 5) {
            // alert("Ratings Can Not Be Given More Than 5");
            setDialogMessage('Ratings Can Not Be Given More Than 5');
            setAlertDialogOpen(true);

            setLoading(false)
            return
        }

        if (Number(formData?.amount_limit) < 0) {
            // alert("Amount Limit is always grater then 0")
            setDialogMessage('Amount Limit is always grater then 0');
            setAlertDialogOpen(true);

            setLoading(false)
            return
        }
        if (Number(formData?.daily_limit) < 0) {
            // alert("daily Limit is always grater then 0")
            setDialogMessage('daily Limit is always grater then 0');
            setAlertDialogOpen(true);
            setLoading(false)
            return
        }
        if (Number(formData?.limit) > 100) {
            // alert("Confirm Limit Can not be grater then 100")
            setDialogMessage('Confirm Limit Can not be grater then 100');
            setAlertDialogOpen(true);
            setLoading(false)
            return
        }

        if (!formData?.removal_limit_gap) {
            // alert("Please Enter Removal limit Gap")
            setDialogMessage('Please Enter Removal limit Gap');
            setAlertDialogOpen(true);
            setLoading(false)
            return
        }

        // formData.bg_color = [color1, color2]
        formData.bg_color = [colors?.color1, colors?.color2]


        await axiosClient.post("/editPartner", formData)
            .then((res) => {
                if (res.data.status === true) {
                    setShowAlertSigh(true)
                    setDialogMessage('Partner edited successfully');
                    setAlertDialogOpen(true);
                    // alert("Partner Edited successfylly")
                    getPartners()
                }
            })
            .catch((err) => console.log("error", err))
            .finally(() => setLoading(false))
    }

    const handleSelectChange = (event) => {
        setPartner({ ...partner, recommended_screen: event.target.value })
    };

    const getPartners = () => {
        setLoading(true)
        let start = null
        let end = null
        axiosClient
            .get(`/partner/${id}?&s_date=${start}&e_date=${end}`)
            .then((res) => {
                console.log("response", res)
                setPartner({
                    name: res?.data?.partnerData?.name,
                    trackLink: res?.data?.partnerData?.trackLink,
                    is_active: res?.data?.partnerData?.is_active,
                    image: res?.data?.partnerData?.image,
                    guaranteedImage: res?.data?.partnerData?.guaranteedImage,
                    borderColor: res?.data?.partnerData?.borderColor,
                    is_static: res?.data?.partnerData?.is_static,
                    is_popular: res?.data?.partnerData?.is_popular,
                    is_recommended: res?.data?.partnerData?.is_recommended,
                    is_bonus: res?.data?.partnerData?.is_bonus,
                    is_guaranteed: res?.data?.partnerData?.is_guaranteed,
                    recommended_screen: res?.data?.partnerData?.recommended_screen,
                    description: res?.data?.partnerData?.description,
                    on_mobile: res?.data?.partnerData?.on_mobile,
                    partner_type: res?.data?.partnerData?.partner_type,
                    ratings: res?.data?.partnerData?.ratings,
                    showDetails: res?.data?.partnerData?.showDetails || false,
                    details: res?.data?.partnerData?.details || [],
                    video: res?.data?.partnerData?.video,
                    position: res?.data?.partnerData.position,
                    swatToPosition: res?.data?.partnerData.swatToPosition,
                    open_value: res?.data?.partnerData?.open_value,
                    limit: res?.data?.partnerData?.earning_info?.limit,
                    daily_limit: res?.data?.partnerData?.earning_info?.daily_limit,
                    amount_limit: res?.data?.partnerData?.earning_info?.amount_limit,
                    removal_limit: res?.data?.partnerData?.removal_limit,
                    removal_limit_gap: res?.data?.partnerData?.removal_limit_gap,
                    bg_color: res?.data?.partnerData?.bgColor
                })
                // setColor1(res?.data?.partnerData?.bg_color[0])
                // setColor2(res?.data?.partnerData?.bg_color[1])

                setColors({ ...colors, color1: res?.data?.partnerData?.bg_color[0], color2: res?.data?.partnerData?.bg_color[1] })



            })
            .catch((error) =>
                console.log(error)
            )
            .finally(() => setLoading(false))
    }

    const handleClose = () => {
        setOpen(false)
        setEditbale(false)
    }


    const handleDetails = () => {
        if (!detail?.heading || !detail?.data || detail?.data == "<p><br></p>") {
            alert("Please Enter Both Field value");
            return
        }
        const updatedDetails = [...partner.details]
        updatedDetails[index] = detail
        setPartner({
            ...partner,
            details: updatedDetails
        });
        setOpen(false)
        setDetail({
            "heading": "",
            "data": ""
        })
        setEditbale(false)
    }

    const handleAddDetails = () => {
        console.log("detail?.data", typeof detail?.data, detail?.data)
        if (!detail?.heading || !detail?.data || detail?.data == "<p><br></p>") {
            alert("Please Enter Both Field value");
            return
        }

        partner.details.push(detail)
        setOpen(false)
        setDetail({
            "heading": "",
            "data": ""
        })

    }

    const handleDeleteDetails = (index) => {
        const partneredDetails = [...partner.details]
        partneredDetails.splice(index, 1);
        setPartner({ ...partner, details: partneredDetails })
    }



    const handleCheckboxChange = (value) => {
        if (partner.partner_type.includes(value)) {
            setPartner({
                ...partner,
                partner_type: partner.partner_type.filter(val => val !== value)
            });
        } else {
            setPartner({
                ...partner,
                partner_type: [...partner.partner_type, value]
            });
        }
    };

    const renderCheckbox = (value, label) => (
        <React.Fragment key={value}>
            <input
                type="checkbox"
                checked={partner?.partner_type?.includes(value)}
                className="circle"
                onChange={() => handleCheckboxChange(value)}
                value={value}
            />
            <label>{label}</label>
        </React.Fragment>
    );








    useEffect(() => {
        getPartners()
    }, [])

    return (
        <>
            <SideBar handleSideBar={true} activeTab={"partners"} />
            <div className="screen">
                <div className='dFlex adminPageButton'>
                    <h2 className='partnerHeading'>Edit Partner</h2>
                </div>
                {loading ?
                    <img src={loader} alt="" className="loader" /> :
                    <>
                        <div className='labelandInput'>
                            <div className='labelClass'>
                                <label>Name </label>
                                <input
                                    type='text'
                                    name="name"
                                    onChange={(e) => {
                                        setPartner({ ...partner, name: e.target.value })
                                    }}
                                    value={partner?.name}
                                    placeholder={"name"}
                                    className="inputfield"
                                />
                            </div>
                            <div className='labelClass'>
                                <label>
                                    Track Link
                                </label>
                                <input
                                    type='text'
                                    name="trackLink"
                                    onChange={(e) => {
                                        setPartner({ ...partner, trackLink: e.target.value })
                                    }}
                                    value={partner.trackLink}
                                    placeholder={'tracklink'}
                                    className="inputfield"
                                />
                            </div>
                            <div className='labelClass'>
                                <label>
                                    Description
                                </label>
                                <input
                                    type='text'
                                    name="description"
                                    onChange={(e) => {
                                        setPartner({ ...partner, description: e.target.value })
                                    }}
                                    value={partner.description}
                                    placeholder={'description'}
                                    className="inputfield"
                                />
                            </div>

                            <div className='labelClass'>
                                <label>
                                    Rating
                                </label>
                                <input
                                    type='text'
                                    name="ratings"
                                    onChange={(e) => {
                                        console.log("e", e.target.value)
                                        setPartner({ ...partner, ratings: { "$numberDecimal": e.target.value } })

                                    }}
                                    value={partner?.ratings?.$numberDecimal}
                                    placeholder={'Enter Rating'}
                                    className="inputfield"
                                />
                            </div>
                            <div className='labelClass'>
                                <label>
                                    Open Value
                                </label>
                                <input
                                    type='number'
                                    name="ovenValue"
                                    onChange={(e) => {
                                        setPartner({ ...partner, open_value: Number(e.target.value) })
                                    }}
                                    value={partner?.open_value}
                                    placeholder={'Enter Unlock Value'}
                                    className="inputfield"
                                />
                            </div>

                            <div className='labelClass'>
                                <label>
                                    Daily Amount Limit
                                </label>
                                <input
                                    type='number'
                                    name="dailyAmount"
                                    onChange={(e) => {
                                        setPartner({ ...partner, amount_limit: Number(e.target.value) })
                                    }}
                                    value={partner?.amount_limit}
                                    placeholder={'Amount Limit'}
                                    className="inputfield"
                                />
                            </div>

                            <div className='labelClass'>
                                <label>
                                    Removal Limit
                                </label>
                                <input
                                    type='number'
                                    name="removal_limit"
                                    onChange={(e) => {
                                        setPartner({ ...partner, removal_limit: Number(e.target.value) })
                                    }}
                                    value={partner?.removal_limit}
                                    placeholder={'Removal Limit'}
                                    className="inputfield"
                                />
                            </div>

                            <div className='labelClass'>
                                <label>
                                    Removal Limit Gap
                                </label>
                                <input
                                    type='number'
                                    name="removal_limit_gap"
                                    onChange={(e) => {
                                        setPartner({ ...partner, removal_limit_gap: Number(e.target.value) })
                                    }}
                                    value={partner?.removal_limit_gap}
                                    placeholder={'Removal Limit Gap'}
                                    className="inputfield"
                                />
                            </div>

                            <div className='labelClass'>
                                <label>
                                    Daily Transaction Limit
                                </label>
                                <input
                                    type='number'
                                    name="dailyTransaction"
                                    onChange={(e) => {
                                        setPartner({ ...partner, daily_limit: Number(e.target.value) })
                                    }}
                                    value={partner?.daily_limit}
                                    placeholder={'Transaction Limit'}
                                    className="inputfield"
                                />
                            </div>

                            <div className='labelClass'>
                                <label>
                                    Confirm Limit
                                </label>
                                <input
                                    type='number'
                                    name="confirmlimit"
                                    onChange={(e) => {
                                        setPartner({ ...partner, limit: Number(e.target.value) })
                                    }}
                                    value={partner?.limit}
                                    placeholder={'Confirm Limit'}
                                    className="inputfield"
                                />
                            </div>

                            <div className='labelClass'>
                                <label>Popular </label>
                                <input
                                    type="radio"
                                    checked={partner.is_popular == true ? true : false}
                                    onChange={(e) => {
                                        setPartner({ ...partner, is_popular: e.target.value == "true" ? true : false })
                                    }}
                                    className="circle"
                                    value={true}
                                />
                                <span className='inline'>True</span>
                                <input
                                    type="radio"
                                    checked={partner.is_popular == false ? true : false}
                                    onChange={(e) => {
                                        setPartner({ ...partner, is_popular: e.target.value == "false" ? false : true })
                                    }}
                                    className="circle"
                                    value={false}
                                />
                                <span className='inline'>False</span>
                            </div>

                            <div className='labelClass'>
                                <label htmlFor="">Active </label>
                                <input
                                    type="radio"
                                    checked={partner.is_active == true ? true : false}
                                    onChange={(e) => {
                                        setPartner({ ...partner, is_active: e.target.value == "true" ? true : false })

                                    }}
                                    className="circle"
                                    value={true}
                                />
                                <span className='inline'>True</span>
                                <input
                                    type="radio"
                                    checked={partner.is_active == false ? true : false}
                                    onChange={(e) => {
                                        setPartner({ ...partner, is_active: e.target.value == "false" ? false : true })
                                    }}
                                    className="circle"
                                    value={false}
                                />
                                <span className='inline'>False</span>
                            </div>

                            <div className='labelClass'>
                                <label>
                                    Single Offer
                                </label>
                                <input
                                    type="radio"
                                    checked={partner.is_static === true ? true : false}
                                    onChange={(e) => {
                                        setPartner({ ...partner, is_static: e.target.value == "true" ? false : true })
                                    }}
                                    className="circle"
                                    value={partner.is_static}
                                />
                                <span className='inline'>True </span>
                                <input
                                    type="radio"
                                    checked={partner.is_static === false ? true : false}
                                    onChange={(e) => {
                                        setPartner({ ...partner, is_static: e.target.value == "false" ? false : true })
                                    }}
                                    className="circle"
                                    value={!partner.is_static}
                                />
                                <span className='inline'>False</span>
                            </div>


                            <div className='labelClass'>
                                <label htmlFor="">Only for Mobile </label>
                                <input
                                    type="radio"
                                    checked={partner.on_mobile === true ? true : false}
                                    onChange={(e) => {
                                        setPartner({ ...partner, on_mobile: e.target.value == "true" ? true : false })
                                    }}
                                    className="circle"
                                    value={true}
                                />
                                <span className='inline'>True</span>
                                <input
                                    type="radio"
                                    checked={partner.on_mobile === false ? true : false}
                                    onChange={(e) => {
                                        setPartner({ ...partner, on_mobile: e.target.value == "false" ? false : true })
                                    }}
                                    className="circle"
                                    value={false}
                                />
                                <span className='inline'>False</span>
                            </div>


                            <div className='labelClass'>
                                <label htmlFor="">Is Recommended</label>
                                <input
                                    type="radio"
                                    checked={partner?.is_recommended == true ? true : false}
                                    onChange={(e) => {
                                        setPartner({ ...partner, is_recommended: e.target.value == "true" ? true : false })
                                    }}
                                    className="circle"
                                    value={true}
                                />
                                <span className='inline'>True</span>
                                <input
                                    type="radio"
                                    checked={partner?.is_recommended == false ? true : false}
                                    onChange={(e) => {
                                        setPartner({ ...partner, is_recommended: e.target.value == "false" ? false : true })
                                    }}
                                    className="circle"
                                    value={false}
                                />
                                <span className='inline'>False</span>
                            </div>

                            <div className='labelClass'>
                                <label htmlFor="">Is Guaranteed</label>
                                <input
                                    type="radio"
                                    checked={partner?.is_guaranteed == true ? true : false}
                                    onChange={(e) => {
                                        setPartner({ ...partner, is_guaranteed: e.target.value == "true" ? true : false })
                                    }}
                                    className="circle"
                                    value={true}
                                />
                                <span className='inline'>True</span>
                                <input
                                    type="radio"
                                    checked={partner?.is_guaranteed == false ? true : false}
                                    onChange={(e) => {
                                        setPartner({ ...partner, is_guaranteed: e.target.value == "false" ? false : true })
                                    }}
                                    className="circle"
                                    value={false}
                                />
                                <span className='inline'>False</span>
                            </div>

                            <div className='labelClass'>
                                <label htmlFor="">Is Bonus</label>
                                <input
                                    type="radio"
                                    checked={partner?.is_bonus == true ? true : false}
                                    onChange={(e) => {
                                        setPartner({ ...partner, is_bonus: e.target.value == "true" ? true : false })
                                    }}
                                    className="circle"
                                    value={true}
                                />
                                <span className='inline'>True</span>
                                <input
                                    type="radio"
                                    checked={partner?.is_bonus == false ? true : false}
                                    onChange={(e) => {
                                        setPartner({ ...partner, is_bonus: e.target.value == "false" ? false : true })
                                    }}
                                    className="circle"
                                    value={false}
                                />
                                <span className='inline'>False</span>
                            </div>

                            <div className='labelClass'>
                                <label > Recommended Screen</label>
                                <FormControl>
                                    <Select
                                        className='selectBox'
                                        multiple
                                        value={partner?.recommended_screen}
                                        onChange={handleSelectChange}
                                        renderValue={(selected) => (
                                            <div>
                                                {selected.map((value) => {
                                                    const option = options.find((option) => option.value === value);
                                                    return (
                                                        <React.Fragment key={value}>
                                                            <span className='optionImage'>
                                                                {option.image}
                                                            </span>
                                                        </React.Fragment>
                                                    );
                                                })}
                                            </div>
                                        )}
                                    >
                                        {options.map((option) => (
                                            <MenuItem key={option.value} value={option.value} className="svgApple">
                                                {option.image}
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>




                            <div className='labelClass'>
                                <label>Partner Type</label>
                                {renderCheckbox(1, "offerwall")}
                                {renderCheckbox(2, "Surveywall")}
                                {renderCheckbox(3, "Games")}
                                {renderCheckbox(4, "PTC Walls")}
                            </div>

                            {/* <div className='labelClass'>
                                <label>
                                    Partner Type
                                </label>
                                <input
                                    type="radio"
                                    checked={partner.partner_type == 1 ? true : false}
                                    onChange={(e) => {
                                        setPartner({ ...partner, partner_type: e.target.value })
                                    }}
                                    className="circle"
                                    value={1}
                                />
                                <span className='inline'>offerwall </span>
                                <input
                                    type="radio"
                                    checked={partner.partner_type == 2 ? true : false}
                                    onChange={(e) => {
                                        console.log("e", e.target.value)
                                        setPartner({ ...partner, partner_type: e.target.value })
                                    }}
                                    className="circle"
                                    value={2}
                                />
                                <span className='inline'>Surveywall</span>

                                <input
                                    type="radio"
                                    checked={partner.partner_type == 3 ? true : false}
                                    onChange={(e) => {
                                        setPartner({ ...partner, partner_type: e.target.value })
                                    }}
                                    className="circle"
                                    value={3}
                                />
                                <span className='inline'>Games</span>

                                <input
                                    type="radio"
                                    checked={partner.partner_type == 4 ? true : false}
                                    onChange={(e) => {
                                        setPartner({ ...partner, partner_type: e.target.value })
                                    }}
                                    className="circle"
                                    value={4}
                                />
                                <span className='inline'>PTC Walls</span>
                            </div> */}


                            <div className='labelClass border-color'>
                                <label>
                                    Border Color
                                </label>

                                <div className='picker-section-color-single'>
                                    <label htmlFor="colorPicker2">Choose  color</label>
                                    <input
                                        type="color"
                                        id="colorPicker2"
                                        name="colorPicker2"
                                        value={partner?.borderColor}
                                        onChange={handleBorderColor}
                                    />
                                </div>

                                <div style={{ background: "red", background: `${partner?.borderColor}` }} className='linear_gradient-single'>
                                </div>

                            </div>



                            <ColorPickar
                                colors={colors}
                                setColors={setColors}
                                heading={"Recommended Color"}

                            />

                            <div className='labelClass'>
                                <label>
                                    Current Position
                                </label>
                                <input
                                    type="text"
                                    disabled
                                    onChange={(e) => {
                                        setPartner({ ...partner, position: e.target.value })
                                    }}
                                    className="swapPosition"
                                    placeholder='Current Position'
                                    value={partner.position}
                                />

                                <label className='swapLabel'>
                                    Swap to
                                </label>

                                <input
                                    type="text"
                                    onChange={(e) => {
                                        setPartner({ ...partner, swatToPosition: e.target.value })
                                    }}
                                    className="swapPosition"
                                    placeholder='Enter Swap Position'
                                />

                            </div>


                            <div className='labelClass'>
                                <label>
                                    Guaranteed Image
                                </label>
                                <label htmlFor="inputTagField-recommedded">
                                    <Picadd className="addImgae" />
                                    <input
                                        type="file"
                                        id="inputTagField-recommedded"
                                        name="photo"
                                        onChange={(e) => handlefileInput(e, 2)}
                                        multiple
                                        className="filetag"
                                    />
                                </label>
                                {partner.guaranteedImage !== "" ?
                                    <>
                                        <div >
                                            <img src={partner.guaranteedImage} className='addpartnerImage' alt="Partners Icon" />
                                        </div>
                                    </> : null}
                            </div>


                            <div className='labelClass'>
                                <label>
                                    Add Image
                                </label>
                                <label htmlFor="inputTagField">
                                    <Picadd className="addImgae" />
                                    <input
                                        type="file"
                                        id="inputTagField"
                                        name="photo"
                                        onChange={(e) => handlefileInput(e, 1)}
                                        multiple
                                        className="filetag"
                                    />
                                </label>
                                {partner.image !== "" ?
                                    <>
                                        <div  >
                                            <img src={partner?.image} className='addpartnerImage' alt="Partners Icon" />
                                        </div>
                                    </> : null}
                            </div>


                            <div className='labelClass'>
                                <label>
                                    showDetails
                                </label>
                                <input
                                    type="radio"
                                    checked={partner.showDetails === true ? true : false}
                                    onChange={(e) => {
                                        setPartner({ ...partner, showDetails: e.target.value == "true" ? true : false })
                                    }}
                                    className="circle"
                                    value={true}
                                />
                                <span className='inline'>True </span>
                                <input
                                    type="radio"
                                    checked={partner.showDetails === false ? true : false}
                                    onChange={(e) => {
                                        setPartner({ ...partner, showDetails: e.target.value == "false" ? false : true })
                                    }}
                                    className="circle"
                                    value={false}
                                />
                                <span className='inline'>False</span>
                            </div>


                            {partner.showDetails && <div className='labelClass'>

                                <p className='rewards'>Details <Add className='addReward' onClick={() => { setOpen(true); setDetail({}) }} /></p>
                                <div className='flexRow'>
                                    <h3>Heading</h3>
                                    <h3>Data</h3>
                                    <h3>Edit</h3>
                                    <h3>Delete</h3>
                                </div>
                                {
                                    partner?.details?.map((e, i) => {
                                        return (<React.Fragment key={i}>
                                            <div className='flexRow detailsFlexRow'>
                                                <h3>{e?.heading}</h3>
                                                <h3>{e?.data}</h3>
                                                <h3>
                                                    <Edit onClick={() => { setDetail(e); setOpen(true); setIndex(i); setEditbale(true) }} />
                                                </h3>
                                                <Delete onClick={() => { handleDeleteDetails(i) }} />

                                            </div>
                                        </React.Fragment>)
                                    })
                                }
                            </div>}

                            {partner.showDetails && <>
                                <div className='labelClass'>
                                    <label>video Id </label>
                                    <input
                                        type='video'
                                        name="name"
                                        onChange={(e) => {
                                            setPartner({ ...partner, video: { ...partner.video, videoId: e.target.value } })
                                        }}
                                        value={partner?.video?.videoId}
                                        placeholder={' Enter video Id'}
                                        className="inputfield"
                                    />
                                </div>

                                <div className='labelClass'>
                                    <label>video Link </label>
                                    <input
                                        type='text'
                                        name="name"
                                        onChange={(e) => {
                                            setPartner({ ...partner, video: { ...partner.video, link: e.target.value } })
                                        }}
                                        value={partner?.video?.link}
                                        placeholder={'Enter Video Link'}
                                        className="inputfield"
                                    />
                                </div>
                            </>}
                        </div>
                        <button className='tSubnitButton partnerSubmit' onClick={() => editPartner()} >Submit</button>
                    </>}
            </div>


            <Dialog
                open={open}
                onClose={() => handleClose()}
                TransitionComponent={Transition}
                className="dialogs"
            >
                <div className='categoryEditBox'>
                    <h3 className='addRew'> Add Details</h3>

                    <div className='editCover fullCover'>
                        <p>
                            Heading
                        </p>
                        <input
                            name="level"
                            placeholder={'Enter Heading'}
                            onChange={(e) => {
                                setDetail({ ...detail, heading: e.target.value })
                            }}
                            type="text"
                            value={detail.heading}
                            className="inputTag"
                        />
                    </div>

                    <div className='editCover editBio'>
                        <p>
                            Data
                        </p>
                        <div className="pRelative">
                            <JoditEditor
                                value={detail.data}
                                onChange={(newContent) =>
                                    setDetail({ ...detail, data: newContent })
                                }
                            />
                        </div>
                    </div>

                    <div className='addButton' onClick={() => { editable ? handleDetails() : handleAddDetails() }}>Save</div>
                </div>
            </Dialog>


            <AlertDialog
                alertDialogOpen={alertDialogOpen}
                setAlertDialogOpen={setAlertDialogOpen}
                msg={dialogMessage}
                showAlertSign={showAlertSign}
                setShowAlertSigh={setShowAlertSigh}
            />


        </>
    )
}

export default EditPartner