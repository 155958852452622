import React, { useEffect, useState } from 'react'
import SideBar from './SideBar';
import axiosClient from '../axios';
import { ReactComponent as Edit } from "../assets/images/edit.svg";
import { ReactComponent as Delete } from "../assets/images/delete.svg";
import { ReactComponent as Add } from "../assets/images/add.svg";
import { ReactComponent as Picadd } from "../assets/images/newAddPic.svg";
import EditIcon from '@mui/icons-material/Edit';
import Dialog from '@mui/material/Dialog';
import moment from 'moment';
import Pagination from './Pagination';

function Categories() {
    const [category, setCategory] = useState({
        name: "",
        image: "",
        type: ""
    });
    const [pagination, setPagination] = useState({
        page: 0,
        limit: 100
    })
    const [rowPerPage, setRowPerPage] = useState(100);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPage, setTotalPage] = useState(10)
    const [data, setData] = useState([])
    const [isDelete, setIsDelete] = useState(false);
    const [newCategory, setNewCategory] = useState({
        name: "",
        image: "",
        type: ""
    });
    const [editable, setEditable] = useState(false);
    const [open, setOpen] = useState(false);
    const [fileInputKey, setFileInputKey] = useState(Date.now());

    const getCatgeories = () => {
        axiosClient
            .get("/getCategory")
            .then((res) => {
                setData(res.data.response)
                setTotalPage(res.data.response.length)
            })
            .catch((error) =>
                console.log(error)
            );
    }

    const handlefileInput = async (e) => {
        setFileInputKey(Date.now());
        const photo = new FormData();
        const selectedFile = e.target.files[0];
        const fileSizeInMb = selectedFile.size / (1024 * 1024);
        if (fileSizeInMb > 7) {
            alert('File size is too large. Please choose a file smaller than 7 MB.');
        } else {
            photo.append("photo", selectedFile);
            await axiosClient
                .post(`/uploadImage`, photo)
                .then((res) => {
                    editable ? setCategory({ ...category, image: res.data.response.url }) :
                        setNewCategory({ ...newCategory, image: res.data.response.url })
                })
                .catch((err) => console.log(err));
        }
    };
    const categoryDetail = (e) => {
        axiosClient
            .get(`/detailCategory/${e}`)
            .then((res) => {
                setCategory(res.data.response)
            })
            .catch((error) =>
                console.log(error)
            );
    }
    const updateCatgeory = () => {
        if (category.type == 0 || category.type == 1) {
            const formData = {
                image: category.image,
                catId: category._id,
                name: category.name,
                type: category.type
            }
            axiosClient
                .put(`/editCategory`, formData)
                .then((res) => {
                    setCategory({});
                    setOpen(false);
                })
                .catch((error) =>
                    console.log(error)
                );
        } else {
            alert(`category type 0 for Offers. \ncategory type 1 for Blogs.\n choose between them  only `)
        }

    }
    const addCategory = () => {
        if (newCategory.type == 1 || newCategory.type == 0) {
            const formData = {
                image: newCategory.image,
                name: newCategory.name,
                type: newCategory.type
            }
            axiosClient
                .post(`/newCategory`, formData)
                .then((res) => {
                    setNewCategory({
                        name: "",
                        image: "",
                        type: ""
                    });
                    setOpen(false);
                })
                .catch((error) => console.log(error));
        } else {
            alert(`category type 0 for Offers. \ncategory type 1 for Blogs.\n choose between them  only `)
        }
    }
    const handleDelete = () => {
        setOpen(false);
        setIsDelete(false);
        setCategory({});
        setNewCategory({
            name: "",
            image: "",
            type: ""
        });
    }

    const DeleteCatgeory = () => {
        axiosClient
            .delete("/deleteCategory", { data: category })
            .then((res) => {
                setCategory({})
                setOpen(false);
                setIsDelete(false);
            })
            .catch((err) => console.log(err))
    }
    useEffect(() => {
        getCatgeories()
    }, [open])
    return (
        <>
            <SideBar handleSideBar={true} activeTab={"categories"} />
            <div className="screen">
                <div className='dFlex'>
                    <h2 className='screenHeading catHead'>Categories</h2>
                    <div className='editButton addCategory' onClick={() => { setOpen(true); setEditable(false) }}><Add />Add Category</div>
                </div>
                <div className='profileInfoBox table'>
                    <>
                        <div style={{ width: '100%' }} className="container app-body">
                            <div className='tabel-scrol'>
                                <table id="users">
                                    <thead>
                                        <tr>
                                            <th style={{ width: '50px' }}>S.NO.</th>
                                            <th style={{ width: '80px' }}>Name</th>
                                            <th style={{ width: '100px' }}>Icon</th>
                                            <th style={{ width: '100px' }}>Type</th>
                                            <th style={{ width: '180px' }}>Created At</th>
                                            <th style={{ width: '70px' }}>Edit</th>
                                            <th style={{ width: '70px' }}>Delete</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {data?.map((val, key) => {
                                            return (
                                                <tr key={key}>
                                                    <td>{(pagination.limit * pagination.page)+ (key + 1)}</td>
                                                    <td >{val?.name}</td>

                                                    <td>{<div className='iconImage'>
                                                        <img src={val?.image} alt="icon" />
                                                    </div>}</td>

                                                    <td>{<div className='j'>
                                                        {val.type == 0 ? "Offers" : val.type == 1 ? "Blogs" : null}
                                                    </div>}</td>

                                                    <td>{moment.utc(val?.created_at).local().format("MMM Do YY, h:mm:ss a")}</td>
                                                    <td>{<Edit className='whiteRound' onClick={() => { setOpen(true); setEditable(true); categoryDetail(val?._id); }} />}</td>

                                                    <td>{<Delete onClick={() => {
                                                        setOpen(true); setIsDelete(true); setCategory({
                                                            catId: val?._id,
                                                            is_deleted: true
                                                        })
                                                    }} />}</td>
                                                </tr>)
                                        })
                                        }
                                    </tbody>
                                </table>
                            </div>

                            <div>
                                <Pagination
                                    filterDataInput={pagination}
                                    setFilterDataInput={setPagination}
                                    totalPage={totalPage}
                                />
                            </div>

                            {/* <div className='userTablePagination'>
                                <label>Rows per page:</label>
                                <select value={rowPerPage} onChange={(e) => { setRowPerPage(e.target.value); setCurrentPage(0) }}>
                                    <option value={10}>10</option>
                                    <option value={50}>50</option>
                                    <option value={100}>100</option>
                                    <option value={500}>500</option>
                                    <option value={1000}>1000</option>
                                    <option value={1500}>1500</option>
                                </select>
                                <span className='currentPagespan'> Current Page {currentPage + 1}/{Math.ceil(totalPage / rowPerPage)} </span>
                                {currentPage !== 0 &&
                                    (<button className='paginationButton' onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 0}>
                                        Previous
                                    </button>)
                                }

                                {Math.ceil(totalPage / rowPerPage) !== 1 && Math.ceil(totalPage / rowPerPage) != currentPage + 1 &&
                                    (<button className='paginationButton'
                                        onClick={() => setCurrentPage(currentPage + 1)}
                                        disabled={currentPage + 1 >= totalPage / rowPerPage}
                                    >
                                        Next
                                    </button>)
                                }
                            </div> */}

                        </div>
                    </>
                </div>
            </div>

            <Dialog
                open={open}
                onClose={() => handleDelete()}
                className="dialogs"
            >
                <div className='categoryEditBox'>
                    {isDelete ?
                        <>
                            <h3>Delete Category</h3>
                            <p>
                                Are your Sure you want to Delete the category
                            </p>
                            <div className='dFlex'>
                                <div className='editButton' onClick={() => DeleteCatgeory()}>Yes</div>
                                <div className='editButton' onClick={() => handleDelete()}>No</div>
                            </div>
                        </> :
                        <>
                            <h3>{editable ? "Edit Catgeory" : "Add Catgeory"}</h3>
                            <div className="pRelative w300">
                                <input
                                    type="text"
                                    name="name"
                                    placeholder='Category Name'
                                    onChange={(e) => {
                                        editable ? setCategory({ ...category, name: e.target.value }) : setNewCategory({ ...newCategory, name: e.target.value })
                                    }}
                                    value={editable ? category.name : newCategory.name}
                                    className="inputTag categoryInput"
                                />
                                {editable ? <EditIcon className='editIcon' /> : null}
                            </div>
                            <div className="pRelative w300">
                                <input
                                    type="number"
                                    name="type"
                                    placeholder='Category Type'
                                    onChange={(e) => {
                                        editable ? setCategory({ ...category, type: e.target.value }) : setNewCategory({ ...newCategory, type: e.target.value })
                                    }}
                                    value={editable ? category.type : newCategory.type}
                                    className="inputTag categoryInput"
                                />
                                {editable ? <EditIcon className='editIcon' /> : null}
                            </div>
                            <div className="pRelative w300">
                                <label htmlFor="inputTag">
                                    <Picadd className="addImgae" />
                                    <input
                                        type="file"
                                        id="inputTag"
                                        key={fileInputKey}
                                        name="photo"
                                        multiple
                                        onChange={(e) => handlefileInput(e)}
                                        className="filetag"
                                    />
                                </label>
                                {category.image || newCategory.image ?
                                    <>
                                        <div className='catImageCover'>
                                            <img src={editable ? category.image : newCategory.image} alt="Category Icon" className='cat_image' />
                                        </div>
                                    </> : null}

                                {editable ? <EditIcon className='editIcon' /> : null}
                            </div>
                            {
                                editable ?
                                    <div className='editButton' onClick={() => updateCatgeory()}>Update</div> :
                                    <div className='editButton' onClick={() => addCategory()}>Add</div>
                            }
                        </>
                    }

                </div>
            </Dialog>
        </>
    )
}

export default Categories