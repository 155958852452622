import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import axiosClient from '../axios';
import SideBar from './SideBar'
import { ReactComponent as Config } from "../assets/images/config.svg"
import { ReactComponent as Cross } from "../assets/images/cross.svg"
import loader from '../assets/images/loader.gif'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { TextField } from '@mui/material';
import { dateConvert } from '../dateConverter';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


function SinglePartner() {
    const [date, setDate] = useState({
        start: null,
        end: null,
    })
    const [rowPerPage, setRowPerPage] = useState(100);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPage, setTotalPage] = useState(100)
    const [data, setData] = useState([])

    const [isPopular, setIsPopular] = useState('true')
    const [isOpen, setIsOpen] = useState(false);
    const { id } = useParams();
    const [partner, setPartner] = useState({});
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false)
    const [amount, setAmount] = useState(0)
    const [transaction, setTransaction] = useState(0)
    const [showBox, setShowBox] = useState(false)
    const [showTransaction, setShowTransaction] = useState(false)


    const [transData, setTransData] = useState([])
    const [userWinning, setUserWinning] = useState({
        winningAmt: 0,
        adminMessage: ""
    })


    const getPartners = () => {
        console.log("getPartner")
        let start, end
        if (date.start && date.end == null) {
            start = dateConvert(date?.start);
        }
        else if (date.start && date.end) {
            start = dateConvert(date?.start);
            end = dateConvert(date?.end);
        } else {
            start = null
            end = null
        }
        if (start > end) {
            return alert("start date should be previous to end date")
        }
        let temp = "&"
        temp += 'is_popular=' + isPopular
        setLoading(true)
        axiosClient
            .get(`/partner/${id}?${temp}&page=${currentPage}&limit=${rowPerPage}&s_date=${start}&e_date=${end}`)
            .then((res) => {
                setPartner(res?.data?.partnerData)
                setData(res?.data?.partnerOffer)
            })
            .catch((error) =>
                console.log(error)
            ).finally(() => { setLoading(false) })
    }


    const handleDelete = () => {
        setIsOpen(false);
    }


    const handleTransactions = () => {
        let s_date, e_date
        if (date.start && date.end == null) {
            s_date = dateConvert(date?.start);
        }
        else if (date.start && date.end) {
            s_date = dateConvert(date?.start);
            e_date = dateConvert(date?.end);
        } else {
            s_date = null
            e_date = null
        }
        if (s_date > e_date) {
            alert("start date should be previous to end date")
            return
        }
        console.log("amount trans", amount, transaction)

        if (!amount || !transaction || amount == 0 || transaction == 0) {
            alert("Please Enter No Of Transaction and Sum Of Transaction")
            return
        }

        setLoading(true)
        axiosClient
            .get(`/getCombinedTransaction?&page=${currentPage}&limit=${rowPerPage}&s_date=${s_date}&e_date=${e_date}&partner=${id}&amount=${amount}&noOfTransaction=${transaction}`)
            .then((res) => {
                console.log("response======>", res)
                setTransData(res?.data?.response?.totalData)
                setTotalPage(res?.data?.response?.counting)
            })
            .catch((error) =>
                console.log(error)
            ).finally(() => { setLoading(false) })

    }

    const handleDateSubmit = () => {
        if (date.start) {
            handleTransactions()
        }
        else {
            alert("Please Select Start Date And End Date")
        }
    }

    const sendWinning = () => {
        setLoading(true)
        let s_date, e_date
        if (date.start && date.end == null) {
            s_date = dateConvert(date?.start);
        }
        else if (date.start && date.end) {
            s_date = dateConvert(date?.start);
            e_date = dateConvert(date?.end);
        } else {
            s_date = null
            e_date = null
        }
        if (s_date > e_date) {
            alert("start date should be previous to end date")
            return
        }

        if (transData?.length === 0) {
            alert('No Transaction Winning Can not be send')
            setLoading(false)
            return
        }
        const sendData = {
            // transData: transData,
            filterData: {
                s_date: s_date,
                e_date: e_date,
                amount: amount,
                noOfTransaction: transaction,
                partner: partner._id
            },
            userWinning: userWinning
        };
        setIsOpen(false)
        axiosClient
            .post(`/sendPartnerTransWinning`, sendData)
            .then((res) => {
                alert(res.data.message)
                setUserWinning({
                    winningAmt: 0,
                    adminMessage: ""
                })
                console.log("res of partner transaction", res)
            })
            .catch((error) => console.log(error))
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        getPartners();
    }, [rowPerPage, currentPage])
    return (
        <>
            <SideBar handleSideBar={true} activeTab={"partners"} />
            {loading ?
                <div className='partnerloader'>
                    <img src={loader} alt='loader' />
                </div>
                : <div className="screen">
                    <div className='divimage singlePartnerFlex'>
                        <div className='dBlock sigleOfferdBlock '>
                            <h2 className='screenHeading catHead'>Partner Details</h2>
                            <p className='catPara'><span>Name</span> : {partner?.name}</p>
                            <p className='catPara'><span>Is Active</span> : {partner?.is_deleted ? "Not Active" : "Active"}</p>
                            <p className='catPara'><span>Total Payout</span> : {partner?.totalPayout?.toFixed(2)}</p>
                            <p className='catPara'><span>Total Revenue</span> : {partner?.totalRevenue?.toFixed(2)}</p>
                            <p className='catPara'><span>Total Transaction Count</span> : {partner?.count || 0}</p>
                        </div>

                        <div className='editSingPartnerFlex'>

                            {transData.length > 0 && <div className='tSubnitButton' onClick={() => setIsOpen(true)} >SendWinning</div>}
                            <div className='tSubnitButton' onClick={() => navigate(`/editpartner/${id}`)} >Edit</div>
                            <div className='partner_filter_data'>
                                <span>
                                    {
                                        showBox ?
                                            <Cross onClick={() => setShowBox(false)} style={{ width: '20px', height: '17px' }} />
                                            : <Config onClick={() => setShowBox(true)} />
                                    }
                                    <div className={`partner-main-div ${showBox && 'active'}`}>
                                        <div className="partner-input-box">
                                            <p>Transaction</p>
                                            <input
                                                type="text"
                                                placeholder="Transaction"
                                                value={transaction == 0 ? "" : transaction}
                                                onChange={(e) => setTransaction(e.target.value)}
                                            />
                                        </div>
                                        <div className="partner-input-box">
                                            <p>Amount</p>
                                            <input
                                                type="text"
                                                placeholder="Enter Amount"
                                                value={amount == 0 ? '' : amount}
                                                onChange={(e) => setAmount(e.target.value)}
                                            />
                                        </div>


                                        <div className="partner-input-box">
                                            <p>Show Transaction</p>
                                            <input
                                                type="radio"
                                                value={true}
                                                className='partner_radio'
                                                checked={showTransaction ? true : false}
                                                onChange={(e) => {
                                                    setShowTransaction(true)
                                                }}
                                            />
                                            <span>True</span>

                                            <input
                                                type="radio"
                                                value={false}
                                                className='partner_radio'
                                                checked={!showTransaction ? true : false}
                                                onChange={(e) => {
                                                    setShowTransaction(false)
                                                }}
                                            />
                                            <span>False</span>
                                        </div>



                                        <div className='graphBoxInPartner'>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                    label="Start Date"
                                                    value={date?.start}
                                                    onChange={(value) => { setDate({ ...date, start: value }) }}
                                                    renderInput={(params) => <TextField {...params} />}
                                                // maxDate={date?.end}
                                                />
                                            </LocalizationProvider>
                                        </div>
                                        <div className='graphBoxInPartner'>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                    label="End Date"
                                                    value={date?.end}
                                                    onChange={(value) => setDate({ ...date, end: value })}
                                                    renderInput={(params) => <TextField {...params} />}
                                                // maxDate={date?.end}
                                                />
                                            </LocalizationProvider>
                                        </div>
                                        <button className='partnerTSubnitButton' onClick={() => { setShowBox(false); { showTransaction ? handleDateSubmit() : getPartners() } }}>Submit</button>
                                    </div>
                                </span>



                            </div>
                        </div>
                    </div>

                    <div className='profileInfoBox table'>
                        <div className='popular'>
                            <h5 className='sPartnerh5'>Transactions</h5>
                        </div>
                        {
                            transData && transData.length > 0 ? <>
                                <div className='profileInfoBox table'>
                                    <>
                                        <div style={{ width: '100%' }} className="container app-body">
                                            <div className='tabel-scrol'>
                                                <table id="users">
                                                    <tr>
                                                        <th style={{ width: '5px' }}>S.NO.</th>
                                                        <th style={{ width: '80px' }}>Name</th>
                                                        <th style={{ width: '80px' }}>Email</th>
                                                        <th style={{ width: '80px' }}>Phone Number</th>
                                                        <th style={{ width: '80px' }}>UerId</th>
                                                        <th style={{ width: '80px' }}>Amount SUM</th>
                                                        <th style={{ width: '80px' }}>Transaction count</th>
                                                    </tr>

                                                    {transData?.map((val, key) => {
                                                        return (<tr>
                                                            <td>{(rowPerPage * currentPage) + (key + 1)}</td>
                                                            <td className='clickabuetd' onClick={() => window.open(`/users/transaction/${val?._id}`)}>{val?.user_name}</td>
                                                            <td>{val?.user_email}</td>
                                                            <td>{val?.user_number}</td>
                                                            <td>{val?._id}</td>
                                                            <td>{val?.amount}</td>
                                                            <td>{val?.count}</td>
                                                        </tr>)
                                                    })
                                                    }
                                                </table>
                                            </div>
                                            <div className='userTablePagination'>
                                                <label>Rows per page:</label>
                                                <select value={rowPerPage} onChange={(e) => { setRowPerPage(e.target.value); setCurrentPage(0) }}>
                                                    <option value={10}>10</option>
                                                    <option value={50}>50</option>
                                                    <option value={100}>100</option>
                                                    <option value={500}>500</option>
                                                    <option value={1000}>1000</option>
                                                    <option value={1500}>1500</option>
                                                </select>

                                                <span className='currentPagespan'> Current Page {currentPage + 1}/{Math.ceil(totalPage / rowPerPage)} </span>

                                                {currentPage !== 0 && (
                                                    <button className='paginationButton' onClick={() => setCurrentPage(currentPage - 1)} style={{ backgroundColor: "#2b579a" }}>
                                                        Previous
                                                    </button>
                                                )}

                                                {Math.ceil(totalPage / rowPerPage) !== 1 && Math.ceil(totalPage / rowPerPage) != currentPage + 1 && (<button className='paginationButton'
                                                    onClick={() => setCurrentPage(currentPage + 1)}
                                                    disabled={currentPage + 1 >= totalPage / rowPerPage}
                                                >
                                                    Next
                                                </button>
                                                )}
                                            </div>
                                        </div>
                                    </>
                                </div>
                            </> : <p>No Transaction Found</p>
                        }

                        <Dialog
                            open={isOpen}
                            onClose={() => handleDelete()}
                            TransitionComponent={Transition}
                            className="dialogs"
                        >
                            <div className='par_dialog_box'>
                                <>
                                    <div className="part-dia-m-div">
                                        <p>Winning Amount</p>
                                        <input
                                            type="number"
                                            placeholder='winning'
                                            className='p_input_box'
                                            value={userWinning?.winningAmt}
                                            onChange={(e) => {
                                                setUserWinning({ ...userWinning, winningAmt: e.target.value })
                                            }}
                                        />
                                        <p>Admin Message</p>
                                        <input
                                            type="text"
                                            className='p_input_box'
                                            placeholder='message'
                                            value={userWinning?.adminMessage}
                                            onChange={(e) => {
                                                setUserWinning({ ...userWinning, adminMessage: e.target.value })
                                            }}
                                        />
                                    </div>
                                    {
                                        <div className='editButton' onClick={() => sendWinning()}>Submit</div>
                                    }
                                </>
                            </div>
                        </Dialog>
                    </div>
                </div>}
        </>
    )
}

export default SinglePartner