import React, { useEffect, useState } from 'react'
import JoditEditor from "jodit-react";
import { useNavigate } from 'react-router-dom';
import axiosClient from '../axios';
import SideBar from './SideBar';
import loader from "../assets/images/loader.gif";
import { Autocomplete } from "@mui/material";
import { TextField } from "@material-ui/core";
import { ReactComponent as Picadd } from "../assets/images/newAddPic.svg";


function AddBlogs() {
    const [loading, setLoading] = useState(false)
    const [formData, setFormData] = useState({
        title: "",
        is_active: true,
        content: "",
        description: "",
        category: "",
        image: "",
        metaDescription: "",
        url: ""
    });
    const [allCategory, setAllCatgeory] = useState([]);
    const [fileInputKey, setFileInputKey] = useState(Date.now());
    const [vouhcerCoverImage, setVouhcerCoverImage] = useState("")
    const [is_voucherActive, setIs_voucherActive] = useState("true");
    const navigate = useNavigate();
    const handlefileInput = async (e) => {
        setFileInputKey(Date.now());
        const photo = new FormData();
        photo.append("photo", e.target.files[0]);
        await axiosClient
            .post(`/uploadImage`, photo)
            .then((res) => {
                setVouhcerCoverImage(res.data.response.url)
                setFormData({
                    ...formData, image: res.data.response.url
                })
            })
            .catch((err) => console.log(err));
    };
    const getCatgeories = () => {
        axiosClient
            .get("/getCategory?type=1")
            .then((res) => setAllCatgeory(res.data.response))
            .catch((error) => console.log(error));
    }
    const addData = async (e) => {
        setLoading(true);
        console.log("formData", formData)
        await axiosClient
            .post("/admin/newBlog", formData)
            .then((res) => {
                if (res.data.status === true) {
                    alert("Offer has been added succeffully")
                    navigate("/allBlogs");
                } else {
                    alert("Some error while adding offer")
                }
            })
            .catch((error) => {
                alert("Some error while adding offer")
            })
            .finally(() => setLoading(false))
    };
    useEffect(() => {
        getCatgeories();
    }, [])
    return (
        <>
            <SideBar handleSideBar={true} activeTab={"addBlog"} />
            <div className='screen'>
                {loading ?
                    <img src={loader} alt="" className="loader" /> : <>
                        <h2 className='screenHeading'>Add Blogs</h2>
                        <div className='vouhcerInfoBox'>
                            <div className='dataTable'>
                                <div className='editCover editBio'>
                                    <p>
                                        Title
                                    </p>
                                    <input
                                        name="name"
                                        placeholder={'Name'}
                                        onChange={(e) => {
                                            setFormData({ ...formData, title: e.target.value });
                                        }}
                                        value={formData.title}
                                        className="inputTag"
                                    />
                                </div>
                                <div className='editCover editBio'>
                                    <p>
                                        Description
                                    </p>
                                    <input
                                        name="description"
                                        placeholder={'description'}
                                        onChange={(e) => {
                                            setFormData({ ...formData, description: e.target.value });
                                        }}
                                        value={formData.description}
                                        className="inputTag"
                                    />
                                </div>
                                <div className='editCover editBio'>
                                    <p>
                                        Meta Description
                                    </p>
                                    <input
                                        name="metadescription"
                                        placeholder={'Meta Description'}
                                        onChange={(e) => {
                                            setFormData({ ...formData, metaDescription: e.target.value });
                                        }}
                                        value={formData.metaDescription}
                                        className="inputTag"
                                    />
                                </div>
                                <div className='editCover editBio'>
                                    <p>
                                        URL
                                    </p>
                                    <input
                                        name="URL"
                                        placeholder={'URL'}
                                        onChange={(e) => {
                                            setFormData({ ...formData, url: e.target.value });
                                        }}
                                        value={formData.url}
                                        className="inputTag"
                                    />
                                </div>
                                <div className='editCover editBio'>
                                    <p>
                                        Is Blog active
                                    </p>
                                    <div className="pRelative">
                                        <input
                                            type="radio"
                                            checked={is_voucherActive === "true" ? true : false}
                                            className="rounded"
                                            value={'true'}
                                            onChange={(e) => {
                                                setIs_voucherActive(e.target.value);
                                                setFormData({ ...formData, is_active: e.target.value == 'true' ? true : false });
                                            }}
                                        />
                                        <p className='inline'>True</p>
                                        <input
                                            type="radio"
                                            checked={is_voucherActive === "false" ? true : false}
                                            className="rounded"
                                            value={'false'}
                                            onChange={(e) => {
                                                setIs_voucherActive(e.target.value);
                                                setFormData({ ...formData, is_active: false });
                                            }}
                                        />
                                        <p className='inline'>False</p>
                                    </div>
                                </div>
                                <div className='editCover editBio'>
                                    <p>
                                        Category
                                    </p>
                                    <Autocomplete
                                        disablePortal
                                        value={formData?.category.name}
                                        onChange={(event, newValue) => {
                                            if (newValue) {
                                                setFormData({ ...formData, category: newValue?.name });
                                            }
                                        }}
                                        options={allCategory}
                                        getOptionLabel={option => option.name}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Category"
                                            />
                                        )}
                                    />
                                </div>
                                <div className='editCover editBio'>
                                    <p>content</p>
                                    <div className="pRelative">
                                        <JoditEditor
                                            value={formData.content}
                                            onChange={(newContent) =>
                                                setFormData({ ...formData, content: newContent })
                                            }
                                        />
                                    </div>
                                </div>
                                <div className='editCover editBio'>
                                    <p>
                                        Offer Cover Image
                                    </p>
                                    <div className="pRelative">
                                        <label htmlFor="inputTagField">
                                            <Picadd className="addImgae" />
                                            <input
                                                type="file"
                                                id="inputTagField"
                                                key={fileInputKey}
                                                name="photo"
                                                multiple
                                                onChange={(e) => handlefileInput(e)}
                                                className="filetag"
                                            />
                                        </label>
                                    </div>
                                    <div>
                                        <img src={vouhcerCoverImage} alt="voucherimgae" className='voucher_image' />
                                    </div>
                                </div>
                            </div>
                        </div >
                        <div className='editButton' onClick={() => addData()}>Add Offer</div>
                    </>}
            </div>
        </>
    );
}
export default AddBlogs