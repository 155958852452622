
import React, { useState, useEffect } from 'react'
import SideBar from './SideBar'
import partnerTrackingCase from "../helperComponent/TaskChallengeTracker"
import axiosClient from '../axios';
import AlertDialog from '../helperComponent/AlertDialog';
import { ReactComponent as Add } from "../assets/images/add.svg"
import { ReactComponent as Delete } from "../assets/images/delete.svg"
import { TextField } from '@mui/material';
import { Autocomplete } from "@mui/material";
import { ReactComponent as Picadd } from "../assets/images/newAddPic.svg";
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { ReactComponent as Edit } from "../assets/images/edit.svg";
import { useNavigate } from 'react-router-dom';
import { Co2Sharp } from '@mui/icons-material';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const Challenge = () => {
    const [open, setOpen] = useState(false)
    const options = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
    const [openPartnerDialog, setOpenPartnerDialog] = useState(false)
    const [openReferDialog, setOpenReferDialog] = useState(false)
    const [openPlatForm, setOpenPlatForm] = useState(false)
    const [taskOpen, setTaskOpen] = useState(false)
    const navigate = useNavigate()
    const [isSubmitting, setIsSubmitting] = useState(false);


    const [alertDialogOpen, setAlertDialogOpen] = useState(false)
    const [dialogMessage, setDialogMessage] = useState('');
    const [showAlertSign, setShowAlertSigh] = useState(false)

    const [openTrackValueMessage, setOpenTrackValueMessagge] = useState(false)
    const [trackType, setTrackType] = useState(0)

    const [platForms, setPlatForms] = useState([])
    const [platFormData, setPlatFormData] = useState({
        earningCoins: 0
    })
    const [challengeInfo, setChallengeInfo] = useState({
        taskName: "",
        description: [],
        duration: 0,
        taskStartedAt: "",
        taskEndedAt: "",
        payableCoins: 0,
        data: {},
        multipleOfferWall: false,
    })

    const [desPoint, setDesPoint] = useState("")
    const [message, setMessage] = useState("")
    const [offerDetail, setOfferDetail] = useState([])
    const [offers, setOffers] = useState(
        {
            offerImageLink: "",
            offerId: "",
            earningCoins: 0,
            winningPercentage: 0,
            winningCoin: 0
        }
    )

    const [openPartner, setOpenPartner] = useState(false)
    const [partnerOfferDetail, setPartnerOfferDetail] = useState([])

    const [partnerImageData, setPartnerImageData] = useState({
        partnerImageLink: "",
        partnerId: '',
        name: ""

    })
    const [partners, setPartners] = useState({
        partnerInfo: [],
        minimumValue: 0,
        maximumValue: 0,
        offerQuantity: 0,
        earningCoins: 0,
        winningPercentage: 0,
        winningCoin: 0
    })

    const [otherOfferDetail, setOtherOfferDetail] = useState([])
    const [others, setOthers] = useState({
        referImageLink: "",
        numberOfRefer: 0,
        minimumReferEarning: 0,
        maximumReferEarning: 0,
        winningPercentage: 0,
        winningCoin: 0

    })

    const [taskEditable, setTaskEditable] = useState(false)
    const [taskIndex, setTaskIndex] = useState(0)
    const [allProvider, setAllProvider] = useState([]);

    let trackArray = [
        `No Case Found`,

        `Complete 1 or n task from any partner`,

        `Earn 500 or n coins from any partner`,

        `Complete 1 task from any partner of minimum 100 coins and get 50 coins`,

        `Earn 500 coins from any partner (include 1 partner), or n number of partners`,

        `Earn 100 coins from A and get 20
         Earn 90 coins from B and get 20
         Earn 80 coins from C and get 20`,

        `Complete 1 task from A offer wall of 100 coins
         Complete 1 task from B offer wall of 100 coins
         Complete 1 task from C offer wall of 100 coins`,

        `Scenario 4:
         Earn 100 coins from A
         Earn 90 coins from B
         Earn 80 coins from C
         and get 100 coins`,

        `Scenario 5:
         (Complete 1 task from A offer wall)
         (Complete 1 task from B)
         (Complete 1 task from C)
         and get 100 coins`,

        `specific partner case:
         Scenario 1: (Complete n tasks from specific partner)`,

        `specific partner case:
         Scenario 2: (Earn 500 coins from specific partner)`,

        `specific partner case:
         Scenario 4: (Complete n tasks from specific partner of minimum 100 coins)`,

        `Multiple Partner:
         Scenario 1: Earn 500 coins from n number of partners`,

        `Scenario 6: Complete n tasks from n number of partners`,

        `3: Multiple offer:
         (Task 2: Complete a Specific offer and get 20 extra)`,

        `Multiple offer:
         (Task 1: Complete a Specific offer)
         (Task 2: Complete a Specific offer)
         and get 100 coins extra.`,

        `Earn 500 Coin From Platform`,

        `Challenges Track Type Is Different , Please Dont Create`

    ];

    const handleIsChallengeMet = () => {
        if (partnerOfferDetail?.length == 0 && offerDetail?.length == 0 && platForms?.length == 0) {
            // alert("You Can Not Create Empty Task , Please Mention The Challenge")
            setDialogMessage("You Can Not Create Empty Task , Please Add Some Challenge");
            setAlertDialogOpen(true);
            return
        }
        async function fetchData() {
            const result = await partnerTrackingCase(challengeInfo?.data, challengeInfo?.multipleOfferWall);
            setTrackType(result);
            setOpenTrackValueMessagge(true)
        }
        fetchData();
    };

    const getPartner = () => {
        axiosClient
            .get("/partnersList")
            .then((res) => setAllProvider(res.data.response))
            .catch((error) => console.log(error));
    }

    function formatDateTimeForInput(date) {
        if (!date) return "";
        const d = new Date(date);
        const year = d.getFullYear();
        const month = String(d.getMonth() + 1).padStart(2, '0');
        const day = String(d.getDate()).padStart(2, '0');
        const hours = String(d.getHours()).padStart(2, '0');
        const minutes = String(d.getMinutes()).padStart(2, '0');
        return `${year}-${month}-${day}T${hours}:${minutes}`;
    }

    const handleDesArray = () => {
        setChallengeInfo({ ...challengeInfo, description: [...challengeInfo?.description, desPoint] })
        setDesPoint("");
    };

    const deletePoint = (index) => {
        let tempArr = [...challengeInfo.description];
        tempArr.splice(index, 1);
        setChallengeInfo({
            ...challengeInfo,
            description: tempArr
        });
    };

    const handleImage = async (e, type) => {
        const photo = new FormData();
        const selectedFile = e.target.files[0];
        const file = e.target.files[0];

        if (file) {
            const img = new Image();
            img.src = URL.createObjectURL(file);
            photo.append("photo", selectedFile);

            await axiosClient
                .post(`/uploadImage`, photo)
                .then((res) => {
                    if (type == 1) {
                        setOffers({ ...offers, offerImageLink: res.data.response.url })
                    }
                    if (type == 2) {
                        // setPartners({ ...partners, partnerImageLink: res.data.response.url })
                        setPartnerImageData({ ...partnerImageData, partnerImageLink: res.data.response.url })
                    }
                    if (type == 3) {
                        setOthers({ ...others, referImageLink: res.data.response.url })
                    }
                })
                .catch((err) => console.log(err));
        }
    }

    const handleClose = () => {
        setOpenTrackValueMessagge(false)
        setOpenPlatForm(false)
        setOpenPartner(false)
        setOpen(false)
        setTaskIndex(0)
        setTaskEditable(false)
        // setTask({ imageLink: "", trackLink: "" });
        setTaskOpen(false)
        setOpenReferDialog(false)
        setOpenPartnerDialog(false)
        setOffers({
            offerImageLink: "",
            offerId: "",
            // minimumValue: 0,
            // maximumValue: 0,
            // offerQuantity: 0,
            earningCoins: 0,
            winningPercentage: 0,
            winningCoin: 0

        }) // Formerly setOfferDetail
        setPartners({
            // partnerImageLink: "",
            // partnerId: "",
            minimumValue: 0,
            partnerInfo: [],
            maximumValue: 0,
            offerQuantity: 0,
            earningCoins: 0,
            winningPercentage: 0,
            winningCoin: 0,
        }) // Formerly setPartnerOfferDetail
        setOthers({
            referImageLink: "",
            numberOfRefer: 0,
            minimumReferEarning: 0,
            maximumReferEarning: 0,
            winningPercentage: 0,
            winningCoin: 0
        }) // Formerly setReferOfferDetail
    }

    const handleAddOfferDetail = () => {
        if (!offers?.offerImageLink) {
            setDialogMessage("Missing image");
            setAlertDialogOpen(true);
            return
        }

        if (!offers?.offerId) {
            setDialogMessage("Missing OfferId");
            setAlertDialogOpen(true);
            return
        }

        if (partnerOfferDetail.length > 0 || platForms.length > 0) {
            setDialogMessage("You Can Enter Only One Challenge , It may be offer ,Partner or PlatForm Challenge");
            setAlertDialogOpen(true);
            return
        }

        if (offers?.earningCoins < 0 || offers?.winningPercentage < 0 || offers?.winningCoin < 0) {
            // alert("You Can not give negative field value...")
            setDialogMessage("You Can not give negative field value...");
            setAlertDialogOpen(true);
            return
        }

        if (!Number.isInteger(offers?.earningCoins) || !Number.isInteger(offers?.winningCoin)) {
            setDialogMessage("earningCoins and winningCoin must be whole numbers (no decimals allowed).");
            setAlertDialogOpen(true);
            return;
        }


        const updatedOfferDetail = [...offerDetail, offers];
        setOfferDetail(updatedOfferDetail);
        setChallengeInfo(prevState => ({
            ...prevState,
            data: {
                ...prevState.data,
                offerDetail: updatedOfferDetail
            }
        }));
        setOpen(false);
        setOffers({
            offerImageLink: "",
            offerId: "",
            earningCoins: 0,
            winningPercentage: 0,
            winningCoin: 0
        })
    };

    const handlePartnerAdd = () => {

        if (offerDetail.length > 0 || platForms.length > 0) {
            setDialogMessage("You Can Enter Only One Challenge , It may be offer ,Partner or PlatForm Challenge");
            setAlertDialogOpen(true);
            return
        }

        if (challengeInfo?.multipleOfferWall) {
            if (partnerOfferDetail?.length >= 1) {
                setDialogMessage("You Can Not Add Multiple Partner Challenge Because Multiple Offer Wall key is true ");
                setAlertDialogOpen(true);
                return
            }
        }

        if (
            partners?.minimumValue ||
            partners?.maximumValue
        ) {
            if (!partners?.offerQuantity) {
                setDialogMessage("Please enter the offer quantity of this criteria.");
                setAlertDialogOpen(true);
                return
            }
            if (partners?.earningCoins) {
                setDialogMessage("You can only create the challange on the basis of min coin value offer or max coin offer value.");
                setAlertDialogOpen(true);
                return
            }
        }
        if (partners?.earningCoins) {
            if (
                partners?.minimumValue ||
                partners?.maximumValue
            ) {
                setDialogMessage("You can only create the challange on the basis earning coins.");
                setAlertDialogOpen(true);
                return
            }
        }

        if (!partners?.minimumValue && !partners?.maximumValue && !partners?.earningCoins && !partners?.offerQuantity) {
            setDialogMessage("Please mention the field value on the basis of which you want to create the offer.");
            setAlertDialogOpen(true);
            return
        }



        if (partners?.minimumValue < 0 || partners?.maximumValue < 0 || partners?.earningCoins < 0 || partners?.offerQuantity < 0 || partners?.winningCoin < 0 || partners?.winningPercentage < 0) {
            setDialogMessage("You Can not give negative field  value...");
            setAlertDialogOpen(true);
            return
        }

        if (!Number.isInteger(partners?.minimumValue) || !Number.isInteger(partners?.maximumValue) || !Number.isInteger(partners?.earningCoins) || !Number.isInteger(partners?.offerQuantity) || !Number.isInteger(partners?.winningCoin)) {
            setDialogMessage("minimumValue , maximumValue ,earningCoins  , offerQuantity and winningCoin must be whole numbers (no decimals allowed).");
            setAlertDialogOpen(true);
            return;
        }

        const updatedPartnerOfferDetail = [...partnerOfferDetail, partners];
        setPartnerOfferDetail(updatedPartnerOfferDetail);
        setChallengeInfo(prevState => ({
            ...prevState,
            data: {
                ...prevState.data,
                partnerOfferDetail: updatedPartnerOfferDetail
            }
        }));
        setOpenPartnerDialog(false);
        setPartners({
            partnerInfo: [],
            minimumValue: 0,
            maximumValue: 0,
            offerQuantity: 0,
            earningCoins: 0,
            winningPercentage: 0,
            winningCoin: 0
        })
    }

    const handleReferOfferDetails = () => {
        if (!others?.referImageLink) {
            alert("Missing image")
            return
        }
        if (!others?.numberOfRefer) {
            alert("Missing numberOfRefer")
            return
        }
        if (!others?.minimumReferEarning) {
            alert('Missing minimumReferEarning')
            return
        }
        if (!others?.maximumReferEarning) {
            alert("Missing maximumReferEarning")
            return
        }

        if (others?.numberOfRefer < 0 || others?.minimumReferEarning < 0 || others?.maximumReferEarning < 0 || others?.winningCoin < 0 || others?.winningPercentage < 0) {
            alert("You Can not give negative field value...")
            return
        }

        if (others?.minimumReferEarning > others?.maximumReferEarning) {
            alert("minimumReferEarning value can not be greater than minimumReferEarning value...")
            return
        }


        if (!others?.winningPercentage && !others?.winningCoin) {
            alert("Please enter the winning percentage or winning coin of this criteria.")
            return
        }

        if (!others?.winningCoin) {
            let value = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100]
            if (!value.includes(others?.winningPercentage)) {
                alert("Winning Coin value should be only 10, 20, 30, 40, 50, 60, 70, 80, 90, 100")
                return
            }
        }

        const updatedReferOfferDetail = [...otherOfferDetail, others];
        setOtherOfferDetail(updatedReferOfferDetail);
        setChallengeInfo(prevState => ({
            ...prevState,
            data: {
                ...prevState.data,
                otherOfferDetail: [{ referOffer: updatedReferOfferDetail }]
            }
        }));
        setOpenReferDialog(false);
        setOthers({
            referImageLink: "",
            numberOfRefer: 0,
            minimumReferEarning: 0,
            maximumReferEarning: 0,
            winningPercentage: 0,
            winningCoin: 0
        })
    }


    const handleOfferDelete = (index) => {
        const offerDetailToUpdate = [...offerDetail];
        offerDetailToUpdate.splice(index, 1);

        setOfferDetail(offerDetailToUpdate);

        if (offerDetailToUpdate.length > 0) {
            setChallengeInfo(prevState => ({
                ...prevState,
                data: {
                    ...prevState.data,
                    offerDetail: offerDetailToUpdate
                }
            }));
        } else {
            setChallengeInfo(prevState => {
                // Create a new data object without the offerDetail key
                const { offerDetail, ...restData } = prevState.data;
                return {
                    ...prevState,
                    data: restData
                };
            });
        }
    };


    const handlePartnerDelete = (index) => {
        const partnerOfferDetailToUpdate = [...partnerOfferDetail];
        partnerOfferDetailToUpdate.splice(index, 1);
        setPartnerOfferDetail(partnerOfferDetailToUpdate);
        if (partnerOfferDetailToUpdate?.length > 0) {
            setChallengeInfo(prevState => ({
                ...prevState,
                data: {
                    ...prevState.data,
                    partnerOfferDetail: partnerOfferDetailToUpdate
                }
            }));
        } else {
            setChallengeInfo(preState => {
                const { partnerOfferDetail, ...restData } = preState.data
                return {
                    ...preState,
                    data: restData
                }
            })
        }
    }

    const handleReferDelete = (index) => {
        const referOfferDetailToUpdate = [...otherOfferDetail];
        referOfferDetailToUpdate.splice(index, 1);
        setOtherOfferDetail(referOfferDetailToUpdate);
        if (referOfferDetailToUpdate?.length > 0) {
            setChallengeInfo(prevState => ({
                ...prevState,
                data: {
                    ...prevState.data,
                    otherOfferDetail: [{ referOffer: referOfferDetailToUpdate }]
                }
            }));
        }
        else {
            setChallengeInfo(preState => {
                const { otherOfferDetail, ...restData } = preState.data
                return {
                    ...preState,
                    data: restData
                }
            })

        }

    }

    const handleOfferEdit = (index, newTask) => {

        if (!newTask?.offerImageLink) {
            setDialogMessage("Missing image");
            setAlertDialogOpen(true);
            return
        }

        if (!newTask?.offerId) {
            setDialogMessage("Missing offerid");
            setAlertDialogOpen(true);
            return
        }


        if (newTask?.earningCoins < 0 || newTask?.winningPercentage < 0 || newTask?.winningCoin < 0) {
            setDialogMessage("You Can not give negative field value...");
            setAlertDialogOpen(true);
            return
        }

        if (!Number.isInteger(newTask?.earningCoins) || !Number.isInteger(newTask?.winningCoin)) {
            setDialogMessage("earningCoins and winningCoin must be whole numbers (no decimals allowed).");
            setAlertDialogOpen(true);
            return;
        }


        const offerDetailToUpdate = [...offerDetail];
        offerDetailToUpdate[index] = newTask;
        setOfferDetail(offerDetailToUpdate);
        setChallengeInfo(prevState => ({
            ...prevState,
            data: {
                ...prevState.data,
                offerDetail: offerDetailToUpdate
            }
        }));

        setOpen(false);
        setTaskIndex(0);
        setTaskEditable(false);
        setOffers({
            offerImageLink: "",
            offerId: "",
            earningCoins: 0,
            winningPercentage: 0,
            winningCoin: 0
        });
    };

    const handlePartnerEdit = (index, newTask) => {
        if (
            newTask?.minimumValue ||
            newTask?.maximumValue
        ) {
            if (!newTask?.offerQuantity) {
                setDialogMessage("Please enter the offer quantity of this criteria.");
                setAlertDialogOpen(true);
                return
            }
            if (newTask?.earningCoins) {
                setDialogMessage("You can only create the challange on the basis of min coin value offer or max coin offer value.");
                setAlertDialogOpen(true);
                return
            }
        }
        if (newTask?.earningCoins) {
            if (
                newTask?.minimumValue ||
                newTask?.maximumValue
            ) {
                setDialogMessage("You can only create the challange on the basis earning coins.");
                setAlertDialogOpen(true);
                return
            }
        }


        if (!newTask?.minimumValue && !newTask?.maximumValue && !newTask?.earningCoins && !newTask.offerQuantity) {
            setDialogMessage("Please mention the field value on the basis of which you want to create the offer,field are min,max coin value ,earning coin and offerid.");
            setAlertDialogOpen(true);
            return
        }

        if (newTask?.minimumValue < 0 || newTask?.maximumValue < 0 || newTask?.earningCoins < 0 || newTask?.offerQuantity < 0 || newTask?.winningPercentage < 0 || newTask?.winningCoin < 0) {
            setDialogMessage("You Can not give negative field value...");
            setAlertDialogOpen(true);
            return
        }

        if (!Number.isInteger(newTask?.minimumValue) || !Number.isInteger(newTask?.maximumValue) || !Number.isInteger(newTask?.earningCoins) || !Number.isInteger(newTask?.offerQuantity) || !Number.isInteger(newTask?.winningCoin)) {
            setDialogMessage("minimumValue , maximumValue ,earningCoins  , offerQuantity and winningCoin must be whole numbers (no decimals allowed).");
            setAlertDialogOpen(true);
            return;
        }

        const partnerOfferDetailToUpdate = [...partnerOfferDetail];
        partnerOfferDetailToUpdate[index] = newTask;
        setPartnerOfferDetail(partnerOfferDetailToUpdate);
        setChallengeInfo(prevState => ({
            ...prevState,
            data: {
                ...prevState.data,
                partnerOfferDetail: partnerOfferDetailToUpdate
            }
        }));

        setOpenPartnerDialog(false);
        setPartners({
            partnerInfo: [],
            minimumValue: 0,
            maximumValue: 0,
            offerQuantity: 0,
            earningCoins: 0,
            winningPercentage: 0,
            winningCoin: 0
        });
        setTaskIndex(0);
        setTaskEditable(false);
    };

    const handleReferEdit = (index, newTask) => {

        if (!newTask?.referImageLink) {
            alert("Missing image")
            return
        }
        if (!newTask?.numberOfRefer) {
            alert("Missing numberOfRefer")
            return
        }
        if (!newTask?.minimumReferEarning) {
            alert('Missing minimumReferEarning')
            return
        }
        if (!newTask?.maximumReferEarning) {
            alert("Missing maximumReferEarning")
            return
        }

        if (newTask?.numberOfRefer < 0 || newTask?.minimumReferEarning < 0 || newTask?.maximumReferEarning < 0 || newTask?.winningPercentage < 0 || newTask?.winningCoin < 0) {
            alert("You Can not give negative field value...")
            return
        }

        if (newTask?.minimumReferEarning > newTask?.maximumReferEarning) {
            alert("minimumReferEarning value can not be greater than minimumReferEarning value...")
            return
        }

        if (!newTask?.winningPercentage && !newTask?.winningCoin) {
            alert("Please enter the winning percentage or winning coin of this criteria.")
            return
        }

        if (!newTask?.winningCoin) {
            let value = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100]
            if (!value.includes(newTask?.winningPercentage)) {
                alert("Winning Coin value should be only 10, 20, 30, 40, 50, 60, 70, 80, 90, 100")
                return
            }
        }

        if (!newTask?.winningPercentage && !newTask?.winningCoin) {
            alert("Please enter the winning percentage or winning coin of this criteria.")
            return
        }

        if (!newTask?.winningCoin) {
            let value = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100]
            if (!value.includes(newTask?.winningPercentage)) {
                alert("Winning Coin value should be only 10, 20, 30, 40, 50, 60, 70, 80, 90, 100")
                return
            }
        }

        const referOfferDetailToUpdate = [...otherOfferDetail];
        referOfferDetailToUpdate[index] = newTask;
        setOtherOfferDetail(referOfferDetailToUpdate);
        setChallengeInfo(prevState => ({
            ...prevState,
            data: {
                ...prevState.data,
                // otherOfferDetail: referOfferDetailToUpdate 
                otherOfferDetail: [{ referOffer: referOfferDetailToUpdate }]
            }
        }));

        setOpenReferDialog(false);
        setOthers({
            referImageLink: "",
            numberOfRefer: 0,
            minimumReferEarning: 0,
            maximumReferEarning: 0,
            winningPercentage: 0,
            winningCoin: 0

        });
        setTaskIndex(0);
        setTaskEditable(false);
    };

    const handleAddPlatForm = () => {
        if (partnerOfferDetail.length > 0 || offerDetail.length > 0) {
            setDialogMessage("You Can Enter Only One Challenge , It may be offer ,Partner or Plat Form Challenge");
            setAlertDialogOpen(true);
            return
        }

        if (platFormData?.earningCoins < 0) {
            setDialogMessage("Earning Coin Can Not Be Negative Value.");
            setAlertDialogOpen(true);
            return
        }

        if (!Number.isInteger(platFormData?.earningCoins)) {
            setDialogMessage("earningCoins and winningCoin must be whole numbers (no decimals allowed).");
            setAlertDialogOpen(true);
            return;
        }


        const updatedPlatFormData = [...platForms, platFormData];
        setPlatForms(updatedPlatFormData);
        setChallengeInfo(prevState => ({
            ...prevState,
            data: {
                ...prevState.data,
                platForms: updatedPlatFormData
            }
        }));
        setOpenPlatForm(false);
        setPlatFormData({
            earningCoins: 0
        })
    };


    const handlePlatFormEdit = (index, newTask) => {

        if (!Number.isInteger(newTask?.earningCoins)) {
            setDialogMessage("earningCoins and winningCoin must be whole numbers (no decimals allowed).");
            setAlertDialogOpen(true);
            return;
        }
        const platFormsToUpdate = [...platForms];
        platFormsToUpdate[index] = newTask;
        setPlatForms(platFormsToUpdate);
        setChallengeInfo(prevState => ({
            ...prevState,
            data: {
                ...prevState.data,
                platForms: platFormsToUpdate
            }
        }));

        setOpenPlatForm(false);
        setTaskIndex(0);
        setTaskEditable(false);
        setPlatFormData({
            earningCoins: 0
        })

    };

    const handlePlateFormDelete = (index) => {
        const plateFormDelete = [...platForms];
        plateFormDelete.splice(index, 1);
        setPlatForms(plateFormDelete);
        if (plateFormDelete?.length > 0) {
            setChallengeInfo(prevState => ({
                ...prevState,
                data: {
                    ...prevState.data,
                    platForms: plateFormDelete
                }
            }));
        }
        else {
            setChallengeInfo(preState => {
                const { platForms, ...restData } = preState.data
                return {
                    ...preState,
                    data: restData
                }
            })
        }
    }

    const handleCreateChallenge = async () => {
        setIsSubmitting(true);
        setOpenTrackValueMessagge(false)
        if (!challengeInfo?.taskName || !challengeInfo?.payableCoins) {
            setDialogMessage("Please Enter All Fields Value Properly");
            setAlertDialogOpen(true);
            setIsSubmitting(false);
            return;
        }

        if (challengeInfo?.description?.length == 0) {
            setDialogMessage("Please Enter The Description Point");
            setAlertDialogOpen(true);
            setIsSubmitting(false);
            return
        }

        if (challengeInfo?.payableCoins <= 0 || challengeInfo?.duration <= 0) {
            setDialogMessage("Time Duration and PayableCoins can not be 0 or less than 0");
            setAlertDialogOpen(true);
            setIsSubmitting(false);
            return;
        }

        if (!Number.isInteger(challengeInfo?.payableCoins) || !Number.isInteger(challengeInfo?.duration)) {
            setDialogMessage("Duration and PayableCoins must be whole numbers (no decimals allowed).");
            setAlertDialogOpen(true);
            setIsSubmitting(false);
            return;
        }

        if (!challengeInfo?.taskStartedAt || !challengeInfo?.taskEndedAt) {
            setDialogMessage("Please Enter The Task Started Time and Task Ended Time");
            setAlertDialogOpen(true);
            setIsSubmitting(false);
            return;
        }

        const startDate = new Date(challengeInfo.taskStartedAt);
        const endDate = new Date(challengeInfo.taskEndedAt);

        // Compare the actual date values
        if (startDate >= endDate) {
            setDialogMessage("Start Time Of The Task Can Not Be Greater Than Or Equal To Task End Time...");
            setAlertDialogOpen(true);
            setIsSubmitting(false);
            return;
        }

        // Calculate the difference in milliseconds
        const taskTotalTimeInMilliseconds = endDate - startDate;
        // Convert the difference into minutes (1 minute = 60,000 milliseconds)
        const taskTotalTimeInMinute = taskTotalTimeInMilliseconds / (1000 * 60);

        if (taskTotalTimeInMinute < challengeInfo?.duration) {
            setDialogMessage("Duration can not be more than the difference of  Task Start And Task End Time");
            setAlertDialogOpen(true);
            setIsSubmitting(false);
            return
        }

        const totalWinningCoins =
            (challengeInfo?.data?.partnerOfferDetail?.reduce(
                (acc, offer) => acc + (offer?.winningCoin || 0),
                0
            ) || 0) +
            (challengeInfo?.data?.offerDetail?.reduce(
                (acc, offer) => acc + (offer?.winningCoin || 0),
                0
            ) || 0);

        if (totalWinningCoins > 0) {
            if (totalWinningCoins > challengeInfo?.payableCoins) {
                setDialogMessage("winning Coins Can Not Be Greater than Payable Coins");
                setAlertDialogOpen(true);
                setIsSubmitting(false);
                return
            }

            if (totalWinningCoins < challengeInfo?.payableCoins) {
                setDialogMessage("winning Coins Can Not Be less than Payable Coins");
                setAlertDialogOpen(true);
                setIsSubmitting(false);
                return
            }
        }

        await axiosClient
            .post("/add/task", challengeInfo)
            .then((res) => {
                setMessage(res?.data?.message)
                console.log("res?.data?.message", res?.data?.message)
                setOpenTrackValueMessagge(false)

                if (res?.data?.status === false) {
                    alert(res?.data?.message)
                    setIsSubmitting(false);
                    return
                }
                setTaskOpen(true);
            })
            .catch((err) => console.log(err));
    }

    const handleDeleteSingle = (i) => {
        let tempArr = [...partners?.partnerInfo]
        tempArr.splice(i, 1)
        setPartners({ ...partners, partnerInfo: tempArr })
    }

    const handlePartnerImage = () => {
        if (!partnerImageData?.partnerId || !partnerImageData?.partnerImageLink) {
            alert("Please Enter partnerImage and Partnerr Id")
            return
        }
        setOpenPartner(false)
        setPartners(prevState => ({
            ...prevState,
            partnerInfo: Array.isArray(prevState.partnerInfo)
                ? [...prevState.partnerInfo, partnerImageData]
                : [partnerImageData]
        }));

        setPartnerImageData({
            partnerImageLink: "",
            partnerId: ''
        })
    }


    useEffect(() => {
        getPartner()
    }, [])

    return (
        <>
            <SideBar handleSideBar={true} activeTab={"challenges"} />
            <div className="screen">
                <div className="challenge-container">
                    <h2>Create Challenge</h2>
                    <div className="challenge-field">
                        <span className='challenge-heading'>Name</span>
                        <input
                            className='challenge-input'
                            type="text"
                            name='name'
                            onChange={(e) => {
                                setChallengeInfo({ ...challengeInfo, taskName: e.target.value })
                            }}
                            placeholder='Enter Name'
                            value={challengeInfo.taskName}
                        />
                    </div>

                    <div className="challenge-field">
                        <span className='challenge-heading'>Description</span>
                        <input
                            className='challenge-input'
                            type="text"
                            name='description'
                            onChange={(e) => {
                                setDesPoint(e.target.value)
                            }}
                            placeholder='Enter Description '
                            value={desPoint}
                        />

                        <button className='descri-button' onClick={() => desPoint && handleDesArray()}>
                            Add
                        </button>


                        <div className='descriptionarry'>
                            <>
                                {
                                    challengeInfo.description?.map((item, index) => {
                                        return (
                                            <>
                                                <li key={index}>{item} <span className='span-crosss' onClick={() => { deletePoint(index) }}>⛌</span></li>
                                            </>
                                        )

                                    })
                                }
                            </>
                        </div>
                    </div>

                    <div className="challenge-field">
                        <span className='challenge-heading'>Started At</span>
                        <input
                            className='challenge-input'
                            type="datetime-local"
                            name="duration"
                            placeholder='Enter Time Duration'
                            value={formatDateTimeForInput(challengeInfo.taskStartedAt)}

                            onChange={(e) => {
                                setChallengeInfo({ ...challengeInfo, taskStartedAt: e.target.value })
                            }}
                        />
                    </div>
                    <div className="challenge-field">
                        <span className='challenge-heading'>Ended At</span>
                        <input
                            className='challenge-input'
                            type="datetime-local"
                            name="duration"
                            placeholder='Enter Time Duration'
                            value={formatDateTimeForInput(challengeInfo.taskEndedAt)}

                            onChange={(e) => {
                                setChallengeInfo({ ...challengeInfo, taskEndedAt: e.target.value })
                            }}
                        />
                    </div>

                    <div className="challenge-field">
                        <span className='challenge-heading'>PayableCoins</span>
                        <input
                            className='challenge-input'
                            type="Number"
                            name='payablecoins'
                            value={challengeInfo.payableCoins}
                            placeholder='Enter Placeholder'
                            onChange={(e) => { setChallengeInfo({ ...challengeInfo, payableCoins: Number(e.target.value) }) }}
                            onWheel={(e) => e.target.blur()}
                        />
                    </div>

                    <div className="challenge-field">
                        <span className='challenge-heading'>Duration
                            <span className='in-minutes'>(In Minute)</span>
                        </span>
                        <input
                            className='challenge-input'
                            type="Number"
                            name='duration'
                            value={challengeInfo.duration}
                            placeholder='Enter Duration'
                            onChange={(e) => { setChallengeInfo({ ...challengeInfo, duration: Number(e.target.value) }) }}
                            onWheel={(e) => e.target.blur()}

                        />
                    </div>

                    <div className='challengeLabel'>
                        <label htmlFor="">Multiple Offer Wall </label>
                        <input
                            type="radio"
                            checked={challengeInfo?.multipleOfferWall == true ? true : false}
                            onChange={(e) => {
                                setChallengeInfo({ ...challengeInfo, multipleOfferWall: true })
                            }}
                            className="circle"
                            value={true}
                        />
                        <span className='inline'>True</span>
                        <input
                            type="radio"
                            checked={challengeInfo?.multipleOfferWall == false ? true : false}
                            onChange={(e) => {
                                setChallengeInfo({
                                    ...challengeInfo, multipleOfferWall: false
                                })

                            }}
                            className="circle"
                            value={false}
                        />
                        <span className='inline'>False</span>
                    </div>

                    <div className="challenge-field">
                        <p className='challenge-heading'>Add Offer <Add className='addReward' onClick={() => { setOpen(true) }} /></p>

                        {(offerDetail.length > 0) && <div className='challenge-preview'>
                            <table className='challenge-task-tabel'>
                                <thead>
                                    <th>offer image</th>
                                    <th>offerId</th>
                                    <th>earning Coins</th>
                                    <th>winning Coin</th>
                                    <th>Edit</th>
                                    <th>Delete</th>
                                </thead>
                                <tbody>

                                    {offerDetail.map((item, i) => {
                                        return (
                                            <tr key={i} >
                                                <td>
                                                    <img src={item?.offerImageLink} alt="" width="100%" height="40px" />
                                                </td>
                                                <td>
                                                    {item?.offerId}
                                                </td>
                                                <td>{item?.earningCoins}</td>
                                                <td>{item?.winningPercentage ? `${item?.winningPercentage} %` : `${item?.winningCoin} coin`}</td>
                                                <td><Edit
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => {
                                                        setOpen(true);
                                                        setOffers(item)
                                                        setTaskIndex(i)
                                                        setTaskEditable(true)
                                                    }}
                                                /></td>
                                                <td><Delete
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => {
                                                        handleOfferDelete(i)
                                                    }} /></td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>


                        </div>}
                    </div>

                    <div className="challenge-field">
                        <p className='challenge-heading'>Add Partner <Add className='addReward' onClick={() => { setOpenPartnerDialog(true) }} /></p>

                        {(partnerOfferDetail.length > 0) && <div className='challenge-preview'>
                            <table className='challenge-task-tabel'>
                                <thead>
                                    <th>minimum Value</th>
                                    <th>maximum Value</th>
                                    <th>offer Quantity</th>
                                    <th>earning Coins</th>
                                    <th>winning coin</th>
                                    <th>Edit</th>
                                    <th>Delete</th>
                                </thead>
                                <tbody>

                                    {partnerOfferDetail.map((item, i) => {
                                        return (
                                            <tr key={i}>
                                                <td>{item?.minimumValue}</td>
                                                <td>{item?.maximumValue}</td>
                                                <td>{item?.offerQuantity}</td>
                                                <td>{item?.earningCoins}</td>
                                                <td>{item?.winningPercentage ? `${item?.winningPercentage} %` : `${item?.winningCoin} coin`}</td>
                                                <td ><Edit style={{ cursor: 'pointer' }}
                                                    onClick={() => {
                                                        setOpenPartnerDialog(true)
                                                        setPartners(item)
                                                        setTaskIndex(i)
                                                        setTaskEditable(true)
                                                    }}
                                                /></td>
                                                <td><Delete style={{ cursor: 'pointer' }} onClick={() => {
                                                    handlePartnerDelete(i)
                                                }} /></td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>}
                    </div>

                    <div className="challenge-field">
                        <p className='challenge-heading'>Add Refer Details <Add className='addReward' onClick={() => { setOpenReferDialog(true) }} /></p>

                        {(otherOfferDetail.length > 0) && <div className='challenge-preview'>
                            <table className='challenge-task-tabel'>
                                <thead>
                                    <th>Refer Image</th>
                                    <th>number Of Refer</th>
                                    <th>minRefer Earning</th>
                                    <th>maxRefer Earning</th>
                                    <th>winning Coin</th>
                                    <th>Edit</th>
                                    <th>Delete</th>
                                </thead>
                                <tbody>
                                    {otherOfferDetail.map((item, i) => {
                                        return (
                                            <tr key={i}>
                                                <td>
                                                    <img src={item?.referImageLink} alt="" width="100%" height="40px" />
                                                </td>
                                                <td>
                                                    {item?.numberOfRefer}
                                                </td>
                                                <td>{item?.minimumReferEarning}</td>
                                                <td>{item?.maximumReferEarning}</td>
                                                <td>{item?.winningPercentage ? `${item?.winningPercentage} %` : `${item?.winningCoin} coin`}</td>
                                                <td><Edit
                                                    onClick={() => {
                                                        setOpenReferDialog(true)
                                                        setOthers(item)
                                                        setTaskIndex(i)
                                                        setTaskEditable(true)
                                                    }}
                                                /></td>
                                                <td><Delete onClick={() => {
                                                    handleReferDelete(i)
                                                }} /></td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>}
                    </div>


                    <div className="challenge-field">
                        <p className='challenge-heading'>PlatForm <Add className='addReward' onClick={() => { setOpenPlatForm(true) }} /></p>

                        {(platForms?.length > 0) && <div className='challenge-preview'>
                            <table className='challenge-task-tabel'>
                                <thead>
                                    <th>Earning Coin</th>
                                    <th>Edit</th>
                                    <th>Delete</th>
                                </thead>
                                <tbody>
                                    {platForms.map((item, i) => {
                                        return (
                                            <tr key={i}>
                                                <td>{item?.earningCoins}</td>
                                                <td><Edit
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => {
                                                        setOpenPlatForm(true)
                                                        setPlatFormData(item)
                                                        setTaskIndex(i)
                                                        setTaskEditable(true)
                                                    }}
                                                /></td>
                                                <td><Delete
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => {
                                                        setTaskIndex(i)
                                                        handlePlateFormDelete(i)
                                                    }} /></td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>}
                    </div>

                    <button
                        className='challenge-button'
                        onClick={() => { handleIsChallengeMet() }}
                    >
                        Submit
                    </button>
                </div>
            </div>


            <Dialog
                open={open}
                onClose={() => handleClose()}
                TransitionComponent={Transition}
                className="dialogs"
            >
                <div className='categoryEditBox challenge-offer-dialog'>
                    <div className='editCover fullCover offer-challenge'>
                        <p>
                            offer Image
                        </p>

                        <div className="pRelative">
                            <label htmlFor="offerimage">
                                <Picadd className="addImgae" />
                                <input
                                    type="file"
                                    id="offerimage"
                                    name="photo"
                                    multiple
                                    onChange={(e) => handleImage(e, 1)}
                                    className="filetag"
                                />
                            </label>
                        </div>
                        {offers?.offerImageLink && <img src={offers?.offerImageLink} alt="" className="banner-image" />}
                    </div>


                    <div>
                        <p>
                            Offer Id
                        </p>
                        <input
                            name="offerid"
                            placeholder={'Enter Offer id'}
                            onChange={(e) => {
                                setOffers({ ...offers, offerId: e.target.value });
                            }}
                            type="text"
                            value={offers?.offerId}
                            className="inputTag"
                        />
                    </div>

                    <div className='editCover fullCover offer-challenge'>
                        <p>
                            Earning Coins
                        </p>
                        <input
                            name="earning"
                            placeholder={'Enter Earning Coins'}
                            onChange={(e) => {
                                setOffers({ ...offers, earningCoins: Number(e.target.value) });

                            }}
                            type="text"
                            value={offers?.earningCoins}
                            className="inputTag"
                        />
                    </div>
                    <div className='editCover fullCover offer-challenge'>
                        <p>
                            Winning Coin<span className='in-minutes'>(in percent)</span>
                        </p>

                        <select
                            id="numberSelect"
                            className='input-tag-selectbox'
                            value={offers?.winningPercentage}
                            disabled={offers.winningCoin ? true : false}
                            onChange={(e) => {
                                setOffers({ ...offers, winningPercentage: Number(e.target.value) });
                            }}>
                            {options.map((option) => (
                                <option key={option} value={option}>
                                    {option}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className='editCover fullCover offer-challenge'>
                        <p>
                            Winning Coin
                        </p>
                        <input
                            name="winningCoin"
                            placeholder={'Enter Winning coin'}
                            disabled={offers.winningPercentage ? true : false}
                            onChange={(e) => {
                                setOffers({ ...offers, winningCoin: Number(e.target.value) });
                            }}
                            type="text"
                            value={offers?.winningCoin}
                            className="inputTag"
                        />
                    </div>

                    <div className='addButton' onClick={() => taskEditable ? handleOfferEdit(taskIndex, offers) : handleAddOfferDetail()}>{taskEditable ? "Edit" : "Add Offer"}</div>
                </div>
            </Dialog>

            <Dialog
                open={openPartnerDialog}
                onClose={() => handleClose()}
                TransitionComponent={Transition}
                className="dialogs"
            >
                <div className='dialogPartnerChallenge'>
                    <p className='challenge-heading'>Add Partner <Add className='addReward' onClick={() => { setOpenPartner(true) }} /></p>
                    {(partners?.partnerInfo?.length > 0) && <table className='challenge-task-tabel'>
                        <thead>
                            <th>Image</th>
                            <th>Name</th>
                            <th>Delete</th>
                        </thead>
                        <tbody>
                            {partners?.partnerInfo?.map((item, i) => {
                                return (
                                    <tr key={i}>
                                        <td>
                                            <img src={item?.partnerImageLink} alt="Partner Image" width="50px" />
                                        </td>
                                        <td>
                                            {item?.name}
                                        </td>
                                        <td><Delete onClick={() => {
                                            handleDeleteSingle(i)
                                        }} /></td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>}
                </div>


                <div className='categoryEditBox challenge-offer-dialog'>

                    <div className='editCover fullCover offer-challenge'>
                        <p>
                            Min Coin Value
                        </p>
                        <input
                            name="min-coin"
                            placeholder={'Enter Min Coin'}
                            disabled={partners?.earningCoins ? true : false}
                            type="number"
                            value={partners?.minimumValue}
                            className="inputTag"
                            onChange={(e) => {
                                setPartners({ ...partners, minimumValue: Number(e.target.value) });
                            }}
                            onWheel={(e) => e.target.blur()}
                        />
                    </div>
                    <div className='editCover fullCover offer-challenge'>
                        <p>
                            Max Coin Value
                        </p>
                        <input
                            name="max-coin"
                            placeholder={'Enter Max Coin'}
                            disabled={partners?.earningCoins ? true : false}
                            type="number"
                            value={partners?.maximumValue}
                            className="inputTag"
                            onChange={(e) => {
                                setPartners({ ...partners, maximumValue: Number(e.target.value) });
                            }}
                            onWheel={(e) => e.target.blur()}
                        />
                    </div>
                    <div className='editCover fullCover offer-challenge'>
                        <p>
                            Offer Quantity
                        </p>
                        <input
                            name="offerquentity"
                            placeholder={'Enter Offer Quantity'}
                            disabled={partners?.earningCoins ? true : false}
                            type="number"
                            value={partners?.offerQuantity}
                            className="inputTag"
                            onChange={(e) => {
                                setPartners({ ...partners, offerQuantity: Number(e.target.value) });
                            }}
                            onWheel={(e) => e.target.blur()}

                        />
                    </div>
                    <div className='editCover fullCover offer-challenge'>
                        <p>
                            Earning Coins
                        </p>
                        <input
                            name="earning"
                            placeholder={'Enter Earning Coins'}
                            disabled={partners?.minimumValue || partners?.maximumValue || partners?.offerQuantity ? true : false}
                            onChange={(e) => {
                                setPartners({ ...partners, earningCoins: Number(e.target.value) });
                            }}
                            onWheel={(e) => e.target.blur()}
                            type="number"
                            value={partners?.earningCoins}
                            className="inputTag"
                        />
                    </div>
                    <div className='editCover fullCover offer-challenge'>
                        <p>
                            Winning Coin<span className='in-minutes'>(in percent)</span>
                        </p>

                        <select
                            id="numberSelect"
                            className='input-tag-selectbox'
                            value={partners?.winningPercentage}
                            disabled={partners?.winningCoin ? true : false}
                            onChange={(e) => {
                                setPartners({ ...partners, winningPercentage: Number(e.target.value) });
                            }}>
                            {options.map((option) => (
                                <option key={option} value={option}>
                                    {option}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className='editCover fullCover offer-challenge'>
                        <p>
                            Winning Coin
                        </p>
                        <input
                            name="winningCoin"
                            placeholder={'Enter Winning Percentage'}
                            disabled={partners?.winningPercentage ? true : false}
                            onChange={(e) => {
                                setPartners({ ...partners, winningCoin: Number(e.target.value) });
                            }}
                            onWheel={(e) => e.target.blur()}
                            type="number"
                            value={partners?.winningCoin}
                            className="inputTag"
                        />
                    </div>
                    <div className='addButton' onClick={() => taskEditable ? handlePartnerEdit(taskIndex, partners) : handlePartnerAdd()}>{taskEditable ? "Edit" : "Add Partner"}</div>
                </div>
            </Dialog>

            <Dialog
                open={openReferDialog}
                onClose={() => handleClose()}
                TransitionComponent={Transition}
                className="dialogs"
            >
                <div className='categoryEditBox challenge-offer-dialog'>

                    <div className='editCover fullCover offer-challenge'>
                        <p>
                            Refer Image
                        </p>

                        <div className="pRelative">
                            <label htmlFor="referimage">
                                <Picadd className="addImgae" />
                                <input
                                    type="file"
                                    id="referimage"
                                    // key={fileInputKey}
                                    name="photo"
                                    multiple
                                    onChange={(e) => handleImage(e, 3)}
                                    className="filetag"
                                />
                            </label>
                        </div>
                        {others?.referImageLink && <img src={others?.referImageLink} alt="" className="banner-image" />}
                    </div>

                    <div className='editCover fullCover offer-challenge'>
                        <p>
                            Number of Refer
                        </p>
                        <input
                            name="partnerid"
                            placeholder={'Enter Number Of Refer'}
                            onChange={(e) => {
                                setOthers({ ...others, numberOfRefer: Number(e.target.value) });
                            }}
                            type="number"
                            value={others?.numberOfRefer}
                            className="inputTag"
                        />
                    </div>
                    <div className='editCover fullCover offer-challenge'>
                        <p>
                            Min Refer Earning
                        </p>
                        <input
                            name="min-refer-earning"
                            placeholder={'Enter Refer Earning'}
                            onChange={(e) => {
                                setOthers({ ...others, minimumReferEarning: Number(e.target.value) });
                            }}
                            type="number"
                            value={others?.minimumReferEarning}
                            className="inputTag"
                        />
                    </div>
                    <div className='editCover fullCover offer-challenge'>
                        <p>
                            Max Refer Earning
                        </p>
                        <input
                            name="max-coin"
                            placeholder={'Enter Max Refer Earning'}
                            onChange={(e) => {
                                setOthers({ ...others, maximumReferEarning: Number(e.target.value) });
                            }}

                            type="number"
                            value={others?.maximumReferEarning}
                            className="inputTag"
                        />
                    </div>



                    <div className='editCover fullCover offer-challenge'>
                        <p>
                            Winning Coin<span className='in-minutes'>(in percent)</span>
                        </p>



                        <select
                            id="numberSelect"
                            className='input-tag-selectbox'
                            value={others?.winningPercentage}
                            disabled={others?.winningCoin ? true : false}
                            onChange={(e) => {
                                setOthers({ ...others, winningPercentage: Number(e.target.value) });
                            }}>
                            {options.map((option) => (
                                <option key={option} value={option}>
                                    {option}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className='editCover fullCover offer-challenge'>
                        <p>
                            Winning Coin
                        </p>
                        <input
                            name="winningCoin"
                            placeholder={'Enter Winning Percentage'}
                            disabled={others?.winningPercentage ? true : false}
                            onChange={(e) => {
                                setOthers({ ...others, winningCoin: Number(e.target.value) });
                            }}
                            type="number"
                            value={others?.winningCoin}
                            className="inputTag"
                        />
                    </div>

                    <div className='addButton' onClick={() => taskEditable ? handleReferEdit(taskIndex, others) : handleReferOfferDetails()}>{taskEditable ? "Edit" : "Add"}</div>
                </div>

            </Dialog>

            <Dialog
                open={openPartner}
                onClose={() => handleClose()}
                TransitionComponent={Transition}
                className="dialogs"
            >
                <div className="task-success-message">
                    <h2>
                        Add Partner
                    </h2>
                    <div className='xyzdialog'>
                        <div className='editCover fullCover offer-challenge'>
                            <p>
                                Partner Image
                            </p>
                            <div className="pRelative">
                                <label htmlFor="partnerimage">
                                    <Picadd className="addImgae" />
                                    <input
                                        type="file"
                                        id="partnerimage"
                                        name="photo"
                                        multiple
                                        onChange={(e) => handleImage(e, 2)}
                                        className="filetag"
                                    />
                                </label>
                            </div>
                            {partnerImageData?.partnerImageLink && <img src={partnerImageData?.partnerImageLink} alt="" className="banner-image" />}
                        </div>

                        <div>
                            <p>
                                Partner Id
                            </p>
                            <Autocomplete
                                disablePortal
                                value={partnerImageData?.name != null ? partnerImageData?.name : ''}
                                onChange={(event, newValue) => {
                                    if (newValue) {

                                        setPartnerImageData({ ...partnerImageData, partnerId: newValue._id, name: newValue.name });
                                    }
                                    else {

                                        setPartnerImageData({ ...partnerImageData, partnerId: "", name: "" })
                                    }
                                }}
                                options={allProvider}
                                getOptionLabel={(option) => option['name'] || option}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Provider"
                                    />
                                )}
                            />

                        </div>
                    </div>
                    <button className='addPartnernDialog' onClick={() => { handlePartnerImage(); }}>Add</button>
                </div>
            </Dialog>


            <Dialog
                open={taskOpen}
                onClose={() => handleClose()}
                TransitionComponent={Transition}
                className="dialogs"
            >
                <div className="task-success-message">
                    <h2>
                        {message}
                    </h2>
                    <button className='ok-cha-button' onClick={() => {
                        setTaskOpen(false);
                        setChallengeInfo(
                            {
                                taskName: "",
                                description: [],
                                duration: 0,
                                taskStartedAt: "",
                                taskEndedAt: "",
                                payableCoins: 0,
                                challenges: []
                            }
                        )
                        navigate("/allchallenge")
                    }}>Ok</button>
                </div>

            </Dialog>


            <Dialog
                open={openPlatForm}
                onClose={() => handleClose()}
                TransitionComponent={Transition}
                className="dialogs"
            >
                <div className='categoryEditBox challenge-offer-dialog'>

                    <div className='editCover fullCover offer-challenge'>
                        <p>
                            Earning Coins
                        </p>
                        <input
                            name="earning"
                            placeholder={'Enter Earning Coins'}
                            onChange={(e) => {
                                setPlatFormData({ ...platFormData, earningCoins: Number(e?.target?.value) });
                            }}
                            type="text"
                            value={platFormData.earningCoins}
                            className="inputTag"
                        />
                    </div>

                    <div className='addButton' onClick={() => taskEditable ? handlePlatFormEdit(taskIndex, platFormData) : handleAddPlatForm()}>{taskEditable ? "Edit" : "Save"}</div>
                </div>
            </Dialog>


            <Dialog
                open={openTrackValueMessage}
                onClose={() => handleClose()}
                TransitionComponent={Transition}
                className="dialogs"
            >
                <div className='trackValueMessage-dialog'>

                    <div className='trackValue-message-para'>
                        <p>Track Type is {trackType}</p>
                        <div>
                            {trackArray[trackType]?.includes('\n') ? (
                                trackArray[trackType]
                                    .split('\n')
                                    .map((line, index) => (
                                        <p key={index}>{line.trim()}</p>
                                    ))
                            ) : (
                                <p>{trackArray[trackType]}</p>
                            )}
                        </div>
                    </div>
                    <div className="alert-dialog-box-trackValue">
                        <button className='alert-dialog-button' onClick={() => { setOpenTrackValueMessagge(false) }}>No</button>
                       {trackType != 17 && <button className='alert-dialog-button' disabled={isSubmitting} onClick={() => { handleCreateChallenge() }} >Ok</button>}
                    </div>

                </div>

            </Dialog>


            <AlertDialog
                alertDialogOpen={alertDialogOpen}
                setAlertDialogOpen={setAlertDialogOpen}
                msg={dialogMessage}
                showAlertSign={showAlertSign}
                setShowAlertSigh={setShowAlertSigh}
            />

        </>
    )
}

export default Challenge
