import React, { useEffect, useState } from 'react'
import axiosClient from '../axios';
import SideBar from './SideBar';
import "../assets/css/profile.css"
import JoditEditor from "jodit-react";
import { Autocomplete } from "@mui/material";
import { ReactComponent as Picadd } from "../assets/images/newAddPic.svg";
import { TextField } from "@material-ui/core";
import { useLocation, useNavigate } from 'react-router-dom';

function EditBlog() {
    const search = useLocation().search;
    const offerId = new URLSearchParams(search).get("blog_id");
    const [vouhcerImage, setVoucherImage] = useState("")
    const [fileInputKey, setFileInputKey] = useState(Date.now());
    const [allCategory, setAllCatgeory] = useState([]);

    const handlefileInput = async (e) => {
        setFileInputKey(Date.now());
        const photo = new FormData();
        const selectedFile = e.target.files[0];
        const fileSizeInMb = selectedFile.size / (1024 * 1024);
        if (fileSizeInMb > 5) {
            alert('File size is too large. Please choose a file smaller than 5 MB.');
        } else {
            photo.append("photo", e.target.files[0]);
            await axiosClient
                .post(`/uploadImage`, photo)
                .then((res) => {
                    setVoucherImage(res.data.response.url)
                    setFormData({ ...formData, image: res.data.response.url })
                })
                .catch((err) => console.log(err));
        }
    };

    const [is_voucherActive, setIs_voucherActive] = useState("false");
    const [formData, setFormData] = useState({
        title: "",
        is_active: true,
        content: "",
        category: "",
        image: "",
        description: "",
        metaDescription: "",
        url: ""
    });
    const navigate = useNavigate();
    const getCatgeories = () => {
        axiosClient
            .get("/getCategory")
            .then((res) => {
                setAllCatgeory(res.data.response);
            })
            .catch((error) => console.log(error));
    }
    const getOffer = () => {
        axiosClient
            .get(`/admin/oneBlog/${offerId}`)
            .then((res) => {
                const data = res.data.response
                setFormData({
                    title: data.title,
                    is_active: data.is_active,
                    content: data.content,
                    image: data.image,
                    description: data.description,
                    metaDescription: data.metaDesc,
                    url: data.metaUrl
                })
                setVoucherImage(data.image)
                data.is_active ? setIs_voucherActive("true") : setIs_voucherActive("false")
            })
            .catch((error) => console.log(error));
    }
    const sendData = () => {
        axiosClient
            .put(`admin/editBlog/${offerId}`, formData)
            .then((res) => {
                if (res.data.status === true) {
                    alert("Offer has been edited successfully")
                    navigate("/allBlogs");
                }
            })
            .catch((error) =>
                console.log(error)
            );
    }
    useEffect(() => {
        getCatgeories();
        getOffer();
    }, [])
    return (
        <>
            <SideBar handleSideBar={true} activeTab={"vouhcer"} />
            <div className='screen'>

                <h2 className='screenHeading'>Add Vouhcer</h2>

                <div className='vouhcerInfoBox'>
                    <div className='dataTable'>
                        <div className='editCover editBio   '>
                            <p>
                                Title
                            </p>
                            <input
                                name="name"
                                placeholder={'Title'}
                                onChange={(e) => {
                                    setFormData({ ...formData, title: e.target.value });
                                }}
                                value={formData.title}
                                className="inputTag"
                            />
                        </div>
                        <div className='editCover editBio   '>
                            <p>
                                description
                            </p>
                            <input
                                name="description"
                                placeholder={'description'}
                                onChange={(e) => {
                                    setFormData({ ...formData, description: e.target.value });
                                }}
                                value={formData.description}
                                className="inputTag"
                            />
                        </div>
                        <div className='editCover editBio'>
                            <p>
                                Meta Description
                            </p>
                            <input
                                name="metadescription"
                                placeholder={'Meta Description'}
                                onChange={(e) => {
                                    setFormData({ ...formData, metaDescription: e.target.value });
                                }}
                                value={formData.metaDescription}
                                className="inputTag"
                            />
                        </div>

                        <div className='editCover editBio'>
                            <p>
                                URL
                            </p>
                            <input
                                name="URL"
                                placeholder={'URL'}
                                onChange={(e) => {
                                    setFormData({ ...formData, url: e.target.value });
                                }}
                                value={formData.url}
                                className="inputTag"
                            />
                        </div>


                        <div className='editCover editBio'>
                            <p>
                                Is Blog active
                            </p>
                            <div className="pRelative">
                                <input

                                    type="radio"
                                    checked={is_voucherActive === "true" ? true : false}
                                    className="rounded"
                                    value={'true'}
                                    onChange={(e) => {
                                        setIs_voucherActive(e.target.value);
                                        setFormData({ ...formData, is_active: true });
                                    }}
                                />
                                <p className='inline'>True</p>
                                <input

                                    type="radio"
                                    checked={is_voucherActive === "false" ? true : false}
                                    className="rounded"
                                    value={'false'}
                                    onChange={(e) => {
                                        setIs_voucherActive(e.target.value);
                                        setFormData({ ...formData, is_active: false });
                                    }}
                                />
                                <p className='inline'>False</p>
                            </div>
                        </div>

                        <div className='editCover editBio'>
                            <p>
                                Category
                            </p>
                            <Autocomplete
                                disablePortal
                                onChange={(event, newValue) => {
                                    setFormData({ ...formData, category: newValue?.name });

                                }}
                                options={allCategory}
                                getOptionLabel={option => option.name}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Category"
                                    />
                                )}
                            />

                        </div>


                        <div className='editCover editBio'>
                            <p>
                                Content
                            </p>
                            <div className="pRelative">
                                <JoditEditor
                                    value={formData.content}
                                    onChange={(newContent) =>
                                        setFormData({ ...formData, content: newContent })
                                    }
                                />
                            </div>
                        </div>

                        <div className='editCover editBio'>
                            <p>
                                Blog Image
                            </p>
                            <div className="pRelative">
                                <label htmlFor="inputTagField">
                                    <Picadd className="addImgae" />
                                    <input
                                        type="file"
                                        id="inputTagField"
                                        key={fileInputKey}
                                        name="photo"
                                        multiple
                                        onChange={(e) => handlefileInput(e)}
                                        className="filetag"
                                    />
                                </label>

                            </div>
                            <div>
                                <img src={vouhcerImage} alt="voucherimgae" className='voucher_image' />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='editButton' onClick={() => sendData()}>Edit Blog</div>
            </div>
        </>
    )
}

export default EditBlog