
import Papa from 'papaparse';

const CSVDownload = (dataToExport) => {
    try {
        const csvData = Papa.unparse(dataToExport);
        const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        link.href = url;
        link.setAttribute('download', 'data.csv');
        link.click();
        URL.revokeObjectURL(url);
    } catch (error) {
        console.error('Error downloading CSV:', error);
    }
};

export default CSVDownload;
