import React, { useState, useEffect } from 'react'
import axiosClient from '../axios';
import { useNavigate } from 'react-router-dom';
import SideBar from './SideBar';
import loader from '../assets/images/loader.gif'
import { ReactComponent as Edit } from "../assets/images/edit.svg";



const AllMultiPageData = () => {
    const [data, setData] = useState([])
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(true)

    const getAllMultiPageData = () => {
        setIsLoading(false)
        axiosClient.get('/getAllMultiPageData')
            .then((res) => {
                console.log("res===>", res)
                setData(res?.data?.response)
            })
            .catch((err) => console.log(err))
            .finally(()=>setIsLoading(true))
    }

    useEffect(() => {
        getAllMultiPageData()
    }, [])


    return (
        <>
            <SideBar handleSideBar={true} activeTab={"miltipageData"} />
            <div className="screen">
                <div className="add-task-chall">
                    <h2>All Multi Page Data</h2>
                    <button onClick={() => {
                        navigate("/multiPageData")
                    }}>Add MultiPage Data</button>
                </div>

                <div className='profileInfoBox table'>
                    {isLoading ? <> {

                        data?.length > 0 ?

                            <div style={{ width: '100%' }} className="container app-body">
                                <div className='tabel-scrol'>
                                    <table id="users">
                                        <tr>
                                        <th style={{ width: '200px' }} >S.N.</th>
                                            <th style={{ width: '200px' }}>Page Name</th>
                                            <th style={{ width: '200px' }}>Edit</th>

                                        </tr>
                                        {
                                            data?.map((val, key) => {
                                                return (
                                                    <tr key={key}>
                                                        <td>{key+1}</td>
                                                        <td>{val?.pagename}</td>
                                                        <td><Edit
                                                            style={{ cursor: 'pointer' }}
                                                            onClick={() => {
                                                                navigate(`/editmultiPageData/${val?._id}`)
                                                            }}
                                                        /></td>

                                                    </tr>
                                                )
                                            })
                                        }


                                    </table>
                                </div>
                            </div>

                            : null
                    }

                    </>
                        :
                        <>
                            <div className='loaderImage'>
                                <img src={loader} alt='loader' />
                            </div>
                        </>
                    }
                </div>

            </div>
        </>
    )
}

export default AllMultiPageData
