import React, { useEffect, useState } from 'react'
import SideBar from './SideBar';
import axiosClient from '../axios';
import Slide from '@mui/material/Slide';
import Pagination from './Pagination';
import Dialog from '@mui/material/Dialog';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function Admins() {
    const [pagination, setPagination] = useState({
        page: 0,
        limit: 100
    });
    const [totalPage, setTotalPage] = useState(10)
    const [data, setData] = useState([])

    const [refressPage, setRefressPage] = useState(false);
    const [open, setOpen] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [currentId, setCurrentId] = useState('')

    const [newAdmin, setNewAdmin] = useState({
        adminUsername: "",
        password: "",
        email: "",
        role: ""
    });
    const [show, setShow] = useState(false)
    const [option, setOptions] = useState('')
    const getAdmins = () => {
        axiosClient
            .get(`/getAllAdmins?page=${pagination.page}&limit=${pagination.limit}`)
            .then((res) => {
                setData(res.data?.response?.totalData)
                setTotalPage(res?.data?.response?.totalCount[0]?.count)
            })
            .catch((error) =>
                console.log(error)
            );
    }

    const handleDelete = () => {
        setOpen(false);
        setOptions("Select Role")
    }

    const handleDeleteAdmins = () => {
        setIsOpen(false);
        setNewAdmin({
            adminUsername: "",
            password: "",
            email: "",
            role: ""
        })
    }

    function addAdmin() {
        const formData = {
            adminUsername: newAdmin.adminUsername,
            password: newAdmin.password,
            email: newAdmin.email,
            role: newAdmin.role,
        }

        if (formData.adminUsername === "" || formData.password === "" || formData.email === "" || formData.role === "") {
            alert("Please Enter All Field Values");
            return
        }

        if (formData.role !== "admin" && formData.role !== "viewer") {
            alert("Role value should be 'admin' or 'viewer' ")
            return
        }

        axiosClient
            .post(`/newAdmin`, formData)
            .then((res) => {
                setNewAdmin({
                    adminUsername: "",
                    password: "",
                    email: "",
                    role: "",
                });
                if (res?.data?.status === true) {
                    setRefressPage(!refressPage)
                }
                setIsOpen(false);
            })
    }

    function editRole() {
        const formData = {
            id: currentId,
            role: option,
        }
        axiosClient
            .put(`/editRole`, formData)
            .then((res) => {
                if (res?.data?.status === true) {
                    setRefressPage(!refressPage)
                }
                setCurrentId('');
                setOptions('');
                setIsOpen(false);
            })
    }
    const toggleForm = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        getAdmins()
    }, [pagination.page, pagination.limit, refressPage])

    return (
        <>
            <SideBar handleSideBar={true} activeTab={"admins"} />
            <div className="screen">
                <div className='dFlex adminPageButton'>
                    <h2 className='screenHeading catHead'>Admins</h2>
                    <button className='addAdminButton' onClick={toggleForm}>Add Admin</button>
                </div>
                <div className='profileInfoBox table'>
                    <>
                        <div style={{ width: '100%' }} className="container app-body">
                            <div className='tabel-scrol'>
                                <table id="users">
                                    <thead>
                                        <tr>
                                            <th style={{ width: '30px' }}>S.NO.</th>
                                            <th style={{ width: '80px' }}>UserName</th>
                                            <th style={{ width: '180px' }}>Email Id</th>
                                            <th style={{ width: '100px' }}>Role</th>
                                            <th style={{ width: '50px' }}>Edit</th>
                                        </tr>
                                    </thead>
                                    {data?.map((val, key) => {
                                        return (
                                            <tbody key={key}>
                                                <tr>
                                                    <td>{(pagination?.page * pagination?.limit) + (key + 1)}</td>
                                                    <td >{val?.adminUsername}</td>
                                                    <td>{val?.email}</td>
                                                    <td>{val.role}</td>
                                                    <td>{val?.role != "superAdmin" && <p className='TransactionBox' onClick={() => { setOpen(true); setCurrentId(val?._id) }} >Edit Role </p>}</td>
                                                </tr>
                                            </tbody>
                                        )
                                    })
                                    }
                                </table>
                            </div>

                            <div>
                                <Pagination
                                    filterDataInput={pagination}
                                    setFilterDataInput={setPagination}
                                    totalPage={totalPage}
                                />
                            </div>

                            {/* <div className='userTablePagination'>
                                <label>Rows per page:</label>
                                <select value={rowPerPage} onChange={(e) => { setRowPerPage(e.target.value); setCurrentPage(0) }}>
                                    <option value={10}>10</option>
                                    <option value={50}>50</option>
                                    <option value={100}>100</option>
                                    <option value={500}>500</option>
                                    <option value={1000}>1000</option>
                                    <option value={1500}>1500</option>
                                </select>

                                <span className='currentPagespan'> Current Page {currentPage + 1}/{Math.ceil(totalPage / rowPerPage)} </span>

                                {currentPage !== 0 &&
                                    (<button className='paginationButton' onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 0}>
                                        Previous
                                    </button>)
                                }

                                {Math.ceil(totalPage / rowPerPage) !== 1 && Math.ceil(totalPage / rowPerPage) != currentPage + 1 &&
                                    (<button className='paginationButton'
                                        onClick={() => setCurrentPage(currentPage + 1)}
                                        disabled={currentPage + 1 >= totalPage / rowPerPage}
                                    >
                                        Next
                                    </button>)}
                            </div> */}

                        </div>

                    </>
                </div>

            </div>

            <Dialog
                open={open}
                onClose={() => handleDelete()}
                TransitionComponent={Transition}
                className="dialogs"
            >
                <div className='categoryEditBox categoryEditBoxAdmin'>
                    <h3>Change Role</h3>
                    <div style={{ position: 'relative' }} className="editButton addCategory dBlock" onClick={() => setShow(!show)}>
                        <div >
                            {option === '' ? "select Role" : option}
                        </div>
                        <div className={show ? `drop` : `drop_hide`}>
                            <span onClick={(e) => { setShow(false); setOptions(e.target.innerHTML); }} >admin</span>
                            <span onClick={(e) => { setShow(false); setOptions(e.target.innerHTML); }}>viewer</span>
                            <span onClick={(e) => { setShow(false); setOptions(e.target.innerHTML); }}>superAdmin</span>
                        </div>
                    </div>
                    {
                        <div className='editButton editButtonAdmin' onClick={() => { editRole(); setOpen(false) }}>Submit</div>
                    }
                </div>
            </Dialog>
            <Dialog
                open={isOpen}
                onClose={() => handleDeleteAdmins()}
                TransitionComponent={Transition}
                className="dialogs"
            >
                <div className='categoryEditBox'>
                    <>
                        <h3>Add Admin</h3>
                        <div className="pRelative w300">
                            <input
                                type="text"
                                name="name"
                                placeholder='Enter Name'
                                onChange={(e) => {
                                    setNewAdmin({ ...newAdmin, adminUsername: e.target.value })
                                }}
                                value={newAdmin.adminUsername}
                                className="inputTag partnerInput"
                            />
                        </div>

                        <div className="pRelative w300">
                            <input
                                type="email"
                                name="email"
                                placeholder='Enter Email'
                                onChange={(e) => {
                                    setNewAdmin({ ...newAdmin, email: e.target.value })
                                }}
                                value={newAdmin.email}
                                className="inputTag partnerInput"
                            />
                        </div>
                        <div className="pRelative w300">
                            <input
                                type="password"
                                name="password"
                                placeholder='Enter Password'
                                onChange={(e) => {
                                    setNewAdmin({ ...newAdmin, password: e.target.value })
                                }}
                                value={newAdmin.password}
                                className="inputTag partnerInput"
                            />
                        </div>
                        <div className="pRelative w300">
                            <input
                                type="text"
                                name="role"
                                placeholder='Enter Role'
                                onChange={(e) => {
                                    setNewAdmin({ ...newAdmin, role: e.target.value })
                                }}
                                value={newAdmin.role}
                                className="inputTag partnerInput"
                            />
                        </div>
                        {
                            <div className='editButton' onClick={() => { addAdmin() }} >Submit</div>
                        }
                    </>
                </div>
            </Dialog>
        </>
    )
}

export default Admins