import React, { useEffect, useState } from 'react'
import SideBar from './SideBar';
import axiosClient from '../axios';
import { ReactComponent as Edit } from "../assets/images/edit.svg";
import { ReactComponent as Add } from "../assets/images/add.svg";
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import CSVDownload from "../screens/CSVDownload"
import loader from '../assets/images/loader.gif'
import Pagination from './Pagination';

function AllBlogs() {
    const navigate = useNavigate()

    const [pagination, setPagination] = useState({
        page: 0,
        limit: 100
    })

    const [rowPerPage, setRowPerPage] = useState(100);
    const [currentPage, setCurrentPage] = useState(0);

    const [totalPage, setTotalPage] = useState(10)
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)

    const getOffers = () => {
        setLoading(true)
        axiosClient
            .get(`/admin/allblogs?page=${pagination?.page}&limit=${pagination?.limit}`)
            .then((res) => {
                setData(res.data?.response?.totalData)
                setTotalPage(res?.data?.response?.totalCount[0]?.count)
            }).catch((error) =>
                console.log(error)
            ).finally(() => setLoading(false))
    }

    const handleDownload = async () => {
        try {
            const dataToExport = data.map((item, key) => ({
                "S.NO.": (key + 1),
                "Title": item?.title,
                "Created At": moment.utc(item?.created_at).local().format("MMM Do YY, h:mm:ss a"),
                "updated At": moment.utc(item?.updated_at).local().format("MMM Do YY, h:mm:ss a"),
                "Is Active": item?.is_active
            }));
            CSVDownload(dataToExport);
        } catch (error) {
            console.error('Error downloading CSV:', error);
        }
    };

    useEffect(() => {
        getOffers()
    }, [pagination.page, pagination.limit])
    return (
        <>
            <SideBar handleSideBar={true} activeTab={"allblogs"} />
            <div className="screen">

                <div className='dFlex dFlex-2'>

                    <div>
                        <button className='csvButton' onClick={handleDownload}>Download CSV</button>
                    </div>
                    <div
                        className="addofferButton"
                        onClick={() => { navigate("/addBlog") }}>
                        <Add />Add Blog
                    </div>

                </div>
                <div>
                    <h2 className='screenHeading catHead'>All Blogs</h2>
                </div>

                <div className='profileInfoBox table'>
                    {
                        data?.length > 0 && !loading ?
                            <>
                                <div style={{ width: '100%' }} className="container app-body">
                                    <div className='tabel-scrol'>
                                        <table id="users">
                                            <thead>
                                                <tr>
                                                    <th style={{ width: '50px' }}>S.NO.</th>
                                                    <th style={{ width: '80px' }}>Title</th>
                                                    <th style={{ width: '100px' }}>Created At</th>
                                                    <th style={{ width: '130px' }}>updated At</th>
                                                    <th style={{ width: '100px' }}>Is Active</th>
                                                    <th style={{ width: '70px' }}>Edit</th>
                                                </tr>
                                            </thead>
                                            {data?.map((val, key) => {
                                                return (
                                                    <tbody key={key}>
                                                        <tr>
                                                            <td>{(pagination.page * pagination.limit) + (key + 1)}</td>
                                                            <td >{val?.title}</td>
                                                            <td>{moment.utc(val?.created_at).local().format("MMM Do YY, h:mm:ss a")}</td>
                                                            <td>{moment.utc(val?.updated_at).local().format("MMM Do YY, h:mm:ss a")}</td>
                                                            <td>{val?.is_active === true ? "true" : "False"}</td>
                                                            <td>{<Edit className='whiteRound' onClick={() => { navigate(`/editBlog?blog_id=${val?._id}`) }} />}</td>
                                                        </tr>
                                                    </tbody>
                                                )
                                            })
                                            }
                                        </table>
                                    </div>

                                    <div>
                                        <Pagination
                                            filterDataInput={pagination}
                                            setFilterDataInput={setPagination}
                                            totalPage={totalPage}
                                        />
                                    </div>

                                    {/* <div className='userTablePagination'>
                                        <label>Rows per page:</label>
                                        <select value={rowPerPage} onChange={(e) => { setRowPerPage(e.target.value); setCurrentPage(0) }}>
                                            <option value={10}>10</option>
                                            <option value={50}>50</option>
                                            <option value={100}>100</option>
                                            <option value={500}>500</option>
                                            <option value={1000}>1000</option>
                                            <option value={1500}>1500</option>
                                        </select>

                                        <span className='currentPagespan'> Current Page {currentPage + 1}/{Math.ceil(totalPage / rowPerPage)} </span>

                                        {currentPage !== 0 && (<button className='paginationButton' onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 0}>
                                            Previous
                                        </button>)}


                                        {Math.ceil(totalPage / rowPerPage) !== 1 && Math.ceil(totalPage / rowPerPage) != currentPage + 1 && (<button className='paginationButton'
                                            onClick={() => setCurrentPage(currentPage + 1)}
                                            disabled={currentPage + 1 >= totalPage / rowPerPage}
                                        >
                                            Next
                                        </button>)}
                                    </div> */}
                                </div>
                            </>
                            :
                            <>
                                <div className='loaderImage'>
                                    <img src={loader} alt='loader' />
                                </div>
                            </>

                    }
                </div>

            </div>


        </>
    )
}

export default AllBlogs