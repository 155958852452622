import React, { useEffect, useState } from 'react'
import SideBar from './SideBar'
import axiosClient from '../axios';
import { useNavigate } from 'react-router-dom';

function StaticOffers() {
    const [partners, setPartners] = useState([]);
    const navigate = useNavigate();
    const getPartners = () => {
        axiosClient
            .get("/partnersList?is_static=true",)
            .then((res) => setPartners(res.data.response))
            .catch((error) =>
                console.log(error)
            );
    }
    useEffect(() => {
        getPartners();
    }, [])

    return (
        <>
            <SideBar handleSideBar={true} activeTab={"staticApi"} />
            <div className="screen">
                <div className='addPartnerButton'>
                    <h2 className='screenHeading'>All Partners</h2>
                </div>
                <div className='brandsDisplayBox'>
                    {
                        partners.length > 0 && partners?.map((ele) => {
                            return (
                                <React.Fragment key={ele?._id}>
                                    <div className='displayBox' onClick={() => navigate(`/partnerOffer/${ele._id}`)}>
                                        <img src={ele?.image} alt="partnerLogoPic" />
                                        <p>{ele?.name}</p>
                                    </div>
                                </React.Fragment>
                            )
                        })
                    }

                </div>
            </div>
        </>
    )
}

export default StaticOffers