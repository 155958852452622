import React, { useState, useEffect } from 'react'
import SideBar from './SideBar'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { dateConvert } from '../dateConverter'
import axiosClient from '../axios';
import { TextField } from '@mui/material';
import { LineChart, Line, XAxis, YAxis, Tooltip } from 'recharts';

function Home() {
    const [date, setDate] = useState({
        start: null,
        end: null,
    })
    const [sevenRev, setSevenRev] = useState([])
    const [sevenUser, setSevenUser] = useState([])
    const [totalUser, setTotalUser] = useState(0);
    const [totalRevenue, setTotalRevenue] = useState(0);
    const [totalOffersCompleted, setTotalOffersCompleted] = useState(0);
    const [mostPerformedOffer, setMostPerformedOffer] = useState({
        name: "",
        attempts: 0
    });
    const [revenueData, setRevenueData] = useState([])
    const [userData, setUserData] = useState([])
    const [partnerList, setPartnerList] = useState([])
    const [dailyActiveUser, setDailyActiveuser] = useState(0)
    const [dailyRegisterUser, setDailyRegistereuser] = useState(0)
    const [dailyScratchCard, setDailyScratchCard] = useState({ count: 0, amount: 0 })
    const [dailySpinWheel, setDailySpinWheel] = useState({ count: 0, amount: 0 })

    const adminArray = JSON.parse(localStorage.getItem("admin"));
    const Role = adminArray?.role

    const Revenue = async () => {
        await axiosClient.get(`/admin/totalRevenue`)
            .then((res) => {
                let data = res.data.response
                setTotalRevenue(data[0].allrevenue)
            })
            .catch((err) => console.log(err))
    }
    const misc_data = async () => {
        await axiosClient.get(`/admin/misc_data`)
            .then((res) => {
                let data = res.data.response
                setTotalOffersCompleted(data.totalOffersCompleted)
                setMostPerformedOffer({
                    name: data.most_attempted[0].name,
                    attempts: data.most_attempted[0].attempts
                })
                setDailyActiveuser(data.allActiveUsers)
                setDailyRegistereuser(data.DailyRegisterUser)
                setPartnerList(data.partner)
                setTotalUser(data.overAllUsers);
                setDailyScratchCard({
                    count: data.sc_data[0]?.scratchCards, amount: data.sc_data[0]?.amountGiven,
                })
                setDailySpinWheel({
                    count: data.spin_data[0]?.spins, amount: data.spin_data[0]?.amountGiven,
                })
            })
            .catch((err) => console.log(err))
    }
    const weekData = async () => {
        await axiosClient.get(`/admin/weekData?s_date=${dateConvert(date?.start)}`)
            .then((res) => {
                let data = res.data.response
                setUserData(data.usersPerDay.reverse())
                let justValues = data.usersPerDay.map(x => x.users);
                setSevenUser(justValues)
                setRevenueData(data.revenuePerDay.reverse())
                let justValues2 = data.revenuePerDay.map(x => x.allrevenue);
                setSevenRev(justValues2.reverse())
            })
            .catch((err) => console.log(err))
    }
    const dashboard = () => {
        Revenue();
        misc_data()
    }
    useEffect(() => {
        dashboard();
    }, [])
    useEffect(() => {
        if (date.start) {
            weekData()
        }
    }, [date])
    return (
        <>
            <SideBar handleSideBar={true} activeTab={"Home"} />
            <div className='screen'>
                <div className='dFlex'>
                    <h2 className='screenHeading catHead'>Home Page</h2>
                </div>
                <div className='dFlex'>
                    <div className='analyticsArea'>
                        <h3 className='h3Heading'>Analytics</h3>
                        <div className='dFlex fWrap'>
                            <div className='graphBox'>
                                <h4 className='graphBoxHead'>Total Users</h4>
                                <div className='graphFlex'>
                                    <h2 className='analyticHead'>{totalUser}</h2>
                                </div>
                            </div>
                            {Role == "superAdmin" ? <div className='graphBox'>
                                <h4 className='graphBoxHead'>Total Revenue</h4>
                                <div className='graphFlex'>
                                    <h2 className='analyticHead'>{totalRevenue?.toFixed(2)}</h2>
                                </div>
                            </div> : ""}
                            <div className='graphBox'>
                                <h4 className='graphBoxHead'>Offers Completed</h4>
                                <div className='graphFlex'>
                                    <h2 className='analyticHead'>{totalOffersCompleted}</h2>
                                </div>
                            </div>
                            <div className='graphBox'>
                                <h4 className='graphBoxHead'>Most Performed Task</h4>
                                <div className='graphFlex'>
                                    <p className='performedName'>{mostPerformedOffer?.name?.length > 5
                                        ? mostPerformedOffer?.name?.slice(0, 5) + '...'
                                        : mostPerformedOffer?.name}</p>
                                    <h2 className='analyticHead'>{mostPerformedOffer?.attempts}</h2>

                                </div>
                            </div>

                            <div className='graphBox'>
                                <h4 className='graphBoxHead'>Today Active User</h4>
                                <div className='graphFlex'>
                                    <h2 className='analyticHead'>{dailyActiveUser}</h2>
                                </div>
                            </div>
                            <div className='graphBox'>
                                <h4 className='graphBoxHead'>Today Register User</h4>
                                <div className='graphFlex'>
                                    <h2 className='analyticHead'>{dailyRegisterUser}</h2>
                                </div>
                            </div>

                            {Role == "superAdmin" ? <div className='graphBox'>
                                <h4 className='graphBoxHead'>Daily Scratch Card</h4>
                                <div className='graphFlex'>
                                    <div>
                                        <h6>No.</h6><h2 className='analyticHead'>{dailyScratchCard?.count}</h2>
                                    </div>
                                    <div>
                                        <h6>Amount.</h6><h2 className='analyticHead2'>{dailyScratchCard?.amount}</h2>
                                    </div>
                                </div>
                            </div> : ""}

                            {Role == "superAdmin" ? <div className='graphBox'>
                                <h4 className='graphBoxHead'>Daily SpinWheel</h4>
                                <div className='graphFlex'>
                                    <div>
                                        <h6>No.</h6><h2 className='analyticHead'>{dailySpinWheel?.count}</h2>
                                    </div>
                                    <div>
                                        <h6>Amount.</h6> <h2 className='analyticHead2'>{dailySpinWheel?.amount}</h2>
                                    </div>
                                </div>
                            </div> : ""}

                            {Role == "superAdmin" ? <div className='graphBox'>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label="Start Date"
                                        value={date?.start}
                                        onChange={(value) => setDate({ ...date, start: value })}
                                        renderInput={(params) => <TextField {...params} />}
                                        maxDate={date?.end}
                                    />
                                </LocalizationProvider>
                            </div> : ""}
                        </div>
                        {Role == "superAdmin" ?
                            <div className="totalrevBox">
                                <div className='flexBoxes'>
                                    <div>
                                        <h2 className='screenHeading catHead'>Total Revenue</h2>
                                        <h2 className='screenHeading catHead'>{sevenRev?.reduce((a, b) => a + b, 0)}</h2>
                                    </div>
                                </div>

                                {revenueData.length > 0 ? <>

                                    <LineChart
                                        className="custom-line-chart"
                                        width={700}
                                        height={400}
                                        data={revenueData}
                                        margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
                                    >
                                        <XAxis dataKey="date" />
                                        <YAxis />
                                        <Tooltip />
                                        <Line type="monotone" dataKey="allrevenue" stroke="#ff7300" yAxisId={0} />
                                    </LineChart>
                                </> : null
                                }
                            </div> : ""}
                        {Role == "superAdmin" &&
                            <div className="totalrevBox">
                                <div className='flexBoxes'>
                                    <div>
                                        <h2 className='screenHeading catHead'>Total Users</h2>
                                        <h2 className='screenHeading catHead'>{sevenUser?.reduce((a, b) => a + b, 0)}</h2>
                                    </div>
                                </div>
                                {userData.length > 0 &&

                                    <LineChart
                                        className="custom-line-chart"
                                        width={700}
                                        height={400}
                                        data={userData}
                                        margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
                                    >
                                        <XAxis dataKey="date" />
                                        <YAxis />
                                        <Tooltip />
                                        <Line type="monotone" dataKey="users" stroke="#ff7300" yAxisId={0} />
                                    </LineChart>}
                            </div>}
                    </div>
                    {Role == "superAdmin" &&
                        <div className="revenueAnalytic">
                            <h3 className='revenueFromPart'>Revenue from partners</h3>
                            <div className='partnTable'>
                                <div className='cont_header'>
                                    <p>S.No.</p>
                                    <p>Partner</p>
                                    <p>Revenue</p>
                                    <p>Transaction</p>
                                </div>
                                {
                                    partnerList?.map((e, i) => {
                                        return <React.Fragment key={e._id}>
                                            <div className='partnTable_Cont'>
                                                <p>{i + 1}</p>
                                                <p>{e?.partnerName}</p>
                                                <p>{Math.floor(e?.revenue)}</p>
                                                <p>{e?.count.toFixed(2)}</p>

                                            </div>
                                        </React.Fragment>
                                    })
                                }
                            </div>
                        </div>}
                </div>


            </div>
        </>
    )
}

export default Home