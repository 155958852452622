import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import axiosClient from '../axios';
import SideBar from './SideBar'
import loader from '../assets/images/loader.gif'
import Slide from '@mui/material/Slide';
import moment from 'moment';
import Dialog from '@mui/material/Dialog';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function SingleOffer() {
    const { id } = useParams();
    const [offer, setOffer] = useState([]);
    const [isLoading, setIsLoading] = useState(true)
    const [userCompletedOffer, setUserCompletedOffer] = useState(0)
    const [rowPerPage, setRowPerPage] = useState(100);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPage, setTotalPage] = useState(10)
    const [data, setData] = useState([])
    const navigate = useNavigate();
    const [open, setOpen] = useState(false)

    const getPartners = () => {
        setIsLoading(false)
        axiosClient
            .get(`/oneOfferUser/${id}?page=${currentPage}&limit=${rowPerPage}&users=0`)
            .then((res) => {
                let data = res.data.response
                setOffer(data.oneOffer)
                setUserCompletedOffer(data.userCompletedteOffer)
                setData(res.data?.response?.getOffersUser)
                setTotalPage(res?.data?.response?.count)
                setIsLoading(true)
            })
            .catch((error) =>
                console.log(error)
            )
    }
    const showWinners = async () => {
        setIsLoading(false)
        await axiosClient
            .get(`/oneOfferUser/${id}?page=${currentPage}&limit=${rowPerPage}&users=1`)
            .then((res) => {
                let data = res.data.response
                setOffer(data.oneOffer)
                setUserCompletedOffer(data.userCompletedteOffer)
                setData(res.data?.response?.getOffersUser)
                setTotalPage(res?.data?.response?.count)
            })
            .catch((error) =>
                console.log(error)
            )
            .finally(() => setIsLoading(true))
    }

    const handleDelete = () => setOpen(false)


    useEffect(() => {
        getPartners();
    }, [rowPerPage, currentPage])
    return (
        <>
            <SideBar handleSideBar={true} activeTab={"allOffer"} />
            <div className="screen">
                <div className='edit-single-offer'>
                    <div className='dBlock sigleOfferdBlock dBlock-width'>
                        <h2 className='screenHeading catHead'>Offer Details</h2>
                        <p className='catPara'><span className='offer-para-val'>Name</span> :<span className='offer-overflow-x'>{offer?.name}</span> </p>
                        <p className='catPara'><span className='offer-para-val'>Total Amount Given</span> :<span>{offer?.amount_given}</span> </p>
                        <p className='catPara'><span className='offer-para-val'>Total Attempts</span> :<span>{offer?.attempts}</span></p>
                        <p className='catPara'>
                            <span className='offer-para-val'>Country</span>:
                            {
                                offer?.countries?.length > 3 ?
                                    (
                                        <>
                                            {offer?.countries.slice(0, 3).map((item, index) => (
                                                <React.Fragment key={item._id}>
                                                    {item.country}
                                                    {index !== offer?.countries?.length - 1 && ', '}
                                                </React.Fragment>
                                            ))}
                                            {'... '}
                                            <button onClick={() => setOpen(true)}>show More</button>
                                        </>
                                    )
                                    :
                                    offer?.countries?.map((item, index) => (
                                        <React.Fragment key={item._id}>
                                            {item.country}
                                            {index !== offer?.countries?.length - 1 && ', '}
                                        </React.Fragment>
                                    ))
                            }
                        </p>


                        <p className='catPara'><span className='offer-para-val'>Revenue and Payout Sum</span> : {offer?.rewards?.map((val, idx) => {
                            return <span>{val?.payOutAmount + val?.revenueAmount}</span>;
                        })}</p>

                        <p className='catPara'><span className='offer-para-val'>Number of User Completed offer</span> : {userCompletedOffer}</p>

                        <div
                            className="editButton in-singleOffer"
                            onClick={() => {
                                navigate(`/editVoucher?offer_id=${id}`);
                            }}
                        >
                            Edit Offer
                        </div>
                    </div>
                    {/* <div
                        className="editButton"
                        onClick={() => {
                            navigate(`/editVoucher?offer_id=${id}`);
                        }}
                    >
                        Edit Offer
                    </div> */}
                </div>



                <div className='profileInfoBox table'>
                    <div className='spaceFlex'>
                        <h5>Attempted Users</h5>
                        <div>
                            <button className='csvButton'
                                onClick={() => { showWinners() }}
                            >
                                Show Winners</button>
                        </div>
                    </div>


                    <>
                        <div className='profileInfoBox table'>
                            {isLoading ? <>
                                <div style={{ width: '100%' }} className="container app-body">
                                    <table id="users">
                                        <tr>
                                            <th style={{ width: '50px' }}>S.NO.</th>
                                            <th style={{ width: '80px' }}>UserName</th>
                                            <th style={{ width: '100px' }}>Number</th>
                                            <th style={{ width: '100px' }}>Clikced on</th>
                                            <th style={{ width: '100px' }}>Status</th>
                                            <th style={{ width: '100px' }}>Earnings</th>
                                        </tr>
                                        {data?.map((val, key) => {
                                            return (<tr>
                                                <td>{(rowPerPage * currentPage) + (key + 1)}</td>
                                                <td onClick={() => navigate(`/users/transaction/${val?.userId?._id}`)}>{val?.userId?.name}</td>
                                                <td>{val.userId?.phoneNumber}</td>
                                                <td>{moment.utc(val?.clicked_on).local().format("MMM Do YY, h:mm:ss a")}</td>
                                                <td>{val?.status === 1 || val?.status === 0 ? "InProcess" : "Completed"}</td>
                                                <td>{val?.payOut_received ? val?.payOut_received : 0}</td>
                                            </tr>)
                                        })
                                        }
                                    </table>
                                    <div className='userTablePagination'>
                                        <label>Rows per page:</label>
                                        <select value={rowPerPage} onChange={(e) => { setRowPerPage(e.target.value); setCurrentPage(0) }}>
                                            <option value={10}>10</option>
                                            <option value={50}>50</option>
                                            <option value={100}>100</option>
                                            <option value={500}>500</option>
                                            <option value={1000}>1000</option>
                                            <option value={1500}>1500</option>
                                        </select>
                                        <span className='currentPagespan'> Current Page {currentPage + 1}/{Math.ceil(totalPage / rowPerPage)} </span>
                                        <button className='paginationButton' onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 0}>
                                            Previous
                                        </button>
                                        <button className='paginationButton'
                                            onClick={() => setCurrentPage(currentPage + 1)}
                                            disabled={currentPage + 1 >= totalPage / rowPerPage}
                                        >
                                            Next
                                        </button>
                                    </div>
                                </div>
                            </> :
                                <>
                                    <div className='loaderImage'>
                                        <img src={loader} alt='loader' />
                                    </div>
                                </>
                            }
                        </div>
                    </>


                </div>
            </div>


            <Dialog
                open={open}
                onClose={() => handleDelete()}
                TransitionComponent={Transition}
                className="dialogs"
            >
                <div className='categoryEditBox'>
                    {
                        offer?.countries?.map((item, index) => (
                            <React.Fragment key={item._id}>
                                {item.country}
                                {index !== offer?.countries?.length - 1 && ', '}
                            </React.Fragment>))
                    }

                </div>
            </Dialog>

        </>
    )
}

export default SingleOffer