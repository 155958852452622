import React, { useState, useEffect } from 'react'
import SideBar from './SideBar';
import axiosClient from '../axios';
import Pagination from './Pagination';
import moment from 'moment';
import { useParams } from 'react-router-dom';



const UserLogs = () => {
    const { id } = useParams();

    const [logsData, setLogsData] = useState([])
    console.log("logsData", logsData)
    const [pagination, setPagination] = useState({
        page: 0,
        limit: 100
    });
    const [totalPage, setTotalPage] = useState(10)

    const getLogsData = () => {
        axiosClient.get(`/userLogs?page=${pagination?.page}&limit=${pagination?.limit}&id=${id}`)
            .then((res) => {
                setLogsData(res?.data?.response)
                setTotalPage(res?.data?.totalLogs)
            })
            .catch((error) => {
                console.log("error", error)
            })
    }

    const getScreenName = (e) => {
        switch (true) {
            case e == 1:
                return "Earn" // 1 - earn screen
            case e == 2:
                return "Games" // 2 - games screen
            case e == 3:
                return "vocher store" // 3 - vocher store screen
            case e == 4:
                return "earning leaderboard" // 4 - earning leaderboard 
            case e == 5:
                return "referal leaderboard" // 5 - referal leaderboard 
            case e == 6:
                return "earning winner leaderboard " // 6 - earning winner leaderboade screen 
            case e == 7:
                return "referal winner leaderboard" // 7 - referal winner leaderboade screen 
            case e == 8:
                return "user profile " // 8 - user profile screen 
            case e == 9:
                return "my stats"  //  9 - my stats screen
            case e == 10:
                return "my vouchers" // 10 - my vouchers screen
            case e == 11:
                return "my transaction"  // 11 - my transaction
            case e == 12:
                return "spinwheel"  // 12 - spinwheel screen
            case e == 13:
                return "scratch card"  // 13 - scratch card screen
            case e == 14:
                return "Shorts screen"// 14 Shorts screen
            case e == 15:
                return "offer screen"  // 15 - offer screen
            case e == 16:
                return "offer details"  // 16 - offer details screen
            case e == 17:
                return "survey"  // 17 - survey screen
            case e == 18:
                return "PTC"   // 18 PTC screen
            // case e == 12:
            //     return "spinwheel"   // 1 - spinwheel activity    
            // case e == 13:
            //     return "scratch card select activity"    // 2 - scratch card select activity
            // case e == 13:
            //     return "scratch card used activity "    // 2 - scratch card used activity 
            // case e == 16:
            //     return "offer clicked"     //offer clicked
            case e == 0:
                return "offer partners"    // offer partners
            case e == 19:
                return "in progress"   // 19 - in progress screen
            // case e == 1:
            //     return "quizwall single activity"    // 4 - quizwall single activity
            case e == 20:
                return "brainvalley"   // 20 brainvalley screen
            case e == 21:
                return "affiliate"  // 21 affiliate screen
            case e == 22:
                return "my network"  //22  my network
            case e == 23:
                return "Voucher Purchase"  //23 Voucher info
            // case e == 23:
            //     return "Voucher info"  //23 Voucher info
            // case e == 23:
            //     return "Voucher info "  //23 Voucher info 
            case e == 24:
                return "Register"  //23 Register screen
            case e == 25:
                return "Register Finish" //23 Register Finish screen
            case e == 26:
                return "game completed" //23 game completed screen
            case e == 27:
                return "gamezop details" //23 gamexop details screen
            default:
                return "earn"
        }
    }

    const getActivityName = (e) => {
        console.log("e===>", e)
        switch (true) {
            case e == 0:
                return "Null"
            case e == 1:
                return "SpinWeel Used"
            case e == 2:
                return "Scratch Card Selected"
            case e == 3:
                return "Scratch Card Used"
            case e == 4:
                return "Offer Started"
            case e == 5:
                return "Partner Clicked"
            case e == 6:
                return "single Quiz wall activity"
            case e == 7:
                return "Daily Reward Claimed"
            default:
                return "Null"
        }
    }

    useEffect(() => {
        getLogsData()
    }, [pagination?.page, pagination?.limit])


    return (
        <>
            <SideBar handleSideBar={true} activeTab={"users"} />
            <div className="screen">
                <h2>{`User Logs ${totalPage}`} </h2>

                <div className='profileInfoBox table'>
                    <>
                        {logsData?.length == 0 ? <p> No Data Found</p> :
                            <div style={{ width: '100%' }} className="container app-body">
                                <div className='tabel-scrol'>
                                    <table id="users">
                                        <thead>
                                            <tr>
                                                <th>S.NO.</th>
                                                <th>Method</th>
                                                <th>Screen</th>
                                                <th>Activity</th>
                                                <th>Created At</th>
                                                <th>Url</th>
                                            </tr>
                                        </thead>
                                        {logsData?.map((val, key) => {
                                            return (
                                                <tbody key={key}>
                                                    <tr>
                                                        <td>{(pagination?.page * pagination?.limit) + key + 1}</td>
                                                        <td>{val?.method}</td>
                                                        <td>{getScreenName(val.screen)}</td>
                                                        <td>{getActivityName(val.activity)}</td>
                                                        <td>{moment.utc(val?.createdAt).local().format("DD/MM/YY, h:mm:ss a")}</td>
                                                        {/* <td className='scrollable'>{val?.url.length < 100 ? val?.url : `${val?.url.slice(0,100)}... `}</td> */}
                                                        <td className='userLogsscrollable'>{val?.url}</td>
                                                    </tr>
                                                </tbody>)
                                        })
                                        }
                                    </table>
                                </div>
                            </div>

                        }

                    </>
                </div>
                <div>
                    <Pagination
                        filterDataInput={pagination}
                        setFilterDataInput={setPagination}
                        totalPage={totalPage}
                    />
                </div>

            </div>


        </>
    )
}

export default UserLogs
