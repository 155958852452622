import React, { useState } from 'react'
import SideBar from './SideBar'
import axiosClient from '../axios';
import { ReactComponent as Add } from "../assets/images/add.svg"
import { ReactComponent as Delete } from "../assets/images/delete.svg"
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { ReactComponent as Edit } from "../assets/images/edit.svg";
import { useNavigate } from 'react-router-dom';
import loader from '../assets/images/loader.gif'
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const MultiPageData = () => {
    const [open, setOpen] = useState(false)
    const [data, setData] = useState({})
    const [pagedata, setPageData] = useState({})
    const [rulesKey, setRuleKey] = useState([])
    const [ruleData, setRuleData] = useState({
        heading: "",
        text: ""
    })
    const [loading, setLoading] = useState(false)

    console.log("data", data)

    const navigate = useNavigate()
    const [editable, setEditable] = useState(false);
    const [index, setIndex] = useState(0)

    const options = ["page0", "pagre10", "pagre20", "pagre30", "pagre40", "pagre50", "pagre60"];


    const handleClose = () => {
        setOpen(false)
    }

    const handlePageDataAdd = () => {
        if (!ruleData?.heading || !ruleData?.text) {
            alert("Both Field Mandatory")
            return
        }
        setRuleKey([...rulesKey, ruleData])
        setPageData({ ...pagedata, rulesKey: [...rulesKey, ruleData] })
        setData({ ...data, pagedata: { ...pagedata, rulesKey: [...rulesKey, ruleData] } })
        setRuleData({
            heading: "",
            text: ""
        })
        setOpen(false)
    }

    const handleEditPageData = () => {
        if (!ruleData?.heading || !ruleData?.text) {
            alert("Both Field Mandatory")
            return
        }
        let updataRule = [...rulesKey]
        updataRule[index] = ruleData
        setRuleKey(updataRule)
        setPageData({ ...pagedata, rulesKey: updataRule })
        setData({ ...data, pagedata: { ...pagedata, rulesKey: updataRule } })
        setRuleData({
            heading: "",
            text: ""
        })
        setOpen(false)
    }

    const handleDelete = (i) => {
        let deleteRule = [...rulesKey]
        deleteRule.splice(i, 1)
        setRuleKey(deleteRule)
        setPageData({ ...pagedata, rulesKey: deleteRule })
        setData({ ...data, pagedata: { ...pagedata, rulesKey: deleteRule } })
    }

    const handleAddPageData = () => {
        if (!data?.pagename || rulesKey?.length == 0) {
            alert('Please Enter All Field Data')
            return
        }
        setLoading(true)
        axiosClient.post('/addMultiPageData', data)
            .then((res) => {
                console.log("res from multi page data", res)
                if (res?.data.status) {
                    setData({})
                    setPageData({})
                    setRuleKey([])
                    alert(res?.data?.message)
                    navigate("/allmultiPageData")
                }else{
                    alert(res?.data.message)
                    
                }
                
            })
            .catch((err) => console.log(err))
            .finally(() => setLoading(false))
    }

    return (
        <>
            <SideBar handleSideBar={true} activeTab={"miltipageData"} />
            {
                loading ?
                    <>
                        <div className='loaderImage'>
                            <img src={loader} alt='loader' />
                        </div>
                    </>

                    : <div className="screen">
                        <h3>Multi Page Data</h3>
                        <div className="multiPageData-container">
                            <div className="challenge-field">
                                <span className='challenge-heading'>Page Name</span>
                                {/* <input
                                    className='challenge-input'
                                    type="text"
                                    name='name'
                                    onChange={(e) => {
                                        setData({ ...data, pagename: e.target.value })
                                    }}
                                    placeholder='Enter Page Name'
                                    value={data.pagename}
                                /> */}

                                <select
                                    id="numberSelect"
                                    className='multi-page-data-selectbox'
                                    value={data.pagename}
                                    // disabled={offers.winningCoin ? true : false}
                                    onChange={(e) => {
                                        setData({ ...data, pagename: e.target.value })
                                    }}
                                >
                                    {options.map((option) => (
                                        <option key={option} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </select>


                            </div>

                            <div className="multi-page-field">
                                <p className='multiPage-table-heading'>Add Multi Page Data<Add className='addReward' onClick={() => { setOpen(true) }} /></p>
                                {(rulesKey.length > 0) && <div className='multi-page-preview'>
                                    <table className='multi-page-table'>
                                        <thead>
                                            <th>Heading</th>
                                            <th>Text</th>
                                            <th>Edit</th>
                                            <th>Delete</th>
                                        </thead>
                                        <tbody>

                                            {rulesKey.map((item, i) => {
                                                return (
                                                    <tr key={i} >
                                                        <td>
                                                            {item.heading}
                                                        </td>
                                                        <td>
                                                            {item.text}
                                                        </td>
                                                        <td><Edit
                                                            style={{ cursor: 'pointer' }}
                                                            onClick={() => {
                                                                setOpen(true)
                                                                setRuleData(item)
                                                                setIndex(i)
                                                                setEditable(true)
                                                            }}
                                                        /></td>
                                                        <td><Delete
                                                            style={{ cursor: 'pointer' }}
                                                            onClick={() => {
                                                                handleDelete(i)
                                                            }} /></td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>


                                </div>}
                            </div>

                        </div>


                        <button
                            className='challenge-button'
                            onClick={() => { handleAddPageData() }}
                        >
                            Submit
                        </button>

                    </div>}

            <Dialog
                open={open}
                onClose={() => handleClose()}
                TransitionComponent={Transition}
                className="dialogs"
            >
                <div className='categoryEditBox multi-page-data'>
                    <div className='editCover fullCover offer-challenge'>
                        <p>
                            Heading
                        </p>
                        <input
                            name="page-heading"
                            placeholder={'Enter Heading'}
                            onChange={(e) => {
                                setRuleData({ ...ruleData, heading: e.target.value })
                            }}
                            type="text"
                            value={ruleData.heading}
                            className="multipage-input"
                        />
                    </div>
                    <div className='editCover fullCover offer-challenge'>
                        <p>
                            Text
                        </p>
                        <input
                            name="pagenamee"
                            placeholder={'Enter  text'}
                            onChange={(e) => {
                                setRuleData({ ...ruleData, text: e.target.value })
                            }}
                            type="text"
                            value={ruleData.text}
                            className="multipage-input"
                        />
                    </div>

                    <div className='addButton' onClick={() => { editable ? handleEditPageData() : handlePageDataAdd() }} >{editable ? "Edit" : "Save"}</div>
                </div>
            </Dialog>
        </>
    )
}

export default MultiPageData
