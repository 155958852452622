import React, { useEffect, useState } from 'react'
import SideBar from './SideBar';
import { ReactComponent as Apple } from "../assets/images/apple.svg"
import { ReactComponent as Desktop } from "../assets/images/desktop.svg"
import { ReactComponent as Android } from "../assets/images/android.svg"
import ColorPickar from '../helperComponent/ColorPickar';
import AlertDialog from '../helperComponent/AlertDialog';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { ReactComponent as Add } from "../assets/images/add.svg"
import JoditEditor from "jodit-react";
import axiosClient from '../axios';
import { ReactComponent as Picadd } from "../assets/images/newAddPic.svg";
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { Delete } from '@mui/icons-material';
import { ReactComponent as Edit } from "../assets/images/edit.svg";
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


function AddPartner() {
    const options = [
        { value: 1, label: 'Offer', image: <Android /> },
        { value: 2, label: 'Trending', image: <Desktop /> },
        { value: 3, label: 'Games', image: <Apple /> }
    ];

    const [newPartner, setNewPartners] = useState({
        name: "",
        trackLink: "",
        is_active: true,
        image: "",
        guaranteedImage: "",
        borderColor: "",
        is_static: false,
        is_popular: false,
        is_bonus: false,
        is_recommended: false,
        is_guaranteed: false,
        removal_limit: 0,
        removal_limit_gap: 48,
        recommended_screen: [1, 2],
        description: "",
        on_mobile: true,
        partner_type: [1],
        ratings: 5,
        showDetails: false,
        details: [],
        video: {
            videoId: "",
            link: ""
        },
        open_value: 0,
        // position:0
        amount_limit: 0,
        daily_limit: 0,
        limit: 100,
    });

    console.log("newPartner.partner_type", newPartner?.partner_type)

    const [open, setOpen] = useState(false)
    const [index, setIndex] = useState(0)
    const [singleDetails, setSingleDetails] = useState({
        "heading": "",
        "data": ""
    });
    const [editable, setEditable] = useState(false)
    const [selectedOptions, setSelectedOptions] = useState([1, 2]);

    const [colors, setColors] = useState({
        color1: "#2b579a",
        color2: "#2b579a"
    })

    const [alertDialogOpen, setAlertDialogOpen] = useState(false)
    const [dialogMessage, setDialogMessage] = useState('');
    const [showAlertSign, setShowAlertSigh] = useState(false)

    const handleSelectChange = (event) => {
        setSelectedOptions(event.target.value);
        setNewPartners({ ...newPartner, recommended_screen: event.target.value })
    };


    const handleBorderColor = (event) => {
        setNewPartners({ ...newPartner, borderColor: event.target.value })
    };

    const handlefileInput = async (e, type) => {
        const photo = new FormData();
        const selectedFile = e.target.files[0];
        const fileSizeInMb = selectedFile.size / (1024 * 1024);
        if (fileSizeInMb > 7) {
            alert('File size is too large. Please choose a file smaller than 7 MB.');
        } else {
            photo.append("photo", selectedFile);
            await axiosClient
                .post(`/uploadImage`, photo)
                .then((res) => {
                    if (type == 1) {
                        setNewPartners({ ...newPartner, image: res.data.response.url })
                    }
                    else if (type == 2) {
                        // setNewPartners({ ...newPartner, recommendedImage: res.data.response.url })
                        setNewPartners({ ...newPartner, guaranteedImage: res.data.response.url })
                    }
                })
                .catch((err) => console.log(err));
        }
    };


    const handleClose = () => {
        setOpen(false)
        setSingleDetails({
            "heading": "",
            "data": ""
        })
    }

    function addPartner() {
        const formData = {
            name: newPartner.name,
            trackLink: newPartner.trackLink,
            is_active: newPartner.is_active,
            image: newPartner.image,
            // recommendedImage: newPartner.recommendedImage,
            guaranteedImage: newPartner.guaranteedImage,
            borderColor: newPartner.borderColor,
            is_static: newPartner.is_static,
            is_popular: newPartner.is_popular,
            on_mobile: newPartner.on_mobile,
            is_recommended: newPartner.is_recommended,
            is_guaranteed: newPartner.is_guaranteed,
            is_bonus: newPartner.is_bonus,
            recommended_screen: newPartner.recommended_screen,
            partner_type: newPartner.partner_type,
            ratings: newPartner.ratings,
            description: newPartner.description,
            details: newPartner.details,
            showDetails: newPartner.showDetails,
            video: newPartner.video,
            open_value: newPartner.open_value,
            amount_limit: newPartner.amount_limit,
            daily_limit: newPartner.open_value,
            limit: newPartner.limit,
            removal_limit: newPartner.removal_limit,
            removal_limit_gap: newPartner.removal_limit_gap,
        }

        formData.bg_color = [colors?.color1, colors?.color2]

        if (formData.name === "" || formData.trackLink === "" || formData.image === "" || formData.partner_type === "") {
            // alert("Required Field Missing");
            setDialogMessage("Required Field Missing");
            setAlertDialogOpen(true);
            return
        }

        if (!formData.removal_limit_gap) {
            // alert("Please Enter Removal Limit Gap");
            setDialogMessage("Please Enter Removal Limit Gap");
            setAlertDialogOpen(true);
            return
        }

        if (formData.ratings > 5) {
            // alert("Ratings Can Not Be Given More Than 5");
            setDialogMessage("Ratings Can Not Be Given More Than 5");
            setAlertDialogOpen(true);
            return
        }

        if (Number(formData?.amount_limit) < 0) {
            // alert("Amount Limit is always grater then 0")
            setDialogMessage("Amount Limit is always grater then 0");
            setAlertDialogOpen(true);
            return
        }
        if (Number(formData?.daily_limit) < 0) {
            // alert("daily Limit is always grater then 0")
            setDialogMessage("daily Limit is always grater then 0");
            setAlertDialogOpen(true);
            return
        }
        if (Number(formData?.limit) > 100) {
            // alert("Confirm Limit Can not be grater then 100")
            setDialogMessage("Confirm Limit Can not be grater then 100");
            setAlertDialogOpen(true);
            return
        }

        if (formData.showDetails) {
            if (formData.details.length == 0) {
                // alert("Please Enter Heading And Data In Detail")
                setDialogMessage('Please Enter Heading And Data In Detail');
                setAlertDialogOpen(true);
                return
            }
        }

        axiosClient.post("/admin/newPartner", formData)
            .then((res) => {
                console.log("res===>", res)
                if (res) {
                    // alert(res.data.message)
                    setShowAlertSigh(true)
                    setDialogMessage(res.data.message);
                    setAlertDialogOpen(true);
                }

                setNewPartners({
                    name: "",
                    trackLink: "",
                    is_active: true,
                    image: "",
                    is_static: false,
                    is_popular: false,
                    on_mobile: true,
                    partner_type: [1],
                    ratings: 5,
                    description: "",
                    showDetails: false,
                    details: [],
                    video: {
                        videoId: "",
                        link: ""
                    },
                    open_value: 0,
                    amount_limit: 0,
                    daily_limit: 0,
                    limit: 100,
                    removal_limit: 0,
                    removal_limit_gap: 48,
                    bgColor: []
                });
            })
    }




    const handleDetails = () => {
        if (!singleDetails?.heading || !singleDetails?.data) {
            alert("Please Enter Both Field value")
            return
        }
        newPartner.details.push(singleDetails)
        setOpen(false)
        setSingleDetails({
            "heading": "",
            "data": ""
        })

    }

    const handleEditDetails = () => {
        if (!singleDetails?.heading || !singleDetails?.data) {
            alert("Please Enter Both Field value")
            return
        }
        const updatedDetails = [...newPartner.details]
        updatedDetails[index] = singleDetails
        setNewPartners({
            ...newPartner,
            details: updatedDetails
        });
        setOpen(false)
        setSingleDetails({
            "heading": "",
            "data": ""
        })
        setEditable(false)
    }


    const handleDeleteDetails = (i) => {
        const partneredDetails = [...newPartner.details]
        partneredDetails.splice(i, 1);
        setNewPartners({ ...newPartner, details: partneredDetails })
    }


    const handleCheckboxChange = (value) => {
        if (newPartner.partner_type.includes(value)) {
            setNewPartners({
                ...newPartner,
                partner_type: newPartner.partner_type.filter(val => val !== value)
            });
        } else {
            setNewPartners({
                ...newPartner,
                partner_type: [...newPartner.partner_type, value]
            });
        }
    };

    const renderCheckbox = (value, label) => (
        <React.Fragment key={value}>
            <input
                type="checkbox"
                checked={newPartner?.partner_type?.includes(value)}
                className="circle"
                onChange={() => handleCheckboxChange(value)}
                value={value}
            />
            <label>{label}</label>
        </React.Fragment>
    );

    return (
        <>
            <SideBar handleSideBar={true} activeTab={"partners"} />
            <div className="screen">
                <div className='dFlex adminPageButton'>
                    <h2 className='partnerHeading'>Add Partner</h2>
                </div>

                <div className='labelandInput'>
                    <div className='labelClass'>
                        <label>Name </label>
                        <input
                            type='text'
                            name="name"
                            onChange={(e) => {
                                setNewPartners({ ...newPartner, name: e.target.value })
                            }}
                            value={newPartner.name}
                            placeholder={' Enter Name'}
                            className="inputfield"
                        />
                    </div>
                    <div className='labelClass'>
                        <label>
                            Track Link
                        </label>
                        <input
                            type='text'
                            name="trackLink"
                            onChange={(e) => {
                                setNewPartners({ ...newPartner, trackLink: e.target.value })
                            }}
                            value={newPartner.trackLink}
                            placeholder={'Enter Tracklink'}
                            className="inputfield"
                        />
                    </div>

                    <div className='labelClass'>
                        <label>
                            Description
                        </label>
                        <input
                            type='text'
                            name="description"
                            onChange={(e) => {
                                setNewPartners({ ...newPartner, description: e.target.value })
                            }}
                            value={newPartner.description}
                            placeholder={'Enter description'}
                            className="inputfield"
                        />
                    </div>

                    <div className='labelClass'>
                        <label>
                            Rating
                        </label>
                        <input
                            type='text'
                            name="ratings"
                            onChange={(e) => {
                                setNewPartners({ ...newPartner, ratings: e.target.value })
                            }}
                            value={newPartner.ratings}
                            placeholder={'Enter Rating'}
                            className="inputfield"
                        />
                    </div>
                    <div className='labelClass'>
                        <label>
                            Open Value
                        </label>
                        <input
                            type='number'
                            name="openvalue"
                            onChange={(e) => {
                                setNewPartners({ ...newPartner, open_value: Number(e.target.value) })
                            }}
                            value={newPartner?.open_value}
                            placeholder={'Enter Unlock Value'}
                            className="inputfield"
                        />
                    </div>

                    <div className='labelClass'>
                        <label>
                            Daily Amount Limit
                        </label>
                        <input
                            type='number'
                            name="dailyamount"
                            onChange={(e) => {
                                setNewPartners({ ...newPartner, amount_limit: Number(e.target.value) })
                            }}
                            value={newPartner?.amount_limit}
                            placeholder={'Enter Amount Limint'}
                            className="inputfield"
                        />
                    </div>

                    <div className='labelClass'>
                        <label>
                            Daily Transaction Limit
                        </label>
                        <input
                            type='number'
                            name="dailyTransaction"
                            onChange={(e) => {
                                setNewPartners({ ...newPartner, daily_limit: Number(e.target.value) })
                            }}
                            value={newPartner?.daily_limit}
                            placeholder={'Enter Transaction Limint'}
                            className="inputfield"
                        />
                    </div>

                    <div className='labelClass'>
                        <label>
                            Removal Limit
                        </label>
                        <input
                            type='number'
                            name="removal_limit"
                            onChange={(e) => {
                                setNewPartners({ ...newPartner, removal_limit: Number(e.target.value) })
                            }}
                            value={newPartner?.removal_limit}
                            placeholder={'Enter Transaction Limint'}
                            className="inputfield"
                        />
                    </div>

                    <div className='labelClass'>
                        <label>
                            Removal Limit Gap   <span className='in-minutes'>(in hour)</span>
                        </label>
                        <input
                            type='number'
                            name="removal_limit_gap"
                            onChange={(e) => {
                                setNewPartners({ ...newPartner, removal_limit_gap: Number(e.target.value) })
                            }}
                            value={newPartner?.removal_limit_gap}
                            placeholder={'Enter Transaction Limint'}
                            className="inputfield"
                        />
                    </div>

                    <div className='labelClass'>
                        <label>
                            Confirm Limit
                        </label>
                        <input
                            type='number'
                            name="confirmlimit"
                            onChange={(e) => {
                                setNewPartners({ ...newPartner, limit: Number(e.target.value) })
                            }}
                            value={newPartner?.limit}
                            placeholder={'Confirm Limit'}
                            className="inputfield"
                        />
                    </div>

                    <div className='labelClass'>
                        <label htmlFor="">Active </label>
                        <input
                            type="radio"
                            checked={newPartner.is_active == true ? true : false}
                            onChange={(e) => {
                                setNewPartners({ ...newPartner, is_active: e.target.value == "true" ? true : false })
                            }}
                            className="circle"
                            value={true}
                        />
                        <span className='inline'>True</span>
                        <input
                            type="radio"
                            checked={newPartner.is_active == false ? true : false}
                            onChange={(e) => {
                                setNewPartners({ ...newPartner, is_active: e.target.value == "false" ? false : true })
                            }}
                            className="circle"
                            value={false}
                        />
                        <span className='inline'>False</span>
                    </div>


                    <div className='labelClass'>
                        <label htmlFor="">Popular </label>
                        <input
                            type="radio"
                            checked={newPartner.is_popular == true ? true : false}
                            onChange={(e) => {
                                setNewPartners({ ...newPartner, is_popular: e.target.value == "true" ? true : false })
                            }}
                            className="circle"
                            value={true}
                        />
                        <span className='inline'>True</span>
                        <input
                            type="radio"
                            checked={newPartner.is_popular === false ? true : false}
                            onChange={(e) => {

                                setNewPartners({ ...newPartner, is_popular: e.target.value == "false" ? false : true })
                            }}
                            className="circle"
                            value={false}
                        />
                        <span className='inline'>False</span>
                    </div>

                    <div className='labelClass'>
                        <label>
                            Single offer
                        </label>
                        <input
                            type="radio"
                            checked={newPartner.is_static == true ? true : false}
                            onChange={(e) => {
                                setNewPartners({ ...newPartner, is_static: e.target.value == "true" ? true : false })
                            }}
                            className="circle"
                            value={true}
                        />
                        <span className='inline'>True </span>
                        <input
                            type="radio"
                            checked={newPartner.is_static == false ? true : false}
                            onChange={(e) => {
                                setNewPartners({ ...newPartner, is_static: e.target.value == "false" ? false : true })
                            }}
                            className="circle"
                            value={false}
                        />
                        <span className='inline'>False</span>
                    </div>


                    <div className='labelClass'>
                        <label htmlFor="">On Mobile</label>
                        <input
                            type="radio"
                            checked={newPartner.on_mobile == true ? true : false}
                            onChange={(e) => {

                                setNewPartners({ ...newPartner, on_mobile: e.target.value == "true" ? true : false })
                            }}
                            className="circle"
                            value={true}
                        />
                        <span className='inline'>True</span>
                        <input
                            type="radio"
                            checked={newPartner.on_mobile == false ? true : false}
                            onChange={(e) => {

                                setNewPartners({ ...newPartner, on_mobile: e.target.value == "false" ? false : true })
                            }}
                            className="circle"
                            value={false}
                        />
                        <span className='inline'>False</span>
                    </div>

                    <div className='labelClass'>
                        <label htmlFor="">Is Recommended</label>
                        <input
                            type="radio"
                            checked={newPartner.is_recommended == true ? true : false}
                            onChange={(e) => {
                                setNewPartners({ ...newPartner, is_recommended: e.target.value == "true" ? true : false })
                            }}
                            className="circle"
                            value={true}
                        />
                        <span className='inline'>True</span>
                        <input
                            type="radio"
                            checked={newPartner.is_recommended == false ? true : false}
                            onChange={(e) => {

                                setNewPartners({ ...newPartner, is_recommended: e.target.value == "false" ? false : true })
                            }}
                            className="circle"
                            value={false}
                        />
                        <span className='inline'>False</span>
                    </div>


                    <div className='labelClass'>
                        <label htmlFor="">Is Guaranteed</label>
                        <input
                            type="radio"
                            checked={newPartner.is_guaranteed == true ? true : false}
                            onChange={(e) => {
                                setNewPartners({ ...newPartner, is_guaranteed: e.target.value == "true" ? true : false })
                            }}
                            className="circle"
                            value={true}
                        />
                        <span className='inline'>True</span>
                        <input
                            type="radio"
                            checked={newPartner.is_guaranteed == false ? true : false}
                            onChange={(e) => {

                                setNewPartners({ ...newPartner, is_guaranteed: e.target.value == "false" ? false : true })
                            }}
                            className="circle"
                            value={false}
                        />
                        <span className='inline'>False</span>
                    </div>
                    <div className='labelClass'>
                        <label htmlFor="">Is Bonus</label>
                        <input
                            type="radio"
                            checked={newPartner.is_bonus == true ? true : false}
                            onChange={(e) => {
                                setNewPartners({ ...newPartner, is_bonus: e.target.value == "true" ? true : false })
                            }}
                            className="circle"
                            value={true}
                        />
                        <span className='inline'>True</span>
                        <input
                            type="radio"
                            checked={newPartner.is_bonus == false ? true : false}
                            onChange={(e) => {

                                setNewPartners({ ...newPartner, is_bonus: e.target.value == "false" ? false : true })
                            }}
                            className="circle"
                            value={false}
                        />
                        <span className='inline'>False</span>
                    </div>




                    <div className='offerDropown'>
                        <label > Recommended Screen</label>
                        <FormControl>
                            <Select
                                className='selectBox'
                                multiple
                                value={selectedOptions}
                                onChange={handleSelectChange}
                                renderValue={(selected) => (
                                    <div>
                                        {selected.map((value) => {

                                            const option = options.find((option) => option.value === value);
                                            return (
                                                <React.Fragment key={value}>
                                                    <span className='optionImage'>
                                                        {option.image}
                                                    </span>
                                                </React.Fragment>
                                            );
                                        })}
                                    </div>
                                )}
                            >
                                {options.map((option) => (
                                    <MenuItem key={option.value} value={option.value} className="svgApple">
                                        {option.image}
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>



                    <div className='labelClass'>
                        <label>Partner Type</label>
                        {renderCheckbox(1, "offerwall")}
                        {renderCheckbox(2, "Surveywall")}
                        {renderCheckbox(3, "Games")}
                        {renderCheckbox(4, "PTC Walls")}
                    </div>





                    {/* <div className='labelClass'>
                        <label>
                            Partner Type
                        </label>
                        <input
                            type="radio"
                            checked={newPartner.partner_type == 1 ? true : false}
                            onChange={(e) => {

                                setNewPartners({ ...newPartner, partner_type: e.target.value == "1" ? 1 : 1 })
                            }}
                            className="circle"
                            value={1}
                        />
                        <span className='inline'>OfferWall </span>
                        <input
                            type="radio"
                            checked={newPartner.partner_type == 2 ? true : false}
                            onChange={(e) => {

                                setNewPartners({ ...newPartner, partner_type: e.target.value == "2" ? 2 : 1 })
                            }}
                            className="circle"
                            value={2}
                        />
                        <span className='inline'>SurveyWall</span>
                        <input
                            type="radio"
                            checked={newPartner.partner_type == 3 ? true : false}
                            onChange={(e) => {
                                setNewPartners({ ...newPartner, partner_type: e.target.value == "3" ? 3 : 1 })
                            }}
                            className="circle"
                            value={3}
                        />
                        <span className='inline'>Games</span>
                        <input
                            type="radio"
                            checked={newPartner.partner_type == 4 ? true : false}
                            onChange={(e) => {
                                setNewPartners({ ...newPartner, partner_type: e.target.value == "4" ? 4 : 1 })
                            }}
                            className="circle"
                            value={4}
                        />
                        <span className='inline'>PTC Walls</span>



                    </div> */}





                    <div className='labelClass border-color'>
                        <label>
                            Border Color
                        </label>

                        <div className='picker-section-color-single'>
                            <label htmlFor="colorPicker2">Choose  color:</label>
                            <input
                                type="color"
                                id="colorPicker2"
                                name="colorPicker2"
                                value={newPartner?.borderColor}
                                onChange={handleBorderColor}
                            />
                        </div>

                        <div style={{ background: "red", background: `${newPartner?.borderColor}` }} className='linear_gradient-single'>
                        </div>

                    </div>


                    <ColorPickar
                        colors={colors}
                        setColors={setColors}
                        heading={"Recommended Color"}
                    />



                    <div className='labelClass'>
                        <label>
                            Guaranteed Image
                        </label>
                        <label htmlFor="inputTagField-recommedded">
                            <Picadd className="addImgae" />
                            <input
                                type="file"
                                id="inputTagField-recommedded"
                                name="photo"
                                onChange={(e) => handlefileInput(e, 2)}
                                multiple
                                className="filetag"
                            />
                        </label>
                        {newPartner.guaranteedImage !== "" ?
                            <>
                                <div >
                                    <img src={newPartner.guaranteedImage} className='addpartnerImage' alt="Partners Icon" />
                                </div>
                            </> : null}
                    </div>


                    <div className='labelClass'>
                        <label>
                            add Image
                        </label>
                        <label htmlFor="inputTagField">
                            <Picadd className="addImgae" />
                            <input
                                type="file"
                                id="inputTagField"
                                name="photo"
                                onChange={(e) => handlefileInput(e, 1)}
                                multiple
                                className="filetag"
                            />
                        </label>
                        {newPartner.image !== "" ?
                            <>
                                <div >
                                    <img src={newPartner.image} className='addpartnerImage' alt="Partners Icon" />
                                </div>
                            </> : null}
                    </div>


                    <div className='labelClass'>
                        <label htmlFor="">Show Details</label>
                        <input
                            type="radio"
                            checked={newPartner.showDetails == true ? true : false}
                            onChange={(e) => {
                                setNewPartners({ ...newPartner, showDetails: e.target.value == "true" ? true : false })
                            }}
                            className="circle"
                            value={true}
                        />
                        <span className='inline'>True</span>
                        <input
                            type="radio"
                            checked={newPartner.showDetails == false ? true : false}
                            onChange={(e) => {
                                setNewPartners({ ...newPartner, showDetails: e.target.value == "false" ? false : true })
                            }}
                            className="circle"
                            value={false}
                        />
                        <span className='inline'>False</span>
                    </div>

                    {newPartner.showDetails && <div className='labelClass'>

                        <p className='rewards'>Details <Add className='addReward' onClick={() => setOpen(true)} /></p>
                        <div className='flexRow'>
                            <h3>Heading</h3>
                            <h3>Data</h3>
                            <h3>Edit</h3>
                            <h3>Delete</h3>
                        </div>
                        {
                            newPartner?.details?.map((e, i) => {
                                return (<React.Fragment key={i}>
                                    <div className='flexRow'>
                                        <h3>{e?.heading}</h3>
                                        <h3>{e?.data}</h3>
                                        <h3><Edit onClick={() => { setOpen(true); setIndex(i); setSingleDetails(e); setEditable(true) }} /></h3>
                                        <Delete onClick={() => { handleDeleteDetails(i) }} />
                                    </div>
                                </React.Fragment>)
                            })
                        }
                    </div>}


                    {newPartner.showDetails && <>
                        <div className='labelClass'>
                            <label>video Id </label>
                            <input
                                type='text'
                                name="videoId"
                                onChange={(e) => {
                                    setNewPartners({ ...newPartner, video: { ...newPartner.video, videoId: e.target.value } })

                                }}
                                value={newPartner.video.videoId}
                                placeholder={' Enter video Id'}
                                className="inputfield"
                            />
                        </div>

                        <div className='labelClass'>
                            <label>video Link </label>
                            <input
                                type='text'
                                name="videoLink"
                                onChange={(e) => {
                                    setNewPartners({ ...newPartner, video: { ...newPartner.video, link: e.target.value } })
                                }}
                                value={newPartner?.video?.link}
                                placeholder={'Enter Video Link'}
                                className="inputfield"
                            />
                        </div>
                    </>}
                    <button className='tSubnitButton partnerSubmit' onClick={() => addPartner()} >Submit</button>

                </div>
                {/* <button className='tSubnitButton partnerSubmit' onClick={() => addPartner()} >Submit</button> */}
            </div>

            <Dialog
                open={open}
                onClose={() => handleClose()}
                TransitionComponent={Transition}
                className="dialogs"
            >
                <div className='categoryEditBox'>
                    <h3 className='addRew'> Add Details</h3>

                    <div className='editCover fullCover'>
                        <p>
                            Heading
                        </p>
                        <input
                            name="level"
                            placeholder={'Enter Heading'}
                            onChange={(e) => {
                                setSingleDetails({ ...singleDetails, heading: e.target.value })
                            }}
                            type="text"
                            value={singleDetails?.heading}
                            className="inputTag"
                        />
                    </div>
                    <div className='editCover editBio'>
                        <p>
                            Data
                        </p>
                        <div className="pRelative">
                            <JoditEditor
                                value={singleDetails?.data}

                                onChange={(newContent) =>
                                    setSingleDetails({ ...singleDetails, data: newContent })
                                }
                            />
                        </div>
                    </div>

                    <div className='addButton' onClick={() => { editable ? handleEditDetails() : handleDetails() }}>{editable ? "Edit" : "Save"}</div>
                </div>
            </Dialog>

            <AlertDialog
                alertDialogOpen={alertDialogOpen}
                setAlertDialogOpen={setAlertDialogOpen}
                msg={dialogMessage}
                showAlertSign={showAlertSign}
                setShowAlertSigh={setShowAlertSigh}
            />


        </>
    )
}

export default AddPartner