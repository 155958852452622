import React, { useEffect, useState } from 'react'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { TextField } from '@mui/material';
import SideBar from './SideBar'
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import axiosClient from '../axios';
import '../assets/css/userTable.css'
import loader from '../assets/images/loader.gif'
import { dateConvert } from '../dateConverter';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function GrossData() {
    const [data, setData] = useState([])
    const [totalData, setTotalData] = useState({})
    const [isLoading, setIsLoading] = useState(true)
    const [open, setOpen] = useState(false)
    const [index, setIndex] = useState(0)

    console.log("Data of gross data", data)
    console.log('totalData', totalData)



    const [date, setDate] = useState({
        start: null,
        end: null,
    })

    const getGrossData = async () => {
        let start, end
        if (date.start && date.end == null) {
            start = dateConvert(date?.start);
        }
        else if (date.start && date.end) {
            start = dateConvert(date?.start);
            end = dateConvert(date?.end);
        }

        setIsLoading(false)
        await axiosClient
            .get(`/admin/grossData?s_date=${start}&e_date=${end}`)
            .then((res) => {
                setData(res.data?.response)
                const total = res.data?.response.reduce((accumulator, entry) => {
                    accumulator.users += entry.users;
                    accumulator.allrevenue += entry.allrevenue;
                    accumulator.transactions += entry.transactions;
                    accumulator.allamountSC += entry.allamountSC;
                    accumulator.cards += entry.cards;
                    accumulator.allamountSW += entry.allamountSW;
                    accumulator.spins += entry.spins;
                    accumulator.allamountWD += entry.allamountWD;
                    accumulator.withdrawals += entry.withdrawals;
                    return accumulator;
                }, {
                    users: 0,
                    allrevenue: 0,
                    transactions: 0,
                    allamountSC: 0,
                    cards: 0,
                    allamountSW: 0,
                    spins: 0,
                    allamountWD: 0,
                    withdrawals: 0
                });
                setTotalData(total);
            })
            .catch((err) => console.log(err))
            .finally(() => setIsLoading(true))
    }

    const handleClose = () => {
        setOpen(false)
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        date.start && date.end && getGrossData()
    }, [])

    return (
        <>
            <SideBar handleSideBar={true} activeTab={"grossData"} />
            <div className="screen">
                <div className='tParentDiv divGrossData'>
                    <h2 className='screenHeading catHead'>Transactions</h2>
                    <div className='grossDataDateFlex'>
                        <div className='graphBox userGrapBox'>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="Start Date"
                                    value={date?.start}
                                    onChange={(value) => { setDate({ ...date, start: value }); }}
                                    renderInput={(params) => <TextField {...params} />}
                                // maxDate={date?.end}
                                />
                            </LocalizationProvider>
                        </div>
                        <div className='graphBox userGrapBox'>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="End Date"
                                    value={date?.end}
                                    onChange={(value) => { setDate({ ...date, end: value }); }}
                                    renderInput={(params) => <TextField {...params} />}
                                // maxDate={date?.end}
                                />
                            </LocalizationProvider>
                        </div>
                        <button className='tSubnitButton' onClick={() => { getGrossData() }}>Submit</button>
                    </div>
                </div>

                <div className='profileInfoBox table'>
                    {isLoading ? <> {

                        data?.length > 0 ?

                            <div style={{ width: '100%' }} className="container app-body">
                                <div className='tabel-scrol'>
                                    <table id="users">
                                        <tr>
                                            <th style={{ width: '100px' }}>Date</th>
                                            <th style={{ width: '100px' }}>All Revenue</th>
                                            <th style={{ width: '120px' }}>Register</th>
                                            <th style={{ width: '120px' }}>SignUP Bonus</th>
                                            <th style={{ width: '120px' }}>Daily Login</th>
                                            <th style={{ width: '120px' }}>Deail Login Amount</th>
                                            <th style={{ width: '120px' }}>Spin Wheel</th>
                                            <th style={{ width: '120px' }}>Spin Wheel Amount</th>
                                            <th style={{ width: '120px' }}>Daily SC</th>
                                            <th style={{ width: '120px' }}>Daily SC Amount</th>
                                            <th style={{ width: '120px' }}>SC Refer</th>
                                            <th style={{ width: '120px' }}>SC Refer Amount</th>
                                            <th style={{ width: '120px' }}>Leader board Prize</th>

                                            <th style={{ width: '120px' }}>Affiliate</th>
                                            <th style={{ width: '120px' }}>Affiliate Count</th>
                                            <th style={{ width: '120px' }}>Profiler</th>
                                            <th style={{ width: '120px' }}>Profiler Count</th>

                                            <th style={{ width: '120px' }}>No. of Widhdraw</th>
                                            <th style={{ width: '120px' }}>Withdraw</th>
                                            <th style={{ width: '150px' }}>Partner Details</th>

                                        </tr>
                                        {
                                            data?.length > 0 && data?.map((val, key) => {
                                                const earningType3 = val?.dailyEarnings?.find(item => item?.earning_type === 3);
                                                const earningType4 = val?.dailyEarnings?.find(item => item?.earning_type === 4);
                                                const earningType1 = val?.dailyEarnings?.find(item => item?.earning_type === 1);
                                                const earningType2 = val?.dailyEarnings?.find(item => item?.earning_type === 2);
                                                const earningType5 = val?.dailyEarnings?.find(item => item?.earning_type === 5);
                                                const earningType6 = val?.dailyEarnings?.find(item => item?.earning_type === 6);
                                                const earningType7 = val?.dailyEarnings?.find(item => item?.earning_type === 7);
                                                const earningType11 = val?.dailyEarnings?.find(item => item?.earning_type === 11);

                                                return (
                                                    <tr key={key}>
                                                        <td>{val?.date}</td>
                                                        <td>{val?.allRevenue}</td>
                                                        <td>{earningType3 ? earningType3.counts : 'N/A'}</td>
                                                        <td>{earningType3 ? earningType3.amount : 'N/A'}</td>
                                                        <td>{earningType4 ? earningType4.counts : 'N/A'}</td>
                                                        <td>{earningType4 ? earningType4.amount : 'N/A'}</td>
                                                        <td>{earningType1 ? earningType1.counts : 'N/A'}</td>
                                                        <td>{earningType1 ? earningType1.amount : 'N/A'}</td>
                                                        <td>{earningType2 ? earningType2.counts : 'N/A'}</td>
                                                        <td>{earningType2 ? earningType2.amount : 'N/A'}</td>
                                                        <td>{earningType5 ? earningType5.counts : 'N/A'}</td>
                                                        <td>{earningType5 ? earningType5.amount : 'N/A'}</td>
                                                        <td>{earningType6 ? earningType6.amount : 'N/A'}</td>
                                                        <td>{earningType7 ? earningType7.amount.toFixed(2) : 'N/A'}</td>
                                                        <td>{earningType7 ? earningType7.counts : 'N/A'}</td>
                                                        <td>{earningType11 ? earningType11.amount : 'N/A'}</td>
                                                        <td>{earningType11 ? earningType11.counts : 'N/A'}</td>
                                                        <td>{val?.withdrawals}</td>
                                                        <td>{val?.allamountWD}</td>
                                                        <button className='tSubnitButton' onClick={() => { setIndex(key); setOpen(true) }}>Partner Details</button>
                                                    </tr>
                                                )
                                            })
                                        }

                                        <tfoot>
                                            <tr>
                                                <td>Total:</td>
                                                <td>{data?.reduce((sum, val) => sum + (val?.allRevenue || 0), 0).toFixed(2)}</td>
                                                <td>{data?.reduce((sum, val) => sum + (val?.dailyEarnings?.find(item => item?.earning_type === 3)?.counts || 0), 0).toFixed(2)}</td>
                                                <td>{data?.reduce((sum, val) => sum + (val?.dailyEarnings?.find(item => item?.earning_type === 3)?.amount || 0), 0).toFixed(2)}</td>
                                                <td>{data?.reduce((sum, val) => sum + (val?.dailyEarnings?.find(item => item?.earning_type === 4)?.counts || 0), 0).toFixed(2)}</td>
                                                <td>{data?.reduce((sum, val) => sum + (val?.dailyEarnings?.find(item => item?.earning_type === 4)?.amount || 0), 0).toFixed(2)}</td>
                                                <td>{data?.reduce((sum, val) => sum + (val?.dailyEarnings?.find(item => item?.earning_type === 1)?.counts || 0), 0).toFixed(2)}</td>
                                                <td>{data?.reduce((sum, val) => sum + (val?.dailyEarnings?.find(item => item?.earning_type === 1)?.amount || 0), 0).toFixed(2)}</td>
                                                <td>{data?.reduce((sum, val) => sum + (val?.dailyEarnings?.find(item => item?.earning_type === 2)?.counts || 0), 0).toFixed(2)}</td>
                                                <td>{data?.reduce((sum, val) => sum + (val?.dailyEarnings?.find(item => item?.earning_type === 2)?.amount || 0), 0).toFixed(2)}</td>
                                                <td>{data?.reduce((sum, val) => sum + (val?.dailyEarnings?.find(item => item?.earning_type === 5)?.counts || 0), 0).toFixed(2)}</td>
                                                <td>{data?.reduce((sum, val) => sum + (val?.dailyEarnings?.find(item => item?.earning_type === 5)?.amount || 0), 0).toFixed(2)}</td>
                                                <td>{data?.reduce((sum, val) => sum + (val?.dailyEarnings?.find(item => item?.earning_type === 6)?.amount || 0), 0).toFixed(2)}</td>

                                                <td>{data?.reduce((sum, val) => sum + (val?.dailyEarnings?.find(item => item?.earning_type === 7)?.amount || 0), 0).toFixed(2)}</td>
                                                <td>{data?.reduce((sum, val) => sum + (val?.dailyEarnings?.find(item => item?.earning_type === 7)?.counts || 0), 0).toFixed(2)}</td>
                                                <td>{data?.reduce((sum, val) => sum + (val?.dailyEarnings?.find(item => item?.earning_type === 11)?.amount || 0), 0).toFixed(2)}</td>
                                                <td>{data?.reduce((sum, val) => sum + (val?.dailyEarnings?.find(item => item?.earning_type === 11)?.counts || 0), 0).toFixed(2)}</td>

                                                <td>{data?.reduce((sum, val) => sum + (val?.withdrawals || 0), 0).toFixed(2)}</td>
                                                <td>{data?.reduce((sum, val) => sum + (val?.allamountWD || 0), 0).toFixed(2)}</td>
                                                <td></td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>

                            : null
                    }

                    </>
                        :
                        <>
                            <div className='loaderImage'>
                                <img src={loader} alt='loader' />
                            </div>
                        </>
                    }
                </div>
            </div>


            <Dialog
                open={open}
                onClose={() => handleClose()}
                TransitionComponent={Transition}
                className="dialogs"
            >
                <div className='profileInfoBox table partner-dialog-box'>
                    <h3 className='part-dia-head'>Partner Details</h3>
                    {isLoading ? <> {[data]?.length > 0 ?
                        <div style={{ width: '100%' }} className="container app-body">
                            <div className='tabel-scrol'>
                                <table id="users">
                                    <tr>
                                        <th style={{ width: '100px' }}>Date</th>
                                        <th style={{ width: '100px' }}>Partner Name</th>
                                        <th style={{ width: '100px' }}>Total Amount</th>
                                        <th style={{ width: '100px' }}>Total Revenue</th>
                                        <th style={{ width: '100px' }}>Total Count</th>
                                    </tr>
                                    {data[index]?.partnerEntry?.map((val, key) => {
                                        return (<tr key={key}>
                                            <td>{data[index]?.date}</td>
                                            <td>{val?.partnerName}</td>
                                            <td>{val?.totalAmount?.toFixed(2)}</td>
                                            <td>{val?.totalRevenue?.toFixed(2)}</td>
                                            <td>{val?.allcount}</td>
                                        </tr>)
                                    })
                                    }

                                </table>
                            </div>
                        </div> : null}
                    </> :
                        <>
                            <div className='loaderImage'>
                                <img src={loader} alt='loader' />
                            </div>
                        </>
                    }
                </div>


            </Dialog>

        </>
    )
}

export default GrossData