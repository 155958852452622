import React, { useState, useEffect } from 'react'
import SideBar from './SideBar'
import loader from '../assets/images/loader.gif'
import axiosClient from '../axios';
import { useParams, useLocation } from 'react-router-dom';
import Pagination from './Pagination';
import moment from 'moment';



const TaskLogs = () => {
    const [totalPage, setTotalPage] = useState(10)
    const [data, setData] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const { id } = useParams();
    const [allProvider, setAllProvider] = useState([]);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const userId = queryParams.get('userId');

    const [pagination, setPagination] = useState({
        page: 0,
        limit: 100
    });

    const getTaskLogs = () => {
        setIsLoading(false)
        axiosClient.get(`/tasklogs/${id}?page=${pagination.page}&limit=${pagination.limit}&userId=${userId}`)
            .then((res) => {
                setData(res?.data?.response)
                setTotalPage(res?.data?.userDocumentCount)
            })
            .catch((error) => { console.log(error) })
            .finally(() => setIsLoading(true))
    }

    const getPartner = () => {
        axiosClient
            .get("/partnersList")
            .then((res) => setAllProvider(res.data.response))
            .catch((error) => console.log(error));
    }

    useEffect(() => {
        getPartner()
    }, [])

    useEffect(() => {
        getTaskLogs()
    }, [pagination?.page, pagination?.limit])

    return (
        <>
            <SideBar handleSideBar={true} activeTab={"challenges"} />
            <div className="screen">
                <h2>Total Logs {totalPage}</h2>

                <div className='profileInfoBox table'>
                    {isLoading ? <>
                        <div style={{ width: '100%' }} className="container app-body">
                            {data.length > 0 ? <>
                                <div className="tabel-scrol">
                                    <table id="users">
                                        <thead>
                                            <tr>
                                                <th style={{ width: '50px' }}>S.NO.</th>
                                                <th>Logs Id </th>
                                                <th>Partner Id </th>
                                                <th>Previous Amount </th>
                                                <th>Callback Amount </th>
                                                <th>Track Type </th>
                                                <th>Task PausedAt</th>
                                                <th>Task ResumeAt</th>
                                                <th>CreatedAt</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data?.map((val, key) => (
                                                <tr>
                                                    <td>{(pagination.page * pagination.limit) + (key + 1)}</td>
                                                    <td>{val?._id}</td>
                                                    <td className='clickabuetd' onClick={() => {
                                                        if (val?.partnerId) {
                                                            window.open(`/partners/${val?.partnerId}`)
                                                        }
                                                    }}>
                                                        {val?.partnerId
                                                            ? allProvider?.find(provider => provider?._id === val.partnerId)?.name || "null"
                                                            : "null"}
                                                    </td>

                                                    <td>{val?.previousAmount}</td>
                                                    <td>{val?.callbackAmount}</td>
                                                    <td>{val?.trackType}</td>
                                                    <td>
                                                        {val?.taskPausedAt
                                                            ? moment.utc(val.taskPausedAt).local().format("DD/MM/YY, h:mm:ss a")
                                                            : 'null'}
                                                    </td>
                                                    <td>
                                                        {val?.taskResumeAt
                                                            ? moment.utc(val.taskResumeAt).local().format("DD/MM/YY, h:mm:ss a")
                                                            : 'null'}
                                                    </td>
                                                    <td>{moment.utc(val?.createdAt).local().format("DD/MM/YY, h:mm:ss a")}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>

                                </div>

                                <div>
                                    <Pagination
                                        filterDataInput={pagination}
                                        setFilterDataInput={setPagination}
                                        totalPage={totalPage}
                                    />
                                </div>
                            </> :
                                <>
                                    <h4 className='h5Heading'>No Data Available</h4>
                                </>}
                        </div>

                    </> :
                        <>
                            <div className='loaderImage'>
                                <img src={loader} alt='loader' />
                            </div>
                        </>
                    }
                </div>
            </div>
        </>
    )
}

export default TaskLogs
