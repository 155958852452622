import SideBar from './SideBar'
import React ,{ useState } from 'react'
import { useNavigate } from 'react-router-dom';
import axiosClient from '../axios';
import loader from '../assets/images/loader.gif'



function LeaderBoard() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)

    const adminArray = JSON.parse(localStorage.getItem("admin"));
    const Role = adminArray?.role
    console.log("Role",Role)

    const handleDeclareResult = (val) => {
        setLoading(true)
        axiosClient.get(`/admin/leaderBoard/result_declare?type=${val}`)
            .then((res) => {
                console.log("res form declare restult api", res)
                if (res?.data?.statusCode == 200) {
                    alert(res?.data?.message)
                }
                else {
                    alert("Some Thing Went Wrong")
                }
            })
            .catch((err) => {
                alert(err?.response?.data?.message)
                console.log(err)
            }
            )
            .finally(()=>setLoading(false))
    }

    const handleReferResult = () => {
        setLoading(true)
        axiosClient.get(`/admin/leaderBoard/refer/result_declare`)
            .then((res) => {
                console.log("res form Refer restult api", res)
                if (res?.data?.statusCode == 200) {
                    alert(res?.data?.message)
                }
                else {
                    alert("Some Thing Went Wrong")
                }
            })
            .catch((err) => {
                alert(err?.response?.data?.message)
                console.log(err)
            }
            )
            .finally(()=>setLoading(false))

    }
    return (
        <>
            <SideBar handleSideBar={true} activeTab={"Leaderboard"} />
            <div className="screen">
                <div className='dFlex'>
                    <h2 className='screenHeading catHead'>Leaderboard</h2>
                </div>

                {loading ?
                    <>
                        <div className='loaderImage'>
                            <img src={loader} alt='loader' />
                        </div>
                    </>
                    :
                    <div className="leader-board-nav">
                        <div className="leader-head-section">
                            <div className="sec--leader-1">
                                <span className='lead-heading'>Earning</span><span className='lead-go' onClick={() => { navigate(`/earningleaderboard`); }}>Go</span>
                            </div>
                            <div className="sec--leader-1">
                                <span className='lead-heading'>Referal</span><span className='lead-go' onClick={() => { navigate(`/referalleaderboard`); }}>Go</span>
                            </div>
                        </div>


                        <div className="leader-head-section">
                            <h3>Winners</h3>
                            <div className="sec--leader-1">
                                <span className='lead-heading'>Earning</span><span className='lead-go' onClick={() => { navigate(`/winnerleaderboard`); }}>Go</span>
                            </div>
                            <div className="sec--leader-1">
                                <span className='lead-heading'>Referal</span><span className='lead-go' onClick={() => { navigate(`/winnerleaderboard`); }}>Go</span>
                            </div>
                        </div>


                       {Role == "superAdmin" && <div className="leader-head-section">
                            <h3>Declare Result</h3>
                            <div className="sec--leader-1">
                                <span className='lead-heading'>Daily Result</span><span className='lead-go' onClick={() => { handleDeclareResult(1) }}>Declare</span>
                            </div>
                            <div className="sec--leader-1">
                                <span className='lead-heading'>Weekly Result</span><span className='lead-go' onClick={() => { handleDeclareResult(2) }}>Declare</span>
                            </div>
                            <div className="sec--leader-1">
                                <span className='lead-heading'>Monthly Result</span><span className='lead-go' onClick={() => { handleDeclareResult(3) }}>Declare</span>
                            </div>
                            <div className="sec--leader-1">
                                <span className='lead-heading'>Referal Result</span><span className='lead-go' onClick={() => { handleReferResult() }}>Declare</span>
                            </div>
                        </div>}
                    </div>
                }

            </div>
        </>
    )
}

export default LeaderBoard