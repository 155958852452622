import React, { useState, useEffect } from 'react'
import SideBar from './SideBar'
import loader from '../assets/images/loader.gif'
import axiosClient from '../axios';
import { useParams } from 'react-router-dom';
import Pagination from './Pagination';
import moment from 'moment';



const SingleUserAllTask = () => {
    const [totalPage, setTotalPage] = useState(10)
    const [data, setData] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const { id } = useParams();
    const [pagination, setPagination] = useState({
        page: 0,
        limit: 100
    });

    const taskStatusMap = {
        0: "New",
        1: "On Going",
        2: "Disqualified",
        3: "Completed",
        4: "Paused"
      };

    const getUserTaskData = () => {
        setIsLoading(false)
        axiosClient.get(`/userAllTasks/${id}?page=${pagination.page}&limit=${pagination.limit}`)
            .then((res) => {
                setData(res?.data?.response)
                setTotalPage(res?.data?.userDocumentCount)
            })
            .catch((error) => { console.log(error) })
            .finally(()=>setIsLoading(true))
    }

    useEffect(() => {
        getUserTaskData()
    }, [])

    return (
        <>
            <SideBar handleSideBar={true} activeTab={"users"} />
            <div className="screen">
                <h1>Total User Task {totalPage}</h1>

                <div className='profileInfoBox table'>
                    {isLoading ? <>
                        <div style={{ width: '100%' }} className="container app-body">
                            {data.length > 0 ? <>
                                <div className="tabel-scrol">
                                    <table id="users">
                                        <thead>
                                            <tr>
                                                <th style={{ width: '50px' }}>S.NO.</th>
                                                <th>taskId</th>
                                                {/* <th>UserName </th>
                                                <th>Phone Number</th> */}
                                                <th>challangeId</th>
                                                <th>taskStatus</th>
                                                <th>taskStartedAt</th>
                                                <th>taskEndedAt</th>
                                                <th>earnings</th>
                                                <th>timeTaken</th>
                                                <th>remainingTime</th>
                                                <th>completed_challenges</th>
                                                <th>nPauses</th>
                                                <th>includePartner</th>
                                                <th>taskPausedAt</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data?.map((val, key) => (
                                                <tr>
                                                    <td>{(pagination.page * pagination.limit) + (key + 1)}</td>
                                                    <td>{val?.taskId}</td>
                                                    {/* <td>{val?.userId?.name}</td>
                                                    <td>{val?.userId?.phoneNumber}</td> */}
                                                    <td>{val?.challangeId}</td>
                                                    <td>{taskStatusMap[val?.taskStatus]}</td>
                                                    <td>{moment.utc(val?.taskStartedAt).local().format("DD/MM/YY, h:mm:ss a")}</td>
                                                    <td>{moment.utc(val?.taskEndedAt).local().format("DD/MM/YY, h:mm:ss a")}</td>
                                                    <td>{val?.earnings?.toFixed(2)}</td>
                                                    <td>{val?.timeTaken?.toFixed(2)}</td>
                                                    <td>{val?.remainingTime?.toFixed(2)}</td>
                                                    <td>{val?.completed_challenges}</td>
                                                    <td>{val?.nPauses}</td>
                                                    <td>{val?.includePartner ? "True" : "False"}</td>
                                                    <td>{val?.taskPausedAt}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>

                                </div>

                                <div>
                                        <Pagination
                                            filterDataInput={pagination}
                                            setFilterDataInput={setPagination}
                                            totalPage={totalPage}
                                        />
                                    </div>
                            </> :
                                <>
                                    <h4 className='h5Heading'>No Data Available</h4>
                                </>}
                        </div>

                    </> :
                        <>
                            <div className='loaderImage'>
                                <img src={loader} alt='loader' />
                            </div>
                        </>
                    }
                </div>
            </div>
        </>
    )
}

export default SingleUserAllTask
