
import axios from "axios";


const axiosClient = axios.create();
const jwt = JSON.parse(localStorage.getItem("JWT"));

axiosClient.defaults.baseURL = "https://adminserver.prorewards.io/server";
// axiosClient.defaults.baseURL = "https://qa-prorewards-admin.khiladiaddasupport.com/server";
// axiosClient.defaults.baseURL = "http://localhost:7001/server";

axiosClient.defaults.headers = {
    authorization: `${jwt}`,
};
let isTokenExpiredAlertShown = false;

axiosClient.interceptors.response.use((config) => {
    let { data: { message, statusCode } } = config
    if (statusCode === 500 && !isTokenExpiredAlertShown) {
        isTokenExpiredAlertShown = true;

        alert(message)
        localStorage.clear()
        window.location.reload();
    }
    return config;
}, (error) => {
    let { response: { data: { statusCode } } } = error

    if (statusCode === 500 && !isTokenExpiredAlertShown) {
        isTokenExpiredAlertShown = true;
        localStorage.clear()
        window.location.reload();
    }
    return Promise.reject(error);
});
export default axiosClient;
