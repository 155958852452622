import React, { useCallback, useEffect, useState } from "react";
import SideBar from "./SideBar";
import axiosClient from "../axios";
import { Autocomplete } from "@mui/material";
import { TextField } from '@mui/material';
import { ReactComponent as Config } from "../assets/images/config.svg"
import { ReactComponent as Cross } from "../assets/images/cross.svg"
import Pagination from './Pagination';

import moment from 'moment';
import { ReactComponent as Edit } from "../assets/images/edit.svg";
import { ReactComponent as Add } from "../assets/images/add.svg";
import { ReactComponent as CopyIcon } from "../assets/images/copy.svg";
import loader from '../assets/images/loader.gif'
import { useNavigate } from "react-router-dom";
import CustomizedHook from "./CustomizedHook";
import Slide from '@mui/material/Slide';
import debounce from "lodash/debounce";
import Dialog from '@mui/material/Dialog';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function AllOffers() {
  const navigate = useNavigate();

  const [totalPage, setTotalPage] = useState(10)
  const [cloneOfferData, setCloneOfferData] = useState({})
  const [searchValue, setSearchValue] = useState("")

  const [offerFilterData, setOfferFilterData] = useState({
    page: 0,
    limit: 100,
    is_active: true,
    country: [],
    providerId: null,
    searchValue: "",
    is_carousel:false
  })

  const [show, setShow] = useState(false)
  const [country, setCountry] = useState([])
  const [allProvider, setAllProvider] = useState([]);
  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState([])
  const [open, setOpen] = useState(false);
  const [openStatus, setOpenStatus] = useState(false)

  const handleSelectedValuesChange = (values) => {
    const namesArray = values?.map(obj => obj.code);
    console.log("namesArray", namesArray)
    setCountry(namesArray)
  };

  const getOffers2 = (value) => {
    setShow(false)
    setIsLoading(false)
    console.log("59 searchValue", searchValue, "value", value)

    let bodyData = offerFilterData
    bodyData.country = country
    bodyData.searchValue = searchValue || value || "";

    axiosClient
      .post(`/admin/allOffer`, bodyData)
      .then((res) => {
        setTotalPage(res?.data?.response?.totalCount[0]?.count)
        setData(res.data?.response?.totalData)
      })
      .catch((error) => console.log(error))
      .finally(() => {
        setIsLoading(true)
      })
  }


  const cloneUser = () => {

    const formData = {
      name: cloneOfferData.name,
      ratings: cloneOfferData.ratings,
      rewards: cloneOfferData.rewards,
      trackLink: cloneOfferData.trackLink,
      is_active: false,
      is_popular: cloneOfferData.is_popular,
      description: cloneOfferData.description,
      important_tips: cloneOfferData.important_tips,
      small_descriptions: cloneOfferData.small_descriptions,
      conditions: cloneOfferData.conditions,
      category: cloneOfferData.category,
      provider: cloneOfferData?.provider?._id,
      videoLink: cloneOfferData.videoLink,
      countries: cloneOfferData.countries,
      availabelOn: cloneOfferData.availabelOn,
      image: cloneOfferData.image
    }

    axiosClient
      .post("/admin/newOffer", formData)
      .then((res) => {
        if (res.data.status === true) {
          alert("Offer has been Cloned succeffully")
        } else {
          console.log("some error")
        }
      })
      .catch((error) => {
        alert("Some error while adding offer")
      })

  }

  const handleOfferStatus = () => {
    setOpenStatus(false)
    axiosClient.put(`/update/offers/status`)
      .then((res) => {
        console.log("res of offer status line 115", res)
        if (res?.data?.statusCode === 201) {
          alert(res?.data?.message)
        }
      })
      .catch((err) => console.log(err))
      .then((res) => {
        console.log("res of offer status line 115", res)
        if (res?.data?.statusCode === 201) {
          alert(res?.data?.message)
        }
      })
      .catch((err) => console.log(err))
  }

  const getPartner = () => {
    axiosClient
      .get("/partnersList")
      .then((res) => setAllProvider(res.data.response))
      .catch((error) => console.log(error));
  }

  const handleSearch = useCallback(
    debounce((value) => {
      setSearchValue(value)
      getOffers2(value);
    }, 500),
    []
  );


  const handleChange = (e) => {
    const { value } = e.target;
    setOfferFilterData({ ...offerFilterData, page: 0, limit: 100 });
    handleSearch(value);
  };


  useEffect(() => {
    getPartner()
  }, [])

  const handleDelete = () => {
    setOpen(false);
    setOpenStatus(false)
  }


  useEffect(() => {
    getOffers2();
  }, [offerFilterData.page, offerFilterData.limit]);

  return (
    <>
      <SideBar handleSideBar={true} activeTab={"allOffer"} />
      <div className="screen">
        <div className="headingFlexAlloffer">
          <h2 className="screenHeading catHead">All Offers</h2>

          <div className="alloffer-head-btn">
            <div
              className="addofferButton"
              onClick={() => {
                setOpenStatus(true)
              }}
            >
              Update Status
            </div>
            <div
              className="addofferButton"
              onClick={() => {
                navigate("/offer");
              }}
            >
              <Add />
              Add Offer
            </div>

          </div>


        </div>

        <div className="dFlex">
          <div className="all-offer-flex">
            <div className="searchContainer">
              <input
                type="text"
                placeholder="Search..."
                onChange={handleChange}
              />
            </div>

            <div className="offerFilterbox">
              <span>
                {
                  show ?
                    <Cross onClick={() => setShow(false)} style={{ width: '20px', height: '17px' }} />
                    : <Config onClick={() => setShow(true)} />
                }
              </span>

              <div className={`offer-Main-div ${show && "active"}`}>

                <div className="offer-filter-item">
                  <p>Is Active</p>
                  <input
                    type="radio"
                    value={true}
                    checked={offerFilterData?.is_active ? true : false}
                    onChange={(e) => {
                      setOfferFilterData({ ...offerFilterData, is_active: true })
                    }}
                  />
                  <span >True</span>
                  <input
                    type="radio"
                    value={true}
                    checked={!offerFilterData?.is_active ? true : false}
                    onChange={(e) => {
                      setOfferFilterData({ ...offerFilterData, is_active: false })
                    }}
                  />
                  <span>False</span>
                </div>

                <div className="offer-filter-item">
                  <p>Is Carousel</p>
                  <input
                    type="radio"
                    value={true}
                    checked={offerFilterData?.is_carousel ? true : false}
                    onChange={(e) => {
                      setOfferFilterData({ ...offerFilterData, is_carousel: true })
                    }}
                  />
                  <span >True</span>
                  <input
                    type="radio"
                    value={true}
                    checked={!offerFilterData?.is_carousel ? true : false}
                    onChange={(e) => {
                      setOfferFilterData({ ...offerFilterData, is_carousel: false })
                    }}
                  />
                  <span>False</span>
                </div>

                <div className="offer-filter-item">
                  <div style={{ width: "150px" }}>
                    <Autocomplete
                      disablePortal
                      onChange={(event, newValue) => {
                        if (newValue) {
                          setOfferFilterData({ ...offerFilterData, providerId: newValue._id })

                        }
                        else {
                          setOfferFilterData({ ...offerFilterData, providerId: null })

                        }
                      }}
                      options={allProvider}
                      getOptionLabel={option => option.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Provider"
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="offer-filter-item">
                  <CustomizedHook onSelectedValuesChange={(e) => handleSelectedValuesChange(e)} />
                </div>

                <button className='allofferSubmit-2'
                  onClick={() => { getOffers2() }}
                >
                  Submit
                </button>

              </div>

            </div>

          </div>
        </div>

        {isLoading ?
          <div className="profileInfoBox table">
            <>
              {data?.length != 0 ?
                <div style={{ width: "100%" }} className="container app-body">
                  <div className="tabel-scrol">
                    <table id="users">
                      <thead>
                        <tr>
                          <th style={{ width: '50px' }}>S.NO.</th>
                          <th style={{ width: '130px' }}>Name</th>
                          <th style={{ width: '150px' }}>Provider</th>
                          <th style={{ width: '200px' }}>payout</th>
                          <th style={{ width: '200px' }}>Revenue</th>
                          <th style={{ width: '100px' }}>availabelOn</th>
                          <th style={{ width: '70px' }}>category</th>
                          <th style={{ width: '100px' }}>countries</th>
                          <th style={{ width: '200px' }}>Created At</th>
                          <th style={{ width: '200px' }}>Updated At</th>
                          <th style={{ width: '100px' }}>Is Active</th>
                          <th style={{ width: '100px' }}>Is Popular</th>
                          <th style={{ width: '70px' }}>Edit</th>
                          <th style={{ width: '70px' }}>clone offer</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data?.map((val, key) => {
                          return (
                            <tr key={key + 1}>
                              <td>{(offerFilterData?.page * offerFilterData?.limit) + (key + 1)}</td>
                              {/* <td className='clickabuetd' onClick={() => window.open(`/offer/${val?._id}`)}>{val?.name}</td> */}
                              <td
                                className='clickabuetd'
                                onClick={() => window.open(`/offer/${val?._id}`)}
                              >
                                {val?.name?.length > 30
                                  ? `${val?.name.slice(0, 30)}...`
                                  : val?.name}
                              </td>
                              <td>{val?.provider?.name}</td>
                              <td>{val?.rewards?.map((price, idx) => {
                                return <p key={idx}>{price?.payOutAmount?.toFixed(2)}</p>;
                              })}</td>
                              <td>{val?.rewards?.map((price, idx) => {
                                return <p key={idx}>{price?.revenueAmount?.toFixed(2)}</p>;
                              })}</td>
                              <td>
                                {val.availabelOn.includes(1) && val.availabelOn.includes(2) && val.availabelOn.includes(3)
                                  ? 'Android, Desktop, Apple'
                                  : val.availabelOn.includes(1) && val.availabelOn.includes(2)
                                    ? 'Android, Desktop'
                                    : val.availabelOn.includes(1) && val.availabelOn.includes(3)
                                      ? 'Android, Apple'
                                      : val.availabelOn.includes(2) && val.availabelOn.includes(3)
                                        ? 'Desktop, apple'
                                        : val.availabelOn.includes(1)
                                          ? 'Android'
                                          : val.availabelOn.includes(2)
                                            ? 'Desktop'
                                            : val.availabelOn.includes(3)
                                              ? 'Apple'
                                              : ''}
                              </td>

                              <td >{val?.category}</td>
                              <td>
                                {
                                  val?.countries.length > 3 ?
                                    (<>
                                      {val?.countries.slice(0, 3).map((item, index) => (
                                        <React.Fragment key={item._id}>
                                          {item.country}
                                          {index !== val.countries.length - 1 && ', '}
                                        </React.Fragment>
                                      ))}{'...'}
                                    </>)
                                    :
                                    val?.countries.map((item, index) => (
                                      <React.Fragment key={item._id}>
                                        {item.country}
                                        {index !== val.countries.length - 1 && ', '}
                                      </React.Fragment>
                                    ))
                                }
                              </td>

                              <td>{moment.utc(val?.created_at).local().format("MMM Do YY, h:mm:ss a")}</td>
                              <td>{moment.utc(val?.updated_at).local().format("MMM Do YY, h:mm:ss a")}</td>
                              <td>{val?.is_active ? "true" : "false"}</td>
                              <td>{val?.is_popular ? "true" : "false"}</td>
                              <td><Edit
                                className="whiteRound"
                                onClick={() => {
                                  navigate(`/editVoucher?offer_id=${val?._id}`);
                                }}
                              /></td>
                              <td> <CopyIcon className="whiteRound" onClick={() => { setOpen(true); setCloneOfferData(val) }}></CopyIcon></td>
                            </tr>
                          )
                        })
                        }
                      </tbody>
                    </table>
                  </div>

                  <div>
                    <Pagination
                      filterDataInput={offerFilterData}
                      setFilterDataInput={setOfferFilterData}
                      totalPage={totalPage}
                    />
                  </div>

                </div>
                :
                <h1>No Data Found</h1>
              }
            </>
          </div>
          :
          <>
            <div className='loaderImage'>
              <img src={loader} alt='loader' />
            </div>
          </>
        }
      </div>

      <Dialog
        open={open}
        onClose={() => handleDelete()}
        TransitionComponent={Transition}
        className="dialogs"
      >
        <div className='categoryEditBox'>
          <h3>Clone offer</h3>
          <p>
            Are You Sure You Want To Duplicate The Offer
          </p>
          <div className='dFlex'>
            <div className='editButton' onClick={() => { cloneUser(); handleDelete() }}>Yes</div>
            <div className='editButton' onClick={() => handleDelete()}>No</div>
          </div>
        </div>
      </Dialog>


      <Dialog
        open={openStatus}
        onClose={() => handleDelete()}
        TransitionComponent={Transition}
        className="dialogs"
      >
        <div className='categoryEditBox'>
          <h3>Change Status</h3>
          <p>
            Are You Sure You Want To Change The Status
          </p>
          <div className='dFlex'>
            <div className='editButton' onClick={() => { handleOfferStatus() }}>Yes</div>
            <div className='editButton' onClick={() => { setOpenStatus(false) }}>No</div>
          </div>
        </div>
      </Dialog>



    </>
  );

}
export default AllOffers;
