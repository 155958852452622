
const partnerTrackingCase = async (data, multipleOfferWall) => {
    let trackType;
    let trackArray = []

    function areAllElementsSame(arr) {
        if (arr.length == 1) return arr[0];
        let isSame = arr.every(element => element === arr[0]);
        if (isSame) {
            return arr[0]
        }
        else {
            return 17
        }
    }

    if (data?.partnerOfferDetail && data?.partnerOfferDetail?.length > 0) {

        if (multipleOfferWall) {
            const partner = data.partnerOfferDetail[0];
            if (
                partner.earningCoins === 0 &&
                partner.winningPercentage === 0 &&
                partner.winningCoin === 0 &&
                partner.minimumValue === 0 &&
                partner.maximumValue === 0 &&
                partner.offerQuantity !== 0 &&
                partner.partnerInfo.length === 0
            ) {
                // Complete 1 or n task from any partner
                console.log("Complete 1 or n task from any partner");
                trackType = 1;
            } else if (
                partner.earningCoins !== 0 &&
                partner.winningPercentage === 0 &&
                partner.winningCoin === 0 &&
                partner.minimumValue === 0 &&
                partner.maximumValue === 0 &&
                partner.offerQuantity === 0 &&
                partner.partnerInfo.length === 0
            ) {
                // Earn 500 or n coins from any partner
                console.log("Earn 500 or n coins from any partner");
                trackType = 2;
            } else if (
                partner.earningCoins === 0 &&
                partner.winningPercentage === 0 &&
                (partner.winningCoin || partner.winningCoin === 0) &&
                partner.minimumValue !== 0 &&
                partner.maximumValue === 0 &&
                partner.offerQuantity !== 0 &&
                partner.partnerInfo.length === 0
            ) {
                // Complete 1 task from any partner of minimum 100 coins and get 50 coins
                console.log(
                    "Complete 1 task from any partner of minimum 100 coins and get 50 coins"
                );
                trackType = 3;
            } else if (
                partner.earningCoins !== 0 &&
                partner.winningPercentage === 0 &&
                partner.winningCoin === 0 &&
                partner.minimumValue === 0 &&
                partner.maximumValue === 0 &&
                partner.offerQuantity === 0 &&
                partner.partnerInfo.length !== 0
            ) {
                // Earn 500 coins from any partner (include 1 partner), or n number of partnerofferdetail
                console.log(
                    "Earn 500 coins from any partner (include 1 partner), or n number of partnerofferdetail"
                );
                trackType = 4;
            } else {
                trackType = 0;
                console.log("no case found");

            }
        } else {
            // different partner
            if (data.partnerOfferDetail.length > 1) {
                for (let i = 0; i < data.partnerOfferDetail.length; i++) {
                    const partner = data.partnerOfferDetail[i];
                    const {
                        earningCoins,
                        winningPercentage,
                        winningCoin,
                        minimumValue,
                        maximumValue,
                        offerQuantity,
                        partnerInfo,
                    } = partner;
                    if (
                        earningCoins !== 0 &&
                        winningPercentage === 0 &&
                        winningCoin !== 0 &&
                        minimumValue === 0 &&
                        maximumValue === 0 &&
                        offerQuantity === 0 &&
                        partnerInfo.length === 1
                    ) {
                        // Earn 100 coins from A and get 20
                        // Earn 90 coins from b and get 20
                        // Earn 80 coins from c and get 20
                        console.log("Earning coins from A and get 20");
                        trackType = 5;
                        trackArray.push(5)

                    } else if (
                        earningCoins === 0 &&
                        winningPercentage === 0 &&
                        winningCoin === 0 &&
                        minimumValue !== 0 &&
                        maximumValue === 0 &&
                        offerQuantity !== 0 &&
                        partnerInfo.length === 1
                    ) {
                        //  Complete 1 task from A offer wall of 100 coins "
                        //  Complete 1 task from B offer wall of 100 coins "
                        //  Complete 1 task from C offer wall of 100 coins "
                        console.log("Complete 1 task from A offer wall of 100 coins ");
                        trackType = 6;
                        trackArray.push(6)

                    } else if (
                        earningCoins !== 0 &&
                        winningPercentage === 0 &&
                        winningCoin == 0 &&
                        minimumValue === 0 &&
                        maximumValue === 0 &&
                        offerQuantity === 0 &&
                        partnerInfo.length === 1
                    ) {
                        //       Scenario 4
                        // Earn 100 coins from A
                        // Earn 90 coins from b
                        // Earn 80 coins from c
                        // and get 100 coins
                        trackType = 7;
                        trackArray.push(7)

                    } else if (
                        earningCoins === 0 &&
                        winningPercentage === 0 &&
                        winningCoin == 0 &&
                        minimumValue === 0 &&
                        maximumValue === 0 &&
                        offerQuantity !== 0 &&
                        partnerInfo.length === 1
                    ) {
                        // Scenario 5:
                        // (Complete 1 task from A offer wall)
                        // (Complete 1 task from B )
                        // (Complete 1 task from C  )
                        // and get 100 coins
                        trackType = 8;
                        trackArray.push(8)

                    } else {
                        trackType = 0;
                        console.log("no case found");
                        return trackType;
                    }
                }
            } else if (data.partnerOfferDetail.length === 1) {
                const partner = data.partnerOfferDetail[0];
                if (
                    partner.earningCoins === 0 &&
                    partner.winningPercentage === 0 &&
                    partner.winningCoin === 0 &&
                    partner.minimumValue === 0 &&
                    partner.maximumValue === 0 &&
                    partner.offerQuantity !== 0 &&
                    partner.partnerInfo.length === 1
                ) {
                    // specific partner case
                    // Scenario 1: (Complete n task from specific partner )
                    trackType = 9;
                } else if (
                    partner.earningCoins !== 0 &&
                    partner.winningPercentage === 0 &&
                    partner.winningCoin === 0 &&
                    partner.minimumValue === 0 &&
                    partner.maximumValue === 0 &&
                    partner.offerQuantity === 0 &&
                    partner.partnerInfo.length === 1
                ) {
                    // specific partner case
                    // Scenario 2: (Earn 500 coins from specific partner)
                    trackType = 10;
                } else if (
                    partner.earningCoins === 0 &&
                    partner.winningPercentage === 0 &&
                    partner.winningCoin === 0 &&
                    partner.minimumValue !== 0 &&
                    partner.maximumValue === 0 &&
                    partner.offerQuantity !== 0 &&
                    partner.partnerInfo.length === 1
                ) {
                    // specific partner case
                    // Scenario 4: (Complete n task from specific partner of minimum 100 coins )
                    trackType = 11;
                } else if (
                    partner.earningCoins !== 0 &&
                    partner.winningPercentage === 0 &&
                    partner.winningCoin === 0 &&
                    partner.minimumValue === 0 &&
                    partner.maximumValue === 0 &&
                    partner.offerQuantity === 0 &&
                    partner.partnerInfo.length > 1
                ) {
                    // 5: Multiple Partner:
                    // Scenario 1 : Earn 500 coins from n number of partnerofferdetail
                    trackType = 12;
                } else if (
                    partner.earningCoins === 0 &&
                    partner.winningPercentage === 0 &&
                    partner.winningCoin === 0 &&
                    partner.minimumValue === 0 &&
                    partner.maximumValue === 0 &&
                    partner.offerQuantity !== 0 &&
                    partner.partnerInfo.length > 1
                ) {
                    // Scenario 6 : Complete n task from n number of partnerofferdetail
                    trackType = 13;
                } else {
                    trackType = 0;
                    console.log("no case found");
                    return trackType
                }
            }
        }
    } else if (data?.offerDetail && data?.offerDetail?.length > 0) {
        for (let i = 0; i < data.offerDetail.length; i++) {
            const offer = data.offerDetail[i];
            if (
                offer?.offerId &&
                offer?.earningCoins === 0 &&
                offer?.winningPercentage === 0 &&
                offer?.winningCoin !== 0
            ) {
                //  3: Multiple offer :
                //  (Task 1 :Complete a Specific offer and get 20 extra)
                //  (Task 2: Complete a Specific offer and get 20 extra. )
                trackType = 14;
                trackArray.push(14)

            } else if (
                offer?.offerId &&
                offer?.earningCoins === 0 &&
                offer?.winningPercentage === 0 &&
                offer?.winningCoin === 0
            ) {

                // (Task 1 :Complete a Specific offer )
                // (Task 2: Complete a Specific offer )
                // and get 100 coins extra.

                trackType = 15;
                trackArray.push(15)
            } else {
                trackType = 0;
                console.log("no case found");
                return trackType;
            }
        }
    } else {
        if (data?.platForms && data?.platForms?.length > 0) {
            if (multipleOfferWall) {
                for (let i = 0; i < data.platForms.length; i++) {
                    const item = data.platForms[i];
                    if (item?.earningCoins != 0) {
                        trackType = 16;
                    } else {
                        trackType = 0;
                        console.log("no case found");
                        return trackType
                    }
                }
            } else {
                trackType = 0;
                console.log("no case found");
                return trackType
            }
        }
    }

    if (trackArray?.length > 1) {
        trackType = areAllElementsSame(trackArray)
        return trackType
    }
    else {
        console.log("trackType", trackType);
        return trackType;
    }
};






export default partnerTrackingCase