import React, { useCallback, useEffect, useState } from 'react'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { TextField } from '@mui/material';
import debounce from "lodash/debounce";

import { ReactComponent as Config } from "../assets/images/config.svg"
import { ReactComponent as Cross } from "../assets/images/cross.svg"

import SideBar from './SideBar'
import { useNavigate } from 'react-router-dom';
import axiosClient from '../axios';
import moment from 'moment';
import '../assets/css/userTable.css'
import loader from '../assets/images/loader.gif'

import { dateConvert } from '../dateConverter';
import { Autocomplete } from "@mui/material";
import CSVDownload from "./CSVDownload"
import Pagination from './Pagination';

function Transaction2() {
    const navigate = useNavigate();

    const [data, setData] = useState([])
    const [isLoading, setIsLoading] = useState(true)

    const [date, setDate] = useState({
        start: null,
        end: null,
    })
    const [allProvider, setAllProvider] = useState([]);
    const [showFilterBox, setShowFilteBox] = useState(false)
    const [provider, setProvider] = useState({});
    const [totalPage, setTotalPage] = useState(0)
    const [scratchCardShow, setscratchCardShow] = useState(false);


    const [filterDataInput, setFilterDataInput] = useState({
        page: 0,
        limit: 100,
        statusType: [1],
        by_admin: false,
        earning_type: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
        s_date: "",
        e_date: "",
        provider: provider.id || null
    })

    const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);


    const handleDate = (e, f) => {
        const res = dateConvert(e);
        if (f === 1) {
            setDate({ ...date, start: e })
            setFilterDataInput({ ...filterDataInput, s_date: res });
            return
        }
        if (f === 2) {
            setDate({ ...date, end: e })
            setFilterDataInput({ ...filterDataInput, e_date: res });
            return
        }
    }


    const getPartner = () => {
        axiosClient
            .get("/partnersList")
            .then((res) => setAllProvider(res.data.response))
            .catch((error) => console.log(error));
    }

    const handleDownload = async (csvData) => {
        try {
            const dataToExport = csvData.map((val, key) => ({
                "S.NO.": (key + 1),
                "UserName": val?.meta_data?.user_name,
                "Email": val?.meta_data?.user_email,
                "phoneNumber": val?.meta_data?.user_number,
                "TransactionId": val?.transactionId,
                "UserID": val?.meta_data?.user_id,
                "Amount": val?.amount,
                "Past Wallet": val?.pastWallet,
                "OfferName": val?.meta_data?.offer_name ? val?.meta_data?.offer_name : "null",
                "Earning Type": val?.earning_type == 1 ? "spinWheel" : val?.earning_type == 2 ? "SC Login" : val?.earning_type == 3 ? "singup" : val?.earning_type == 4 ? "Weekly" : val?.earning_type === 5 ? "SC Referal" : val?.earning_type === 6 ? "LeaderBoard Prize" : val?.earning_type === 7 ? "Affiliate Earning" : "null",
                "Provider": val?.meta_data?.partner_name ? val?.meta_data?.partner_name : "null",
                "statusType": val?.statusType === 1 ? "Credited" : val?.statusType === 2 ? "Debited" : val?.statusType === 3 ? "Pending" : "Null",
                "Time": moment.utc(val?.time).local().format("MMM Do YY, h:mm:ss a")
            }));
            CSVDownload(dataToExport);
        } catch (error) {
            console.error('Error downloading CSV:', error);
        }
    };


    const transaActionCsv = async () => {
        setIsLoading(false)
        let bodyData = filterDataInput
        // bodyData.searchValue = searchValue || ""
        await axiosClient
            .post(`/downloadTransactionCsv`, bodyData)
            .then((res) => {
                handleDownload(res?.data?.response)
            })
            .catch((error) =>
                console.log(error)
            ).finally(() => {
                setIsLoading(true)
            })
    }


    const handleTransFilter = async (value) => {
        setShowFilteBox(false)
        setIsLoading(false)
        let bodyData = filterDataInput

        if (selectedCheckboxes.length != 0) {
            bodyData.earning_type = selectedCheckboxes;
        }

        if (!bodyData.earning_type.includes(0)) {
            bodyData.statusType = [1]
            bodyData.provider = ""
        }

        bodyData.searchValue = value || ""
        await axiosClient
            .post(`/getTransaction`, bodyData)
            .then((res) => {
                if (res.data.statusCode !== 201) {
                    alert(`Error ${res.data.message}`)
                    setFilterDataInput({
                        page: 0,
                        limit: 100,
                        statusType: [1],
                        by_admin: false,
                        earning_type: [0],
                        s_date: "",
                        e_date: "",
                        provider: ""
                    })
                    return
                }
                setTotalPage(res?.data?.response?.totalCount?.count)
                setData(res.data?.response?.totalData)
            })
            .catch((error) =>
                console.log(error)
            ).finally(() => {
                setIsLoading(true)
            })
    }

    const handleSearch = useCallback(
        debounce((value) => {
            handleTransFilter(value)
        }, 500),
        []
    );

    const handleChange = (e) => {
        const { value } = e.target;
        console.log("value", value)
        setFilterDataInput({ ...filterDataInput, page: 0, limit: 100 })
        handleSearch(value);
    };


    const handleCheckboxChange = (e) => {
        const value = parseInt(e.target.value);
        if (e.target.checked) {
            setSelectedCheckboxes(prevSelected => [...prevSelected, value]);
        } else {
            setSelectedCheckboxes(prevSelected => prevSelected.filter(item => item !== value));
        }
    };



    useEffect(() => {
        handleTransFilter()
    }, [filterDataInput.page, filterDataInput.limit])


    useEffect(() => {
        getPartner()
    }, [])
    return (
        <>
            <SideBar handleSideBar={true} activeTab={"transaction"} />
            <div className="screen">

                <div className='trans-upper-div transaction2flex'>
                    <h2 className='screenHeading catHead'>Transactions {totalPage}</h2>
                    <div>
                        <div>
                            {data.length > 0 && <>
                                <button className='csvButton'
                                    onClick={() => { transaActionCsv() }}
                                >
                                    Download CSV</button>
                            </>}
                        </div>
                        <div className="searchContainer notificationPage">
                            <input
                                type="text"
                                placeholder="Transaction Id..."
                                onChange={handleChange}
                            />
                        </div>

                        <div className='trans_filter_div'>
                            <span>
                                {
                                    showFilterBox ?
                                        <Cross onClick={() => setShowFilteBox(false)} style={{ width: '20px', height: '17px' }} />
                                        : <Config onClick={() => setShowFilteBox(true)} />
                                }
                            </span>

                            {showFilterBox ? <div className={`trans_filter_box  ${showFilterBox && "active"} `}>
                                <div className="trans_div_box">
                                    <p>Status Type</p>
                                    <input type="radio"
                                        checked={filterDataInput?.statusType[0] === 1 ? true : false}
                                        onChange={() => {
                                            setFilterDataInput({ ...filterDataInput, statusType: [1] })
                                        }}
                                        value={1}
                                    />
                                    <span>Credit</span>

                                    <input
                                        type="radio"
                                        checked={filterDataInput?.statusType[0] === 2 ? true : false}
                                        onChange={() => {
                                            setFilterDataInput({ ...filterDataInput, statusType: [2] })
                                        }}
                                        value={2}
                                    />
                                    <span>Debit</span>
                                </div>

                                <div className='trans_div_box'>
                                    <p>By Admin</p>
                                    <input
                                        type="radio"
                                        checked={filterDataInput?.by_admin ? true : false}
                                        onChange={() => {
                                            setFilterDataInput({ ...filterDataInput, by_admin: true })
                                        }}
                                        value={true}
                                    />
                                    <span>True</span>

                                    <input type="radio"
                                        checked={filterDataInput?.by_admin ? false : true}
                                        onChange={() => {
                                            setFilterDataInput({ ...filterDataInput, by_admin: false })
                                        }}
                                        value={true}
                                    />
                                    <span>False</span>
                                </div>


                                <div className='trans_earning_select'>
                                    <p>Earning Type</p>

                                    <div style={{ position: 'relative', width: 'content-fit' }} >
                                        <div className="trasitionButton notificationbutton" onClick={() => setscratchCardShow(!scratchCardShow)}>
                                            Earning Types
                                        </div>
                                        <div className={scratchCardShow ? `scratch_show` : `scratch_hide`}>
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    value="0"
                                                    checked={selectedCheckboxes.includes(0)}
                                                    onChange={handleCheckboxChange}
                                                /> Call back</label>
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    value="1"
                                                    checked={selectedCheckboxes.includes(1)}
                                                    onChange={handleCheckboxChange}
                                                /> Spin Wheel</label>
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    value="2"
                                                    checked={selectedCheckboxes.includes(2)}
                                                    onChange={handleCheckboxChange}
                                                /> SC Login</label>
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    value="3"
                                                    checked={selectedCheckboxes.includes(3)}
                                                    onChange={handleCheckboxChange}
                                                /> Signup</label>
                                            <label><input
                                                type="checkbox"
                                                value="4"
                                                checked={selectedCheckboxes.includes(4)}
                                                onChange={handleCheckboxChange}
                                            /> Weekly</label>
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    value="5"
                                                    checked={selectedCheckboxes.includes(5)}
                                                    onChange={handleCheckboxChange}
                                                /> SC Refer</label>
                                            <label>
                                                <input type="checkbox"
                                                    value="6"
                                                    checked={selectedCheckboxes.includes(6)}
                                                    onChange={handleCheckboxChange}
                                                /> Leader Board</label>
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    value="7"
                                                    checked={selectedCheckboxes.includes(7)}
                                                    onChange={handleCheckboxChange}
                                                /> Affiliate Earn</label>
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    value="8"
                                                    checked={selectedCheckboxes.includes(8)}
                                                    onChange={handleCheckboxChange}
                                                /> unLock Offerwall</label>
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    value="9"
                                                    checked={selectedCheckboxes.includes(9)}
                                                    onChange={handleCheckboxChange}
                                                />GiveAways</label>


                                            <label>
                                                <input
                                                    type="checkbox"
                                                    value="10"
                                                    checked={selectedCheckboxes.includes(10)}
                                                    onChange={handleCheckboxChange}
                                                />campaigns
                                            </label>

                                            <label>
                                                <input
                                                    type="checkbox"
                                                    value="11"  
                                                    checked={selectedCheckboxes.includes(11)}
                                                    onChange={handleCheckboxChange}
                                                />cint Profiler
                                            </label>

                                        </div>
                                    </div>
                                </div>
                                <div>
                                    {/* Selected checkboxes: {selectedCheckboxes.join(', ')} */}
                                </div>

                                <div className='trans_div_box'>
                                    <p>Provider</p>
                                    <div style={{ width: "140px" }} >
                                        <Autocomplete
                                            disablePortal
                                            // value={filterDataInput?.provider?.name}  
                                            value={filterDataInput?.provider ? filterDataInput?.provider?.name : ''}
                                            onChange={(event, newValue) => {
                                                if (newValue) {
                                                    setFilterDataInput({
                                                        ...filterDataInput, provider: {
                                                            id: newValue._id,
                                                            name: newValue.name
                                                        }
                                                    })
                                                }
                                                else {
                                                    setFilterDataInput({
                                                        ...filterDataInput, provider: null
                                                    })

                                                }
                                            }}
                                            disabled={!selectedCheckboxes.includes(0)}
                                            options={allProvider}
                                            // getOptionLabel={option => option.name}
                                            getOptionLabel={(option) => option['name'] || option}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Provider"
                                                />
                                            )}
                                        />

                                    </div>
                                </div>

                                <div className='trans_div_box'>
                                    <p>Start Date</p>
                                    {/* <div className='graphBox userGrapBox'> */}
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            label="Start Date"
                                            value={date?.start}
                                            // onChange={(value) => { setDate({ ...date, start: value }); }}
                                            onChange={(value) => { handleDate(value, 1) }}
                                            renderInput={(params) => <TextField {...params} />}
                                        // maxDate={date?.end}
                                        />
                                    </LocalizationProvider>
                                    {/* </div> */}
                                </div>

                                <div className='trans_div_box'>
                                    <p>End Date</p>
                                    {/* <div className='graphBox userGrapBox'> */}
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            label="End Date"
                                            value={date?.end}
                                            // onChange={(value) => { setDate({ ...date, end: value }); }}
                                            onChange={(value) => { handleDate(value, 2) }}
                                            renderInput={(params) => <TextField {...params} />}
                                        // maxDate={date?.end}
                                        />
                                    </LocalizationProvider>
                                    {/* </div> */}
                                </div>

                                <button className='t_submit_button' onClick={() => { handleTransFilter() }}>Submit</button>
                            </div> : ""}

                        </div>


                    </div>

                </div>

                <div className='profileInfoBox table'>
                    {isLoading ? <> {data?.length > 0 ?
                        <div style={{ width: '100%' }} className="container app-body">
                            <div className='tabel-scrol'>
                                <table id="users">
                                    <thead>
                                        <tr>
                                            <th style={{ width: '50px' }}>S.NO.</th>
                                            <th style={{ width: '100px' }}>UserName</th>
                                            <th style={{ width: '230px' }}>Email</th>
                                            <th style={{ width: '140px' }}>phoneNumber</th>
                                            <th style={{ width: '150px' }}>TransactionId</th>
                                            <th style={{ width: '210px' }}>UserID</th>
                                            <th style={{ width: '60px' }}>Amount</th>
                                            <th style={{ width: '100px' }}>Past Wallet</th>
                                            <th style={{ width: '100px' }}>OfferName</th>
                                            <th style={{ width: '150px' }}>Earning Type</th>
                                            <th style={{ width: '110px' }}>Provider</th>
                                            <th style={{ width: '100px' }}>statusType</th>
                                            <th style={{ width: '200px' }}>Time</th>
                                        </tr>
                                    </thead>
                                    {data?.map((val, key) => {
                                        return (
                                            <tbody key={key} id='userstbody'>
                                                <tr>
                                                    <td>{(filterDataInput.limit * filterDataInput.page) + (key + 1)}</td>
                                                    <td className='clickabuetd' onClick={() => window.open(`/users/transaction/${val?.userId}`)}>{val?.meta_data?.user_name}</td>
                                                    <td>{val?.meta_data?.user_email}</td>
                                                    <td>{val?.meta_data?.user_number}</td>
                                                    <td>{val?.transactionId}</td>
                                                    <td>{val?.meta_data?.user_id}</td>
                                                    <td>{val?.amount}</td>
                                                    <td>{val?.pastWallet?.toFixed(2)}</td>
                                                    <td
                                                        // onClick={() => navigate(`/offer/${val?.offerId?._id}`)}
                                                        onClick={() => {
                                                            if (val?.offerId?._id) {
                                                                navigate(`/offer/${val.offerId._id}`);
                                                            }
                                                        }}

                                                    >
                                                        {val?.meta_data?.offer_name ? val?.meta_data?.offer_name : "null"}
                                                    </td>
                                                    <td>{val?.earning_type == 1 ? "spinWheel" : val?.earning_type == 2 ? "SC Login" : val?.earning_type == 3 ? "singup" : val?.earning_type == 4 ? "Weekly" : val?.earning_type === 5 ? "SC Referal" : val?.earning_type === 6 ? "LeaderBoard Prize" : val?.earning_type === 7 ? "Affiliate Earning" : val?.earning_type === 8 ? "OfferWall Unlock" : val?.earning_type === 9 ? "Giveaways" : "null"}</td>
                                                    <td onClick={() => navigate(`/partners/${val?.partnerId?._id}`)}>{val?.meta_data?.partner_name ? val?.meta_data?.partner_name : "null"}</td>
                                                    <td>{val?.statusType === 1 ? "Credited" : val?.statusType === 2 ? "Debited" : val?.statusType === 3 ? "Pending" : val?.statusType === 4 ? "Invalid" : "Null"}</td>
                                                    <td>{moment.utc(val?.time).local().format("MMM Do YY, h:mm:ss a")}</td>
                                                </tr>
                                            </tbody>
                                        )
                                    })
                                    }
                                </table>
                            </div>

                            <div>
                                <Pagination
                                    filterDataInput={filterDataInput}
                                    setFilterDataInput={setFilterDataInput}
                                    totalPage={totalPage}
                                />
                            </div>

                            {/* <div className='userTablePagination'>
                                <label>Rows per page:</label>
                                <select value={filterDataInput.limit} onChange={(e) => setFilterDataInput({ ...filterDataInput, limit: Number(e.target.value), page: 0 })}>
                                    <option value={10}>10</option>
                                    <option value={50}>50</option>
                                    <option value={100}>100</option>
                                    <option value={500}>500</option>
                                    <option value={1000}>1000</option>
                                    <option value={1500}>1500</option>
                                </select>


                                <span className='currentPagespan'> Current Page {filterDataInput?.page + 1}/{Math.ceil(totalPage / filterDataInput?.limit)} </span>
                                {
                                    filterDataInput?.page > 0 &&
                                    (<button className='paginationButton' onClick={() => setFilterDataInput({ ...filterDataInput, page: Number(filterDataInput.page - 1) })} style={{ backgroundColor: "#2b579a" }}>
                                        Previous
                                    </button>)
                                }


                                {filterDataInput.page < Math.ceil(totalPage / filterDataInput.limit) && (
                                    <button className='paginationButton'
                                        onClick={() => {
                                            setFilterDataInput({ ...filterDataInput, page: Number(filterDataInput.page + 1) })
                                        }}
                                        disabled={filterDataInput.page + 1 >= totalPage / filterDataInput.limit}
                                    >
                                        Next
                                    </button>
                                )}
                            </div> */}

                        </div>
                        : <h1>No Data Found</h1>
                    }

                    </> :
                        <>
                            <div className='loaderImage'>
                                <img src={loader} alt='loader' />
                            </div>
                        </>
                    }
                </div>
            </div>
        </>
    )
}

export default Transaction2