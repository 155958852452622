import React from 'react'

const ColorPickar = ({colors ,setColors , heading}) => {

    // const [color1, setColor1] = useState("#2b579a")
    // const [color2, setColor2] = useState("#2b579a")

    const handleColorChange1 = (event) => {
        setColors({...colors,color1:event.target.value});
    };
    const handleColorChange2 = (event) => {
        setColors({...colors , color2:event.target.value});
    };
    return (
        <>
            <div className='color_piker'>
                <div className='picker-section'>
                    <label>
                        {heading}
                    </label>
                    <div className='picker-section-color'>

                        <label htmlFor="colorPicker">Choose a color1:</label>
                        <input
                            type="color"
                            id="colorPicker"
                            name="colorPicker"
                            value={colors?.color1}
                            onChange={handleColorChange1}
                        />
                    </div>
                    <div color='picker-section-color'>
                        <label htmlFor="colorPicker2">Choose a color2:</label>
                        <input
                            type="color"
                            id="colorPicker2"
                            name="colorPicker2"
                            value={colors?.color2}
                            onChange={handleColorChange2}
                        />

                    </div>
                </div>

                <div style={{ background: "red", background: `linear-gradient(to right , ${colors?.color1},${colors?.color2})` }} className='linear_gradient'>
                </div>
            </div>
        </>
    )
}

export default ColorPickar
