import React, { useState } from 'react'
import "../assets/css/Login.css"
import logo from "../assets/images/DarkLogo.png"
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useNavigate } from 'react-router-dom';
import axiosClient from '../axios';
function Login() {
    const [isPasswordShown, setIsPasswordShown] = useState(false);
    const navigate = useNavigate()
    const [formData, setFormData] = useState({
        adminUsername: "",
        password: "",
    });

    const [loading, setLoading] = useState(false)

    const submitForm = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const loginResponse = await axiosClient.post("/adminLogin", formData);
            if (!loginResponse.data.status) {
                alert(loginResponse.data.message);
                setLoading(false);
                return;
            }
            const jwt = loginResponse.data.jwt;
            const admin = loginResponse.data.response;
            localStorage.setItem("JWT", JSON.stringify(jwt));
            localStorage.setItem("admin", JSON.stringify(admin));

            window.location.replace("/");
        } catch (error) {
            alert("Error occurred:", error)
            console.error("Error occurred:", error);
            setLoading(false);
        }
    };


    const showPassword = () => setIsPasswordShown((prev) => !prev);
    return (
        <section className='section loginSection'>
            <div className='loginBox'>
                <img src={logo} alt="logo" />

                <div className='loginHeading'>
                    <h6>Login</h6>
                    <h5>Prorewards Panel</h5>
                </div>
                <div className='inputBox'>
                    <div className='inputCover'>
                        <input
                            placeholder={`Email`}
                            value={formData?.adminUsername}
                            onChange={(event) => {
                                setFormData({ ...formData, adminUsername: event.target.value });
                            }}
                            type="text"
                        />
                    </div>

                    <div className="inputCover">
                        <input
                            type={isPasswordShown ? "text" : "password"}
                            id="password"
                            placeholder={`PassWord`}
                            value={formData?.password}
                            onChange={(event) => {
                                setFormData({ ...formData, password: event.target.value });
                            }}
                        />
                        <button
                            className={`form-group-button${isPasswordShown ? " active" : ""}`}
                            onClick={showPassword}
                            type="button">
                            {isPasswordShown ? <VisibilityOffIcon /> : <VisibilityIcon />}
                        </button>
                    </div>

                    {/* <button type="submit" onClick={submitForm} className="submit">
                        Submit
                    </button> */}


                    <button
                        type="submit"
                        onClick={submitForm}
                        className="submit"
                        disabled={loading} // Disable button when loading
                    >
                        {loading ? (
                            <div className="spinner"></div> // Your spinner component or element
                        ) : (
                            "Submit"
                        )}
                    </button>
                </div>


            </div>
        </section>
    )
}

export default Login