import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import axiosClient from '../axios';
import loader from '../assets/images/loader.gif'
import SideBar from './SideBar'
import Slide from '@mui/material/Slide';
import Dialog from '@mui/material/Dialog';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function PartnerOffer() {
    const { id } = useParams();
    const [partner, setPartner] = useState({});
    const [offers, setOffers] = useState([]);
    const [skip, setSkip] = useState(0);
    const [isLoading, setIsLoading] = useState(true)
    const [open, setOpen] = useState(false)
    const [multiReward, setMultiReward] = useState(0)

    const [allCountry, setAllCountry] = useState([])

    const getPartners = () => {
        axiosClient
            .get(`/partner/${id}`)
            .then((res) => {
                console.log("res==>", res)
                setPartner(res.data.partnerData)
            })
            .catch((error) =>
                console.log(error)
            );
    }



    function fetchStaticOffer(e) {
        setIsLoading(false);
        // Check if the id is "64cd3f9637daab22e2a480ab"
        const baseUrl = `/fetchPartnerOffer/${id}?limit=100&skip=${e}`;
        const url = id === "64cd3f9637daab22e2a480ab" ? `${baseUrl}&type=${multiReward}` : baseUrl;

        axiosClient
            .get(url)
            .then((res) => {
                setOffers(res?.data?.response);
            })
            .catch((error) => console.log(error))
            .finally(() => {
                setIsLoading(true);
            });
    }


    const handleDelete = () => {
        setOpen(false)
        setAllCountry([])
    }


    useEffect(() => {
        getPartners();
    }, [])

    return (
        <>
            <SideBar handleSideBar={true} activeTab={"staticApi"} />
            <div className="screen">
                <div className='divimage'>
                    <div className='dBlock partnerOfferDblock'>
                        <h2 className='screenHeading catHead'>Partner Details</h2>
                        <p className='catPara'><span>Name</span> : {partner?.name}</p>
                        <p className='catPara'><span>Is Active</span> : {partner?.is_deleted ? "Not Active" : "Active"}</p>

                    </div>
                    <div className='partnerImage'><img src={partner?.image} alt="" /></div>
                </div>

                <div className='profileInfoBox table'>
                    <div className='popular'>
                        <div><h5>All Featured Offers</h5></div>

                        {(id == "64cd3f9637daab22e2a480ab") && <div className='editCover'>
                            <p>
                                Multi Reward
                            </p>
                            <div className="pRelative">
                                <input
                                    type="radio"
                                    checked={multiReward === 1 ? true : false}
                                    className="rounded"
                                    value={1}
                                    onChange={(e) => {
                                        setMultiReward(1)
                                    }}
                                />
                                <p className='inline'>True</p>
                                <input
                                    type="radio"
                                    checked={multiReward === 0 ? true : false}
                                    className="rounded"
                                    value={0}
                                    onChange={(e) => {
                                        setMultiReward(0)
                                    }}
                                />
                                <p className='inline'>False</p>
                            </div>
                        </div>}

                        <div className='tSubnitButton' onClick={() => fetchStaticOffer(0)} >Fetch Offer</div>
                    </div>

                    {
                        isLoading ? (

                            offers?.length > 0 && id == "64b6b83cda95aa30374a38bd" ?
                                <>
                                    <div className='profileInfoBox table'>
                                        <div className='tableData'>
                                            {offers?.map((ele) => {
                                                return <>
                                                    <div className='offerData'>

                                                        <p>Name : {ele?.name}</p>
                                                        <p>id : {ele.id}</p>
                                                        <p>click_url : {ele.click_url}</p>
                                                        <p>preview_url : {ele.preview_url}</p>
                                                        <p>requirements : {ele.requirements}</p>
                                                        <div className='flexBox'>
                                                            <div>events :</div>
                                                            <div>
                                                                {ele.events.map((e) => {

                                                                    return <>   <div className='border'>
                                                                        <p>id : {e.id}</p>
                                                                        <p>multiple_conversions_allowed : {e.multiple_conversions_allowed}</p>
                                                                        <p>level : {e.name}</p>
                                                                        <p>payout : {e.payout}</p>
                                                                        <p>payout_type : {e.payout_type}</p>
                                                                    </div>
                                                                    </>
                                                                })}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            })}
                                        </div>
                                    </div>
                                </> :
                                offers?.length > 0 && id == "64e46f26b58831a1376751d9" ?
                                    <>
                                        <div className='profileInfoBox table'>
                                            <div className='tableData'>
                                                {offers?.map((ele) => {
                                                    return <>
                                                        <div className='offerData'>
                                                            <p>Name : {ele.name}</p>
                                                            <p>id : {ele._id}</p>
                                                            <p>tracking_link : {ele.tracking_link}</p>
                                                            <p>platforms : {ele.platforms}</p>
                                                            <p>currency_amount : {ele.currency_amount}</p>
                                                            <p>payout_usd: {ele.payout_usd}</p>
                                                            <p>conversion_instructions : {ele.conversion_instructions}</p>
                                                            <p>conversion_instructions_short :{ele.conversion_instructions_short}</p>
                                                            <p>offer id:{ele.id}</p>

                                                            <div className='flexBox'>
                                                                <div>platforms :</div>
                                                                <div>
                                                                    {ele?.platforms?.map((e) => {
                                                                        return <>   <div className='border'>
                                                                            <p>id : {e}</p>

                                                                        </div>
                                                                        </>
                                                                    })}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                })}
                                            </div>
                                        </div>
                                    </>
                                    :
                                    offers?.length > 0 && id == "64b6a3ed0e67f6fe9d9f9816" ?
                                        <>
                                            <div className='profileInfoBox table'>
                                                <div className='tableData'>
                                                    {offers?.map((ele) => {
                                                        return <>
                                                            <div className='offerData'>
                                                                <p>Name : {ele.name}</p>
                                                                <p>categories : {ele.categories}</p>
                                                                <p>tracking_link : {ele.link}</p>
                                                                <p>conversionRate : {ele.conversionRate}</p>
                                                                <p>platforms : {ele.devices}</p>
                                                                <p>currency_amount : {ele.currencyReward}</p>
                                                                <p>revenue: {ele.revenue}</p>
                                                                <p>image : {ele.image}</p>
                                                                <p>offerID :{ele.offerID}</p>
                                                                <p>description :{ele.description}</p>
                                                                {/* <div className='flexBox'>
                                                                    <div>platforms :</div>
                                                                    <div>
                                                                        {ele?.platforms?.map((e) => {
                                                                            return <>   <div className='border'>
                                                                                <p>id : {e}</p>

                                                                            </div>
                                                                            </>
                                                                        })}
                                                                    </div>
                                                                </div> */}
                                                            </div>
                                                        </>
                                                    })}
                                                </div>
                                            </div>
                                        </> :
                                        offers?.length > 0 && id == "64b6dd1702d4fb1c0b54b90d" ?
                                            <>
                                                <div className='profileInfoBox table'>
                                                    <div className='tableData'>
                                                        {offers?.map((ele) => {
                                                            return <>
                                                                <div className='offerData'>
                                                                    <p>Name : {ele.name}</p>
                                                                    <p>categories : {ele.categories + " "}</p>
                                                                    <p>things_to_know : {ele.things_to_know}</p>
                                                                    <p>anchor : {ele.anchor}</p>

                                                                    <p>requirements : {ele.requirements}</p>
                                                                    <p>disclaimer : {ele.disclaimer}</p>
                                                                    {/* <p>translations: {ele?.translations}</p> */}
                                                                    <p>
                                                                        events:
                                                                        {ele.events.map((item, index) => (
                                                                            <ul key={index}>
                                                                                <li>Name:{item?.name}</li>
                                                                                <li>Payout:{item?.payout}</li>
                                                                            </ul>
                                                                        ))}
                                                                    </p>
                                                                    <p>description :{ele.description}</p>
                                                                    <p>Mobile Rank : {ele.country_stats[0].mobile_rank}</p>
                                                                    <p>Desktop Rank : {ele.country_stats[0].desktop_rank}</p>
                                                                    <p>click_url : {ele.click_url}</p>
                                                                    <p>support_url : {ele.support_url}</p>
                                                                    <p>preview_url : {ele.preview_url}</p>
                                                                    <p>offer id : {ele.id}</p>
                                                                </div>
                                                            </>
                                                        })}
                                                    </div>
                                                </div>
                                            </> :
                                            offers?.length > 0 && id == "64bc36a064d4042fbd2144d9" ?
                                                <>
                                                    <div className='profileInfoBox table'>
                                                        <div className='tableData'>
                                                            {offers?.map((ele) => {
                                                                return <>
                                                                    <div className='offerData'>
                                                                        <p>Name : {ele.name}</p>
                                                                        <p>categories : {ele.categories + " "}</p>
                                                                        <p>devices : {ele.devices}</p>
                                                                        <p>conversionRate : {ele.conversionRate}</p>
                                                                        <p>platforms : {ele.platforms}</p>
                                                                        <p>OS : {ele.os}</p>
                                                                        <p>android_os_version_min: {ele.android_os_version_min}</p>
                                                                        <p>android_os_version_max : {ele.android_os_version_max}</p>
                                                                        <p>description1 :{ele.description1}</p>
                                                                        <p>description2 :{ele.description2}</p>
                                                                        <p>description3 :{ele.description3}</p>
                                                                        <p>payout :{ele.payout}</p>
                                                                        <p>click_url :{ele.click_url}</p>
                                                                        <p>image_url :{ele.image_url}</p>
                                                                    </div>
                                                                </>
                                                            })}
                                                        </div>
                                                    </div>
                                                </> :
                                                offers?.length > 0 && id == "64b698320e67f6fe9d9f97c1" ?
                                                    <>
                                                        <div className='profileInfoBox table'>
                                                            <div className='tableData'>
                                                                {offers?.map((ele) => {
                                                                    return (
                                                                        <div className='offerData' key={ele.offer_id}>
                                                                            <p>OfferId: {ele.offer_id}</p>
                                                                            <p>Name: {ele.name}</p>
                                                                            <p>Adwall Description: {ele.adwall_description}</p>
                                                                            <p>Preview URL:{ele.preview_url}</p>
                                                                            <p>Conversion Point: {ele.conversion_point}</p>
                                                                            <p>Payout: {ele.payout}</p>
                                                                            <p>Click URL:{ele.click_url}</p>
                                                                            <p>Product Id:{ele.product_id.join(', ')}</p>
                                                                            <p>Category Id :{ele.category_id.join(', ')}</p>
                                                                            {/* <p>Countries: {ele.countries.join(', ')}</p> */}

                                                                            <p >
                                                                                <span className='offer-para-val'>Country</span>:
                                                                                {
                                                                                    ele.countries?.length > 3 ?
                                                                                        (
                                                                                            <>
                                                                                                {ele.countries.slice(0, 3).map((item, index) => (
                                                                                                    <React.Fragment key={item._id}>
                                                                                                        {item}
                                                                                                        {index !== ele.countries?.length - 1 && ', '}
                                                                                                    </React.Fragment>
                                                                                                ))}
                                                                                                {'... '}
                                                                                                <button onClick={() => { setAllCountry(ele.countries); setOpen(true) }}>show More</button>
                                                                                            </>
                                                                                        )
                                                                                        :
                                                                                        ele?.countries?.map((item, index) => (
                                                                                            <React.Fragment key={item._id}>
                                                                                                {item}
                                                                                                {index !== ele.countries?.length - 1 && ', '}
                                                                                            </React.Fragment>
                                                                                        ))
                                                                                }
                                                                            </p>



                                                                            <p>events: {ele.events.map((item, i) => {
                                                                                return (
                                                                                    <>
                                                                                        <p>event_id :{item?.event_id}</p>
                                                                                        <p>event_name :{item?.event_name}</p>
                                                                                        <p>event_revenue :{item?.event_revenue}</p>
                                                                                    </>
                                                                                )

                                                                            })}</p>
                                                                        </div>
                                                                    );
                                                                })}
                                                            </div>
                                                        </div>
                                                    </> :
                                                    offers?.length > 0 && id == "65211d0900e390d4a5112573" ?
                                                        <>
                                                            <div className='profileInfoBox table'>
                                                                <div className='tableData'>
                                                                    {offers?.map((ele) => {
                                                                        return (
                                                                            <div className='offerData' key={ele.id}>
                                                                                <p>ID: {ele.id}</p>
                                                                                <p>Name: {ele.name}</p>
                                                                                <p>Description: {ele.description?.en}</p>
                                                                                <p>Total Payout: {ele.totalPayout}</p>
                                                                                <p>Multi Event: {ele.multiEvent ? "True" : "False"}</p>
                                                                                <p>Sub Category: {ele.subCategory.join(', ')}</p>
                                                                                <p>Tracking Type: {ele.trackingType}</p>
                                                                                <p>Preview URL:{ele.previewUrl}</p>
                                                                                <p>Daily Conversion Cap: {ele.dailyConversionCap}</p>
                                                                                <p>EPC: {ele.epc}</p>
                                                                                <p>URL:{ele.url}</p>
                                                                                <p>Device: {ele.device.join(', ')}</p>
                                                                                <p>Popularity: {ele.popularity}</p>
                                                                                {/* <p>Countries: {ele.country.join(', ')}</p> */}
                                                                                <p>
                                                                                    <span className='offer-para-val'>Country</span>:
                                                                                    {
                                                                                        ele.country?.length > 3 ?
                                                                                            (
                                                                                                <>
                                                                                                    {ele?.country.slice(0, 3).map((item, index) => (
                                                                                                        <React.Fragment key={item._id}>
                                                                                                            {item}
                                                                                                            {index !== ele.country?.length - 1 && ', '}
                                                                                                        </React.Fragment>
                                                                                                    ))}
                                                                                                    {'... '}
                                                                                                    <button onClick={() => { setAllCountry(ele?.country); setOpen(true) }}>show More</button>
                                                                                                </>
                                                                                            )
                                                                                            :
                                                                                            ele?.country?.map((item, index) => (
                                                                                                <React.Fragment key={item._id}>
                                                                                                    {item}
                                                                                                    {index !== ele.country?.length - 1 && ', '}
                                                                                                </React.Fragment>
                                                                                            ))
                                                                                    }
                                                                                </p>
                                                                                {/* <p>Icon:</p>
                                                                            <img src={ele.icon} alt={ele.name} width="100" height="100" /> */}

                                                                                <p>Events:</p>
                                                                                {ele.events.map((event) => (
                                                                                    <div key={event.id}>
                                                                                        <p>Event ID: {event.eventId}</p>
                                                                                        <p>Action: {event.action?.en}</p>
                                                                                        <p>Payout: {event.payout}</p>
                                                                                    </div>
                                                                                ))}
                                                                            </div>

                                                                        );
                                                                    })}
                                                                </div>
                                                            </div>
                                                        </> :
                                                        offers?.length > 0 && id == "64cd3f9637daab22e2a480ab" ?
                                                            <>
                                                                <div className='profileInfoBox table'>
                                                                    <div className='tableData'>
                                                                        {offers?.map((ele) => {
                                                                            return (
                                                                                <>
                                                                                    <div className='offerData'>
                                                                                        <p>Title : {ele.title}</p>
                                                                                        <p>Level : {ele.level}</p>
                                                                                        <p>Stars : {ele.stars}</p>
                                                                                        <p>Categories : {ele?.categories?.join(', ')}</p>
                                                                                        {/* <p>Countries : {ele?.countries?.join(', ')}</p> */}
                                                                                        <p >
                                                                                            <span className='offer-para-val'>Country</span>:
                                                                                            {
                                                                                                ele.countries?.length > 3 ?
                                                                                                    (
                                                                                                        <>
                                                                                                            {ele.countries.slice(0, 3).map((item, index) => (
                                                                                                                <React.Fragment key={item._id}>
                                                                                                                    {item}
                                                                                                                    {index !== ele.countries?.length - 1 && ', '}
                                                                                                                </React.Fragment>
                                                                                                            ))}
                                                                                                            {'... '}
                                                                                                            <button onClick={() => { setAllCountry(ele.countries); setOpen(true) }}>show More</button>
                                                                                                        </>
                                                                                                    )
                                                                                                    :
                                                                                                    ele?.countries?.map((item, index) => (
                                                                                                        <React.Fragment key={item._id}>
                                                                                                            {item}
                                                                                                            {index !== ele.countries?.length - 1 && ', '}
                                                                                                        </React.Fragment>
                                                                                                    ))
                                                                                            }
                                                                                        </p>

                                                                                        <p>Devices : {ele?.devices?.join(', ')}</p>
                                                                                        <p>Description : {ele.description}</p>
                                                                                        <p>Image URL : {ele.image}</p>
                                                                                        <p>Payout : {ele.payout}</p>
                                                                                        <p>Payment Term : {ele.payment_term}</p>
                                                                                        <p>Android Minimum OS : {ele.android_min_os || 'N/A'}</p>
                                                                                        <p>iOS Minimum OS : {ele.ios_min_os || 'N/A'}</p>
                                                                                        <p>Offer URL : {ele.url}</p>
                                                                                    </div>
                                                                                </>
                                                                            );
                                                                        })}
                                                                    </div>
                                                                </div>

                                                            </> : null
                        )
                            :
                            <>
                                <div className='loaderImage'>
                                    <img src={loader} alt='loader' />
                                </div>
                            </>
                    }
                </div>
                <button className='nextPageButton' onClick={() => fetchStaticOffer(skip + 100)}>NextPage</button>
            </div>



            <Dialog
                open={open}
                onClose={() => handleDelete()}
                TransitionComponent={Transition}
                className="dialogs"
            >
                <div className='categoryEditBox'>
                    {
                        allCountry?.map((item, index) => (
                            <React.Fragment key={item._id}>
                                {item}
                                {index !== allCountry?.length - 1 && ', '}
                            </React.Fragment>))
                    }

                </div>
            </Dialog>

        </>
    )
}

export default PartnerOffer