import React, { useEffect, useState } from 'react'
import "../assets/css/SideBar.css"
import logo from "../assets/images/DarkLogo.png"
import { ReactComponent as Config } from "../assets/images/config.svg"
import { ReactComponent as Arrow } from "../assets/images/arrow.svg"
import { ReactComponent as Home } from "../assets/images/home.svg"
import { ReactComponent as Categories } from "../assets/images/categories.svg"
import { ReactComponent as Voucher } from "../assets/images/voucher.svg"
import { useNavigate } from 'react-router-dom'

function SideBar({ handleSideBar, activeTab }) {
    const adminArray = JSON.parse(localStorage.getItem("admin"));
    const Role = adminArray?.role
    const [isSiderbarOpen, setIsSiderbarOpen] = useState(true);
    const navigate = useNavigate()
    const userInfo = JSON.parse(localStorage.getItem("admin"));
    useEffect(() => {
        handleSideBar ? setIsSiderbarOpen(false) : setIsSiderbarOpen(false)
    }, [handleSideBar])

    const toggleSidebar = () => {
        setIsSiderbarOpen(!isSiderbarOpen);
    };

    return (
        <>
            <div className='hamburgerParentDiv'>
                <div className='hamburger' onClick={toggleSidebar}>{isSiderbarOpen ? "╳" : "☰"}</div>
            </div>
            <div className={`sidebar ${isSiderbarOpen ? "active" : ' '}`}>
                <div className='logoBox'>
                    <img src={logo} alt="logo" />
                </div>
                <h5 className='mainHead'>MAIN MENU</h5>
                <div className='menuCover'>
                    <div className={activeTab === "Home" || activeTab === "" ? "sideMenu active" : "sideMenu"} onClick={() => navigate('/Home')}>
                        <Home className='config' />
                        <p className='menuName'>Home</p>
                    </div>
                    <div className={activeTab === "users" ? "sideMenu active" : "sideMenu"} onClick={() => navigate('/users')}>
                        <Config className='config' />
                        <p className='menuName'>Users</p>
                    </div>
                    {Role != 'viewer' ? <div className={activeTab === "partners" ? "sideMenu active" : "sideMenu"} onClick={() => navigate('/partners')}>
                        <Voucher className='config' />
                        <p className='menuName'>Partners</p>
                    </div> : ""}
                    {Role != 'viewer' ? <div className={activeTab === "categories" ? "sideMenu active" : "sideMenu"} onClick={() => navigate('/categories')}>
                        <Categories className='config' />
                        <p className='menuName'>Catgeories</p>
                    </div> : ""}
                    {Role != 'viewer' ? <div className={activeTab === "offer" ? "sideMenu active" : "sideMenu"} onClick={() => navigate('/offer')}>
                        <Voucher className='config' />
                        <p className='menuName'>Add Offers</p>
                    </div> : ""}
                    {Role != 'viewer' ? <div className={activeTab === "allOffer" ? "sideMenu active" : "sideMenu"} onClick={() => navigate('/allOffers')}>
                        <Voucher className='config' />
                        <p className='menuName'>All Offers</p>
                    </div> : ""}
                    {Role != 'viewer' ? <div className={activeTab === "addBlog" ? "sideMenu active" : "sideMenu"} onClick={() => navigate('/addBlog')}>
                        <Voucher className='config' />
                        <p className='menuName'>Blogs</p>
                    </div> : ""}
                    {Role != 'viewer' ? <div className={activeTab === "allblogs" ? "sideMenu active" : "sideMenu"} onClick={() => navigate('/allblogs')}>
                        <Voucher className='config' />
                        <p className='menuName'>All Blogs</p>
                    </div> : ""}
                    <div className={activeTab === "transaction" ? "sideMenu active" : "sideMenu"} onClick={() => navigate('/transaction')}>
                        <Voucher className='config' />
                        <p className='menuName'>Transactions</p>
                    </div>
                    {Role === 'superAdmin' ? <div className={activeTab === "grossData" ? "sideMenu active" : "sideMenu"} onClick={() => navigate('/grossData')}>
                        <Voucher className='config' />
                        <p className='menuName'>Gross Data</p>
                    </div> : ""}
                    {Role === 'superAdmin' ? <div className={activeTab === "admins" ? "sideMenu active" : "sideMenu"} onClick={() => navigate('/admins')}>
                        <Voucher className='config' />
                        <p className='menuName'>Admins</p>
                    </div> : ""}

                    {Role != 'viewer' ? <div className={activeTab === "staticApi" ? "sideMenu active" : "sideMenu"} onClick={() => navigate('/staticOffers')}>
                        <Voucher className='config' />
                        <p className='menuName'>StaticOfferApi</p>
                    </div> : ""}

                    <div className={activeTab === "Leaderboard" ? "sideMenu active" : "sideMenu"} onClick={() => navigate('/leaderboard')}>
                        <Voucher className='config' />
                        <p className='menuName'>Leaderboard</p>
                    </div>

                    <div className={activeTab === "pendingEarning" ? "sideMenu active" : "sideMenu"} onClick={() => navigate('/pendingEarning')}>
                        <Voucher className='config' />
                        <p className='menuName'>Pending Earning</p>
                    </div>



                    {Role != 'viewer' ? <div className={activeTab === "paypal" ? "sideMenu active" : "sideMenu"} onClick={() => navigate('/paypal')}>
                        <Voucher className='config' />
                        <p className='menuName'>PayPal</p>
                    </div> : ""}

                    {/* <div className={activeTab === "notification" ? "sideMenu active" : "sideMenu"} onClick={() => navigate('/notification')}>
                        <Voucher className='config' />
                        <p className='menuName'>Notification</p>
                    </div> */}

                    <div className={activeTab === "challenges" ? "sideMenu active" : "sideMenu"} onClick={() => navigate('/allchallenge')}>
                        <Voucher className='config' />
                        <p className='menuName'>All Challenge</p>
                    </div>

                    <div className={activeTab === "miltipageData" ? "sideMenu active" : "sideMenu"} onClick={() => navigate('/allmultiPageData')}>
                        <Voucher className='config' />
                        <p className='menuName'>Multi Page Data</p>
                    </div>

                    <div className={activeTab === "referstat" ? "sideMenu active" : "sideMenu"} onClick={() => navigate('/referlinkstat')}>
                        <Voucher className='config' />
                        <p className='menuName'>Refer Link Page</p>
                    </div>
                    <div className={activeTab === "configuration" ? "sideMenu active" : "sideMenu"} onClick={() => navigate('/configurationInfo')}>
                        <Voucher className='config' />
                        <p className='menuName'>Configuration</p>
                    </div>

                    <div className={activeTab === "metaconfig" ? "sideMenu active" : "sideMenu"} onClick={() => navigate('/metaconfig')}>
                        <Voucher className='config' />
                        <p className='menuName'>MetaConfig</p>
                    </div>

                </div>
                <div className='proflieBox'>
                    <div className={`sideMenu profile ${activeTab === "profile" ? "active" : null}`} onClick={() => navigate('/profile')}>
                        {/* <img src='https://lh3.googleusercontent.com/a/AEdFTp5sRO_Z6qI6JFM2mVdmqEMPEO8wEsSGSlx_e6m8=s96-c' alt='profliPic' referrerPolicy='no-referrer' /> */}
                        <p className='adminName'>{userInfo?.adminUsername?.slice(0, 1)?.toUpperCase()}</p>
                        <Arrow className='arrow' />
                    </div>
                </div>
            </div>
        </>

    )
}

export default SideBar