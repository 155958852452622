import SideBar from './SideBar'
import React, { useEffect, useState } from 'react'
import axiosClient from '../axios';
import loader from "../assets/images/loader.gif";
import { useNavigate } from 'react-router-dom';

function EarningLeaderBoard() {
  const [data, setData] = useState([])
  const [selectedValue, setSelectedValue] = useState(1);
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate();

  const handleLeaderBoard = async (val) => {
    setLoading(true)
    await axiosClient
      .get(`/admin/leaderBoard?board=1&type=${val}`)
      .then((res) => {
        setData(res.data?.response?.leaderBoard)
      })
      .catch((err) => console.log("err", err))
      .finally(() => setLoading(false))
  }

  const handleChange = async (event) => {
    setSelectedValue(event.target.value);
  };

  useEffect(() => {
    handleLeaderBoard(selectedValue)
  }, [])

  return (
    <>

      <SideBar handleSideBar={true} activeTab={"Leaderboard"} />
      <div className="screen">
        <div className='dFlex'>
          <div className='earningleaderBoardType'>
            <h2 className='screenHeading catHead'>Earning Leaderboard</h2>
            <div className='earningleaderboadrdFilter'>
              <select id="dropdown" value={selectedValue} onChange={handleChange} className='leaderboardSelect'>
                <option value={1}>Daily</option>
                <option value={2}>Weekly</option>
                <option value={3}>Monthly</option>
                <option value={4}>All</option>
              </select>
              <button className='leaderboardSubmitV'
                onClick={() => handleLeaderBoard(selectedValue)}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
        <div className='profileInfoBox table'>
          {data?.length > 0 ?
            <>
              <table id="users">
                <thead>
                  <tr>
                    <th style={{ width: '20px' }}>S.NO.</th>
                    <th style={{ width: '50px' }}>UserName</th>
                    <th style={{ width: '100px' }}>Email Id</th>
                    <th style={{ width: '50px' }}>Rank</th>
                    <th style={{ width: '20px' }}>Total Earning</th>
                  </tr>
                </thead>
                {data?.map((val, key) => {
                  return (
                    <tbody key={key}>
                      <tr>
                        <td>{(key + 1)}</td>
                        <td className='clickabuetd' onClick={() => window.open(`/users/transaction/${val.userId}`)} >{val?.userName}</td>
                        <td>{val?.email}</td>
                        <td>{val?.rank}</td>
                        <td>{val?.totalEarnings.toFixed(2)}</td>
                      </tr>
                    </tbody>
                  )
                })
                }
              </table>
             
            </>
            : <p>
              NO Data Found
            </p>}
        </div>

        {loading ?

          <div className='loaderImage'>
            <img src={loader} alt='loader' />
          </div> : ""
        }
      </div>

    </>
  )
}

export default EarningLeaderBoard