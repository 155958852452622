import React, { useState, useEffect } from 'react'
import SideBar from './SideBar';
import loader from '../assets/images/loader.gif'
import { dateConvert } from '../dateConverter';

import Pagination from './Pagination';
import axiosClient from '../axios';
import moment from 'moment';
import { ReactComponent as Edit } from "../assets/images/edit.svg";
import { useNavigate } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const AllChallenge = () => {
    const [isLoading, setIsLoading] = useState(true)
    const [data, setData] = useState([])
    const navigate = useNavigate()
    const [disableTaskOpen, setDisableTaskOpen] = useState(false)
    const [openPopular, setOpenPopular] = useState(false)
    const [singleTask, setSingleTask] = useState({})
    const [pagination, setPagination] = useState({
        page: 0,
        limit: 100
    });
    const [activeTask, setActiveTask] = useState(1)

    const [totalPage, setTotalPage] = useState(10)
    const getAllChallenge = () => {
        setIsLoading(false)
        axiosClient
            .get(`/getAllTask?page=${pagination?.page}&limit=${pagination?.limit}&activeTask=${activeTask}`)
            .then((res) => {
                setData(res?.data?.response)
                setTotalPage(res?.data?.totalTask)
            })
            .catch((error) => console.log(error))
            .finally(() => setIsLoading(true))
    }

    const handleClose = () => {
        setDisableTaskOpen(false)
        setOpenPopular(false)
    }

    const handleEnabelDisableTask = () => {
        setIsLoading(false)
        setDisableTaskOpen(false)

        axiosClient.get(`/toogle/task/${singleTask?._id}`)
            .then((res) => {
                console.log("res", res)
                if (res?.status) {
                    alert(res?.data?.message)
                }
                getAllChallenge()
            })
            .catch((error) => {
                console.log(error)
            })
            .finally(() => setIsLoading(true))

    }

    const handPopularLask = () => {
        setIsLoading(false)
        setOpenPopular(false)

        axiosClient.put(`/popular/task/${singleTask?._id}`)
            .then((res) => {
                console.log("res", res)
                if (res?.status) {
                    alert(res?.data?.message)
                }
                getAllChallenge()
            })
            .catch((error) => {
                console.log(error)
            })
            .finally(() => setIsLoading(true))

    }

    useEffect(() => {
        getAllChallenge()
    }, [pagination.limit, pagination.page, activeTask])
    return (
        <>
            <SideBar handleSideBar={true} activeTab={"challenges"} />
            <div className="screen">

                <div className="add-task-chall">
                    <h2>All Challenge {totalPage}</h2>
                    <div className="challeng-active">
                        <select
                            name="taskSelect"
                            id="taskSelect"
                            defaultValue="" // Ensures "Select Task" is the default placeholder
                            onChange={(e) => {
                                const selectedValue = e.target.value;
                                console.log("selectedValue", typeof selectedValue)
                                setActiveTask(selectedValue);
                            }}
                        >
                            <option value="" disabled>Select Task</option> 
                            <option value={1}>All Task</option>
                            <option value={2}>Active Task</option>
                            <option value={3}>InActive Task</option>
                        </select>

                        {/* <button onClick={() => setActiveTask(true)}>Active Task</button> */}

                        <button onClick={() => navigate("/challenge")}>Add New Challenge</button>
                    </div>


                </div>
                <div className='profileInfoBox table'>
                    {isLoading ?
                        <>
                            <div style={{ width: '100%' }} className="container app-body">
                                {data?.length > 0 ? <>
                                    <div className="tabel-scrol">
                                        <table id="users">
                                            <thead>
                                                <tr>
                                                    <th>S.NO.</th>
                                                    <th>Name</th>
                                                    <th>payable Coins</th>
                                                    <th>duration</th>
                                                    <th>Task Start Time</th>
                                                    <th>Task End Time</th>
                                                    <th>Edit</th>
                                                    <th>Disable Task</th>
                                                    <th>User Info </th>
                                                    <th>Is Popular </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data?.map((val, key) => (
                                                    <tr key={key}>
                                                        <td>{(pagination.limit * pagination.page) + (key + 1)}</td>
                                                        {/* <td>{(key + 1)}</td> */}
                                                        <td>{val.taskName}</td>
                                                        <td>{val.payableCoins}</td>
                                                        <td>{val.duration}</td>
                                                        <td>{moment.utc(val.taskStartedAt).local().format("DD/MM/YY, h:mm:ss a")}</td>
                                                        <td>{moment.utc(val.taskEndedAt).local().format("DD/MM/YY, h:mm:ss a")}</td>
                                                        <td><Edit onClick={() => {
                                                            navigate(`/editchallenge/${val._id}`)
                                                        }} /></td>
                                                        <td><button className='disable-challenge'
                                                            style={{ cursor: 'pointer' }}
                                                            onClick={() => {
                                                                setSingleTask(val)
                                                                setDisableTaskOpen(true)
                                                            }}
                                                        >{val?.enableTask ? "Disable Task" : "Enable Task"}</button></td>

                                                        <td>
                                                            <button
                                                                style={{ cursor: 'pointer' }}
                                                                className='disable-challenge'
                                                                onClick={() => { navigate(`/oneUserTask/${val?._id}`) }}>User Info</button>
                                                        </td>

                                                        <td><button className='disable-challenge'
                                                            style={{ cursor: 'pointer' }}
                                                            onClick={() => {
                                                                setSingleTask(val)
                                                                setOpenPopular(true)
                                                            }}
                                                        >{val?.isPopular ? "Unpopular Task" : "Popular Task"}</button></td>
                                                    </tr>

                                                ))}
                                            </tbody>
                                        </table>

                                    </div>

                                    <div>
                                        <Pagination
                                            filterDataInput={pagination}
                                            setFilterDataInput={setPagination}
                                            totalPage={totalPage}
                                        />
                                    </div>

                                </> :
                                    <>
                                        <h4 className='h5Heading'>No Data Available</h4>
                                    </>}
                            </div>
                        </> :
                        <>
                            <div className='loaderImage'>
                                <img src={loader} alt='loader' />
                            </div>
                        </>
                    }
                </div>
            </div>

            <Dialog
                open={disableTaskOpen}
                onClose={() => handleClose()}
                TransitionComponent={Transition}
                className="dialogs"
            >
                <div className="task-success-message">
                    <h2>
                        Are You Sure , You Want to {singleTask?.enableTask ? "Disable Task" : "Enable Task"} This Task
                    </h2>

                    <div className='flex-disable'>
                        <button className='ok-cha-button' onClick={() => {
                            handleEnabelDisableTask()
                        }
                        }>Yes</button>

                        <button className='ok-cha-button' onClick={() => {
                            setDisableTaskOpen(false);
                        }
                        }>No</button>
                    </div>

                </div>
            </Dialog>

            <Dialog
                open={openPopular}
                onClose={() => handleClose()}
                TransitionComponent={Transition}
                className="dialogs"
            >
                <div className="task-success-message">
                    <h2>
                        Are You Sure , You Want to make  {singleTask?.isPopular ? "UnPopular" : "Popular"} This Task
                    </h2>

                    <div className='flex-disable'>
                        <button className='ok-cha-button' onClick={() => {
                            // handleEnabelDisableTask()
                            handPopularLask()

                        }
                        }>Yes</button>

                        <button className='ok-cha-button' onClick={() => {
                            setOpenPopular(false);
                        }
                        }>No</button>
                    </div>

                </div>
            </Dialog>

        </>
    )
}

export default AllChallenge
