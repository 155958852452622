
import SideBar from './SideBar'
import React, { useEffect, useState } from 'react'
import loader from "../assets/images/loader.gif";
import axiosClient from '../axios';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { ReactComponent as Edit } from "../assets/images/edit.svg";


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function WinnerLeaderBoard() {
  const [data, setData] = useState([])
  const [selectedValue, setSelectedValue] = useState(0);
  const [pastResult, setPastResult] = useState([]);
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(true)

  const [isWinningSent, setIsWinningSent] = useState(true)
  const [editPrice, setEditPrice] = useState(false)

  const [editPriceData, setEditPriceData] = useState({})

  const [priceIndex, setPriceIndex] = useState(0)



  let [winnerPriceData, setWinnerePriceData] = useState({
    multiple: false,
    start_rank: 0,
    end_rank: 0,
    price: 0
  })


  let [winnerData, setWinnerData] = useState([])


  const handleWinnerData = () => {
    let temp = []
    temp.push(winnerPriceData)
    setWinnerData([...winnerData, winnerPriceData])
    setWinnerePriceData({
      multiple: false,
      start_rank: 0,
      end_rank: 0,
      price: 0
    })
    setOpen(false)
  }
  const pastResultData = async () => {
    await axiosClient
      .get(`/admin/leaderBoard/pastResults?board=1`)
      .then((res) => {
        setPastResult(res?.data?.response?.getpastResults)
        setSelectedValue(res?.data?.response?.getpastResults[0]._id)
      })
      .catch(() => console.log("err"))

  }
  const sendWinnings = async () => {

    if (winnerData.length === 0) {
      alert("Please Add Price For Winners.")
      return
    }

    setLoading(false)
    await axiosClient
      .post(`/admin/leaderBoard/sendWinnings/${selectedValue}`, { data: winnerData })
      .then((res) => {
        console.log("response form price distrubuted", res)
        alert("Prize Distributed Successfully")
      })
      .catch((err) => console.log("err ", err))
      .finally(() => setLoading(true))
  }

  const handleLeaderBoard = async (val) => {
    try {
      setLoading(false)
      await axiosClient
        .get(`/admin/leaderBoard/winners/${val}`)
        .then((res) => {
          setLoading(true)
          setData(res?.data?.response?.getWinnings?.winners)
          setIsWinningSent(res?.data?.response?.getWinnings?.winnings_sent)
        })
    } catch (error) {
      console.log(error)
    }
  }

  const handleClose = () => {
    setOpen(false)
    setEditPrice(false)
  }

  const handleChange = async (event) => {
    setSelectedValue(event.target.value);
  };


  const handleWinnerPriceEdit = async () => {

    let updated = winnerData
    updated[priceIndex] = editPriceData
    setWinnerData(updated)

    setEditPriceData({})
    setEditPrice(false)
    setOpen(false);

  }

  useEffect(() => {
    pastResultData()
  }, [])



  return (
    <>

      <SideBar handleSideBar={true} activeTab={"Leaderboard"} />

      <div className="screen">
        <div className='dFlex'>
          {/* <h2 className='screenHeading catHead'>Winner Leaderboard</h2> */}

          <div className='earningleaderBoardType'>
            <div className='earningleaderboadrdFilter'>
              {/* {isWinningSent ? <div></div> :

                <button className='sendWinningButton'
                  onClick={() => sendWinnings()}
                >
                  Send Winings
                </button>
              } */}
              <div className='earningDropdown'>
                <select id="Winnerdropdown" value={selectedValue} onChange={handleChange} className='leaderboardSelect'>
                  {pastResult?.map((ele, index) => {
                    return <option key={index} value={ele?._id}>{`${ele?.contest_start_time?.slice(5, 10)} to ${ele?.contest_end_time?.slice(5, 10)}`}</option>

                  })}
                </select>
                <button className='leaderboardSubmitV'
                  onClick={() => handleLeaderBoard(selectedValue)}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
        {
          !isWinningSent &&
          <>
            <div className='addPrizes' onClick={() => setOpen(true)}>Add Prize</div>

         {winnerData?.length > 0 ? <h2 className='screenHeading catHead'>Prize Table</h2> : ""}


            <div className='profileInfoBox table'>
            <table id="users">
                 {winnerData?.length > 0 ? <thead>
                    <tr>
                      <th style={{ width: '20px' }}>S.NO.</th>
                      <th style={{ width: '50px' }}>Start Rank</th>
                      <th style={{ width: '50px' }}>End Rank</th>
                      <th style={{ width: '50px' }}>Price</th>
                      <th style={{ width: '20px' }}>Multiple</th>
                      <th style={{ width: '20px' }}>Edit</th>
                    </tr>
                  </thead> :""}
                  {winnerData?.length > 0 && winnerData?.map((e, i) => {
                    return (
                      <tbody key={i+1}>
                        <tr >
                          <td>{(i + 1)}</td>
                          <td>{e?.start_rank}</td>
                          <td>{e?.end_rank}</td>
                          <td>{e?.price}</td>
                          <td>{e?.multiple ? "True" : "False"}</td>
                          <td>
                          <Edit onClick={() => {
                        setOpen(true);
                        setEditPrice(true);
                        setEditPriceData(e);
                        setPriceIndex(i)
                      }}></Edit>
                          </td>
                        </tr>
                      </tbody>
                    )
                  })
                  }
                </table>
            </div>

            {winnerData?.length > 0 &&

               <div className='sendWinningdiv'>
                 <button className='sendWinningButton'
                  onClick={() => sendWinnings()}
                >
                  Send Winings
                </button>
               </div>
              }


            {/* <div className='rewardTable'>
              <div className='flexRow'>
                <h3>Start Rank</h3>
                <h3>End Rank</h3>
                <h3>Price</h3>
                <h3>Multiple</h3>
                <h3>Edit</h3>
              </div>
              {
                winnerData?.length > 0 && winnerData?.map((e, i) => {
                  return (<React.Fragment key={i}>
                    <div className='flexRow'>
                      <h3>{e?.start_rank}</h3>
                      <h3>{e?.end_rank}</h3>
                      <h3>{e?.price}</h3>
                      <h3>{e?.multiple ? "True" : "False"}</h3>
                      <h3><Edit onClick={() => {
                        setOpen(true);
                        setEditPrice(true);
                        setEditPriceData(e);
                        setPriceIndex(i)
                      }}></Edit></h3>
                    </div>
                  </React.Fragment>)
                })
              }
            </div> */}

          <h2 className='screenHeading catHead'>Winner Leaderboard</h2>



          </>
        }
        {loading ? <>
          <div className='profileInfoBox table'>
            {data?.length > 0 ?
              <>
                <table id="users">
                  <thead>
                    <tr>
                      <th style={{ width: '20px' }}>S.NO.</th>
                      <th style={{ width: '50px' }}>UserName</th>
                      <th style={{ width: '50px' }}>Phone Number</th>
                      <th style={{ width: '50px' }}>Rank</th>
                      <th style={{ width: '20px' }}>Total Earning</th>
                      <th style={{ width: '20px' }}>Prize</th>
                    </tr>
                  </thead>
                  {data?.map((val, key) => {
                    return (
                      <tbody key={key}>
                        <tr >
                          <td>{(key + 1)}</td>
                          <td className='clickabuetd' onClick={() => window.open(`/users/transaction/${val.userId}`)}>{val?.userName}</td>
                          <td >{val?.phoneNumber}</td>
                          <td>{val?.rank}</td>
                          <td>{parseInt(val?.totalEarnings || val?.totalRefers)}</td>
                          <td>{parseInt(val?.winnings)}</td>
                        </tr>
                      </tbody>
                    )
                  })
                  }
                </table>
              </>
              : <p>
                <h1>No Data Found</h1>
              </p>}
          </div>
        </>
          :
          <>
            <div className='loaderImage'>
              <img src={loader} alt='loader' />
            </div>
          </>

        }
      </div>

      <Dialog
        open={open}
        onClose={() => handleClose()}
        TransitionComponent={Transition}
        className="dialogs"
      >
        <div className='categoryEditBox'>
          <h3 className='addRew'> Send Winning </h3>
          <div className='editCover fullCover'>

            <div className='editCover fullCover'>
              <p>
                Muliple
              </p>
              <input
                type="radio"
                checked={editPrice ? editPriceData.multiple ? true : false : winnerPriceData.multiple == true ? true : false}
                className="rounded multiplefalse"
                value={true}
                onChange={(e) => {
                  editPrice ? setEditPriceData({ ...editPriceData, multiple: e.target.value == 'true' ? true : false }) : setWinnerePriceData({ ...winnerPriceData, multiple: e.target.value == 'true' ? true : false });

                }}
              />
              <label>true</label>

              <input
                type="radio"
                checked={editPrice ? editPriceData.multiple == false ? true : false : winnerPriceData.multiple == false ? true : false}
                className="rounded multiplefalse"
                value={false}
                onChange={(e) => {
                  editPrice ? setEditPriceData({ ...editPriceData, multiple: e.target.value == 'false' ? false : true }) : setWinnerePriceData({ ...winnerPriceData, multiple: e.target.value == 'false' ? false : true });

                }}

              />
              <label>false</label>
            </div>



            <div>
              <p>
                Start Rank
              </p>
              <input
                name="rank"
                placeholder={'Rank'}
                onChange={(e) => {
                  editPrice ? setEditPriceData({ ...editPriceData, start_rank: Number(e.target.value) }) : setWinnerePriceData({ ...winnerPriceData, start_rank: Number(e.target.value) });
                }}
                value={editPrice ? editPriceData.start_rank : winnerPriceData.start_rank}
                type="text"
                className="inputTag"
              />
            </div>

            {winnerPriceData && winnerPriceData.multiple ?

              <div>
                <p>
                  End Rank
                </p>
                <input
                  name="end rank"
                  placeholder={'End Rank'}
                  onChange={(e) => {
                    editPrice ? setEditPriceData({ ...editPriceData, end_rank: Number(e.target.value) }) : setWinnerePriceData({ ...winnerPriceData, end_rank: Number(e.target.value) });
                  }}
                  value={editPrice ? editPriceData.end_rank : winnerPriceData.end_rank}
                  type="text"
                  className="inputTag"
                />

              </div>
              :
              <></>
            }
            {editPriceData && editPriceData.multiple ?

              <div>
                <p>
                  End Rank
                </p>
                <input
                  name="end rank"
                  placeholder={'End Rank'}
                  onChange={(e) => {
                    editPrice ? setEditPriceData({ ...editPriceData, end_rank: Number(e.target.value) }) : setWinnerePriceData({ ...winnerPriceData, end_rank: Number(e.target.value) });
                  }}
                  value={editPrice ? editPriceData.end_rank : winnerPriceData.end_rank}
                  type="text"
                  className="inputTag"
                />

              </div>
              :
              <></>
            }
            <div>
              <p>
                Price
              </p>
              <input
                name="price"
                placeholder={'Price'}

                onChange={(e) => {
                  editPrice ? setEditPriceData({ ...editPriceData, price: Number(e.target.value) }) : setWinnerePriceData({ ...winnerPriceData, price: Number(e.target.value) });
                }}
                value={editPrice ? editPriceData.price : winnerPriceData.price}
                type="text"
                className="inputTag"
              />
            </div>

          </div>

          <div className='addButton' onClick={() => { editPrice ? handleWinnerPriceEdit() : handleWinnerData() }}>{editPrice ? "Edit" : "Add"}</div>
        </div>
      </Dialog>

    </>
  )
}

export default WinnerLeaderBoard