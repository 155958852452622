import React, { useEffect, useState } from 'react'
import SideBar from './SideBar'
import axiosClient from '../axios';
import { ReactComponent as Add } from "../assets/images/add.svg"
import { ReactComponent as Delete } from "../assets/images/delete.svg"
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { ReactComponent as Edit } from "../assets/images/edit.svg";
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import loader from '../assets/images/loader.gif'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const EditMultiPageData = () => {
    let {id} = useParams()
    const [open, setOpen] = useState(false)
    const [data, setData] = useState({})
    const [addHeading ,setAddHeading] = useState(false)
    const [loading ,setLoading] = useState(false)
    const [pagedata, setPageData] = useState({})
    const [rulesKey, setRuleKey] = useState([])
    const [ruleData, setRuleData] = useState({
        heading: "",
        text: ""
    })
    const [index, setIndex] = useState(0)
    const handleClose = () => {
        setOpen(false)
    }
    const navigate = useNavigate()



    const handlePageDataAdd = () => {
        if (!ruleData?.heading || !ruleData?.text) {
            alert("Both Field Mandatory")
            return
        }
        setRuleKey([...rulesKey, ruleData])
        setPageData({ ...pagedata, rulesKey: [...rulesKey, ruleData] })
        setData({ ...data, pagedata: { ...pagedata, rulesKey: [...rulesKey, ruleData] } })
        setRuleData({
            heading: "",
            text: ""
        })
        setOpen(false)
        setAddHeading(false)
    }




    const handleEditPageData = () => {
        if(!ruleData?.heading || !ruleData?.text){
            alert("Both Field Mandatory.")
            return 
        }
        let updataRule = [...rulesKey]
        updataRule[index] = ruleData
        setRuleKey(updataRule)
        setPageData({ ...pagedata, rulesKey: updataRule })
        setData({ ...data, pagedata: { ...pagedata, rulesKey: updataRule } })
        setRuleData({
            heading: "",
            text: ""
        })
        setOpen(false)
    }



    const handleDelete = (i) => {
        let deleteRule = [...rulesKey]
        deleteRule.splice(i, 1)
        setRuleKey(deleteRule)
        setPageData({ ...pagedata, rulesKey: deleteRule })
        setData({ ...data, pagedata: { ...pagedata, rulesKey: deleteRule } })
    }

  


    const handleEditMultiPageData = ()=>{
        if(!data?.pagename ||rulesKey?.length == 0){
            alert('Please Enter All Field Data')
            return
        }
        setLoading(true)
        axiosClient.post(`EditSingleMultiPageData?id=${id}`,data)
        .then((res)=>{
            console.log("res from Edit multiPage Data",res)
            if(res?.data?.status){
                alert(res?.data?.message)
                navigate("/allmultiPageData")
            }
        })
        .catch((err)=>console.log(err))
        .finally(()=>setLoading(false))
    }


    const getSingleMultiPageData = ()=>{
        setLoading(true)
        axiosClient.get(`getSingleMultiPageData?id=${id}`)
        .then((res)=>{
            setData(res.data.response)
            setRuleKey(res?.data.response?.pagedata?.rulesKey)
        })
        .catch((err)=>console.log(err))
        .finally(()=>setLoading(false))
    }

    useEffect(()=>{
        getSingleMultiPageData()
    },[])


    return (
        <>
            <SideBar handleSideBar={true} activeTab={"miltipageData"} />
           {loading ? 
            <>
            <div className='loaderImage'>
                <img src={loader} alt='loader' />
            </div>
        </>:
            <div className="screen">
                <h3>Multi Page Data</h3>
                <div className="multiPageData-container">
                    <div className="challenge-field">
                        <span className='challenge-heading'>Page Name</span>
                        <input
                            className='challenge-input'
                            type="text"
                            disabled
                            name='name'
                            onChange={(e) => {
                                setData({ ...data, pagename: e.target.value })
                            }}
                            placeholder='Enter Page Name'
                            value={data?.pagename}
                        />
                    </div>

                    <div className="multi-page-field">
                        <p className='multiPage-table-heading'>Add Multi Page Data<Add className='addReward' onClick={() => { setOpen(true) ;setAddHeading(true)}} /></p>
                        {(rulesKey?.length > 0) && <div className='multi-page-preview'>
                            <table className='multi-page-table'>
                                <thead>
                                    <th>Heading</th>
                                    <th>Text</th>
                                    <th>Edit</th>
                                    {/* <th>Delete</th> */}
                                </thead>
                                <tbody>

                                    {rulesKey.map((item, i) => {
                                        return (
                                            <tr key={i} >
                                                <td>
                                                    {item?.heading}
                                                </td>
                                                <td>
                                                    {item?.text}
                                                </td>
                                                <td><Edit
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => {
                                                        setOpen(true)
                                                        setRuleData(item)
                                                        setIndex(i)
                                                        // setEditable(true)
                                                    }}
                                                /></td>
                                                <td><Delete
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => {
                                                        handleDelete(i)
                                                    }} /></td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>


                        </div>}
                    </div>

                </div>


                <button
                    className='challenge-button'
                    onClick={() => { handleEditMultiPageData() }}
                >
                  Edit
                </button>

            </div>}

            <Dialog
                open={open}
                onClose={() => handleClose()}
                TransitionComponent={Transition}
                className="dialogs"
            >
                <div className='categoryEditBox multi-page-data'>
                    <div className='editCover fullCover offer-challenge'>
                        <p>
                            Heading
                        </p>
                        <input
                            name="page-heading"
                            placeholder={'Enter Heading'}
                            onChange={(e) => {
                                setRuleData({ ...ruleData, heading: e.target.value })
                            }}
                            type="text"
                            value={ruleData.heading}
                            className="multipage-input"
                        />
                    </div>
                    <div className='editCover fullCover offer-challenge'>
                        <p>
                            Text
                        </p>
                        <input
                            name="pagenamee"
                            placeholder={'Enter  text'}
                            onChange={(e) => {
                                setRuleData({ ...ruleData, text: e.target.value })
                            }}
                            type="text"
                            value={ruleData.text}
                            className="multipage-input"
                        />
                    </div>

                    <div className='addButton' onClick={() => {addHeading ? handlePageDataAdd(): handleEditPageData()  }} >{addHeading ? "Add" : "Edit"}</div>
                </div>
            </Dialog>
        </>
    )
}

export default EditMultiPageData
