import React, { useCallback, useEffect, useState } from 'react'
import SideBar from './SideBar'
import { useNavigate } from 'react-router-dom';
import axiosClient from '../axios';
import moment from 'moment';
import Pagination from './Pagination';
import { ReactComponent as Config } from "../assets/images/config.svg"
import { ReactComponent as Cross } from "../assets/images/cross.svg"
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { TextField } from '@mui/material';
import '../assets/css/userTable.css'
import { dateConvert } from '../dateConverter';
import loader from '../assets/images/loader.gif'
import { Autocomplete } from "@mui/material";
import Slide from '@mui/material/Slide';
import Dialog from '@mui/material/Dialog';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


function PendingEarning() {
    const navigate = useNavigate();
    const [date, setDate] = useState({
        start: null,
        end: null,
    })

    console.log("date", date)
    const [open, setOpen] = useState(false)

    const [pagination, setPagination] = useState({
        page: 0,
        limit: 100
    });
    const [show, setShow] = useState(false);
    const [totalPage, setTotalPage] = useState(10)
    const [data, setData] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [userData, setUserData] = useState({
        'transId': "",
        'userId': "",
        "statusType": 0,
        "deduction": 0   //in percent
    })
    const [allProvider, setAllProvider] = useState([]);
    const [provider, setprovider] = useState({
        "name": "",
        "id": null
    })
    const [userId, setUserId] = useState("")
    const [userWinning, setUserWinning] = useState({
        winningAmt: 0,
        adminMessage: ""
    })

    const [isOpen, setIsOpen] = useState(false)

    console.log("provider", provider)


    const getPendingEarning = () => {
        setShow(false)
        let s_date, e_date
        if (date.start && date.end == null) {
            s_date = dateConvert(date?.start);
        }
        else if (date.start && date.end) {
            s_date = dateConvert(date?.start);
            e_date = dateConvert(date?.end);
        } else {
            s_date = null
            e_date = null
        }
        if (s_date > e_date) {
            alert("start date should be previous to end date")
            return
        }

        setOpen(false)
        setIsLoading(false)
        axiosClient
            .get(`/pendingearning?page=${pagination.page}&limit=${pagination.limit}&s_date=${s_date}&e_date=${e_date}&partnerId=${provider?.id}&userId=${userId}`)
            .then((res) => {
                setTotalPage(res.data?.totalPending)
                setData(res.data?.pendingEarning)
                setIsLoading(true)
            })
            .catch((err) => console.log(err))
    }

    const updatePendingEarning = async () => {
        if (!userData.statusType) {
            alert("Please select a valid option")
            return
        }

        if (!userData.deduction && userData.statusType === 1) {
            alert("Please Enter the deduction")
            setIsLoading(true)
            return
        }

        if (userData.statusType === 1 && (userData.deduction < 0 || userData.deduction > 100)) {
            alert("Deduction is always between 0 to 100")
            setIsLoading(true)
            return
        }
        setOpen(false)
        console.log("user", userData)
        await axiosClient.put(`/updatedPendingEarning`, userData)
            .then((res) => {
                console.log('res=>', res)
                if (res.data.status === true) {
                    alert(res.data.message)
                    getPendingEarning()
                    return
                }
            })
            .catch((err) => {
                alert(err.response.data.message)
                console.log("--error-->", err)
            })
    }

    const handlePanding = async () => {
        setIsLoading(false)
        let s_date, e_date
        if (date.start && date.end == null) {
            s_date = dateConvert(date?.start);
            e_date = null
        }
        else if (date.start && date.end) {
            s_date = dateConvert(date?.start);
            e_date = dateConvert(date?.end);
        } else {
            s_date = null
            e_date = null
        }

        if (s_date > e_date) {
            alert("start date should be previous to end date")
            setIsLoading(true)
            return
        }

        if (!s_date || !provider.id) {
            alert("Please Select The Partner and Date")
            setIsLoading(true)
            return
        }

        if (!userWinning?.adminMessage || !userWinning.winningAmt) {
            alert("Please Enter the deduction and adminMessage")
            setIsLoading(true)
            return
        }

        if (userWinning.winningAmt < 0 || userWinning.winningAmt > 100) {
            alert("Deduction is always between 0 to 100")
            setIsLoading(true)
            return
        }


        const sendData = {
            s_date: s_date,
            e_date: e_date,
            partnerId: provider.id,
            deduction: userWinning?.winningAmt,
            message: userWinning?.adminMessage
        };
        console.log("sendData", sendData)
        setIsOpen(false)
        await axiosClient
            .post(`/admin/pendingClear`, sendData)
            .then((res) => {
                alert(res.data.message)
                setUserWinning({
                    winningAmt: 0,
                    adminMessage: ""
                })
                setprovider({
                    "name": "",
                    "id": null
                })
                setDate({
                    start: null,
                    end: null,
                })
                getPendingEarning()
                // console.log("res of partner transaction", res)
            })
            .catch((error) => console.log(error))
            .finally(() => setIsLoading(true))
    }

    const getPartner = () => {
        axiosClient
            .get("/partnersList")
            .then((res) => setAllProvider(res.data.response))
            .catch((error) => console.log(error));
    }

    const handleBlockDelete = () => {
        setOpen(false)
    }

    const handleDelete = () => {
        setIsOpen(false);
    }

    useEffect(() => {
        getPartner()
    }, [])



    useEffect(() => {
        getPendingEarning()
    }, [pagination.page, pagination.limit])
    return (
        <>
            <SideBar handleSideBar={true} activeTab={"pendingEarning"} />
            <div className="screen">
                <div className='Pending_earn_flex'>

                    <div className='tSubnitButton' onClick={() => setIsOpen(true)} >Clear Pendings</div>

                    <div className='pending_earning_data'>
                        <span>
                            {
                                show ?
                                    <Cross onClick={() => setShow(false)} style={{ width: '20px', height: '17px' }} />
                                    : <Config onClick={() => setShow(true)} />
                            }
                        </span>

                        <div className={`pending_earnoing-main-div ${show && 'active'}`}>

                            <div className='pending_earning_box'>
                                <p>Provider</p>
                                <div style={{ width: "140px" }} >
                                    <Autocomplete
                                        disablePortal
                                        value={provider?.name || ''}
                                        onChange={(event, newValue) => {
                                            if (newValue) {
                                                setprovider({
                                                    id: newValue._id,
                                                    name: newValue.name
                                                })
                                            }
                                            else {
                                                setprovider({
                                                    id: null,
                                                    name: ""
                                                })

                                            }
                                        }}
                                        options={allProvider}
                                        getOptionLabel={(option) => option['name'] || option}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Provider"
                                            />
                                        )}
                                    />

                                </div>
                            </div>
                            <div className='pending_earning_box p_input_pad'>
                                <input
                                    type="text"
                                    placeholder='User Id'
                                    onChange={(e) => {
                                        setUserId(e.target.value)
                                    }}
                                />
                            </div>

                            <div className='pending_earning_box'>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label="Start Date"
                                        value={date?.start}
                                        onChange={(value) => { setDate({ ...date, start: value }) }}
                                        renderInput={(params) => <TextField {...params} />}
                                    // maxDate={date?.end}
                                    />
                                </LocalizationProvider>
                            </div>
                            <div className='pending_earning_box'>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label="End Date"
                                        value={date?.end}
                                        onChange={(value) => setDate({ ...date, end: value })}
                                        renderInput={(params) => <TextField {...params} />}
                                    // maxDate={date?.end}
                                    />
                                </LocalizationProvider>
                            </div>
                            <button className='partnerTSubnitButton' onClick={() => { getPendingEarning() }}>Submit</button>
                        </div>
                    </div>

                </div>



                <div className='profileInfoBox table'>
                    {isLoading ? <> {data?.length > 0 ?
                        <div style={{ width: '100%' }} className="container app-body">
                            <div className='tabel-scrol'>
                                <table id="users">
                                    <tr>
                                        <th style={{ width: '50px' }}>S.NO.</th>
                                        <th style={{ width: '100px' }}>UserName</th>
                                        <th style={{ width: '230px' }}>Email</th>
                                        <th style={{ width: '140px' }}>phoneNumber</th>
                                        <th style={{ width: '150px' }}>TransactionId</th>
                                        <th style={{ width: '210px' }}>UserID</th>
                                        <th style={{ width: '60px' }}>Amount</th>
                                        <th style={{ width: '100px' }}>Past Wallet</th>
                                        <th style={{ width: '100px' }}>OfferName</th>
                                        <th style={{ width: '150px' }}>Earning Type</th>
                                        <th style={{ width: '110px' }}>Provider</th>
                                        <th style={{ width: '100px' }}>statusType</th>
                                        <th style={{ width: '200px' }}>Time</th>
                                        <th style={{ width: '200px' }}>TransferEarning</th>
                                    </tr>
                                    {data?.map((val, key) => {
                                        return (<tr>
                                            <td>{(pagination.page * pagination.limit) + (key + 1)}</td>
                                            <td className='clickabuetd' onClick={() => window.open(`/users/transaction/${val?.userId?._id}`)}>{val?.userId?.name}</td>
                                            <td>{val?.userId?.emailId}</td>
                                            <td>{val?.userId?.phoneNumber}</td>
                                            <td>{val?.transactionId}</td>
                                            <td>{val?.userId?._id}</td>
                                            <td>{val?.amount}</td>
                                            <td>{val?.pastWallet?.toFixed(2)}</td>
                                            <td onClick={() => navigate(`/offer/${val?.offerId?._id}`)}>{val?.offerId?.name ? val?.offerId?.name : "null"}</td>
                                            <td>{val?.earning_type == 0 ? "Call Back" : "null"}</td>
                                            <td>{val?.statusType === 1 && val?.by_admin === false ? val?.partnerId?.name || "null" : val?.statusType === 2 && val?.by_admin === false ? val?.partnerId?.name || "Topupx" : val?.statusType === 3 && val?.by_admin === false ? val?.partnerId?.name : "Admin"}</td>
                                            <td>{val?.statusType === 1 ? "Credited" : val?.statusType === 2 ? "Debited" : val?.statusType === 3 ? "Pending" : "Null"}</td>
                                            <td>{moment.utc(val?.time).local().format("MMM Do YY, h:mm:ss a")}</td>
                                            <td><p className='TransactionBox' onClick={() => { setUserData({ transId: val?._id, userId: val?.userId?._id }); setOpen(true) }}>Transfer Earning</p></td>
                                        </tr>)
                                    })
                                    }
                                </table>
                            </div>

                            <div>
                                <Pagination
                                    filterDataInput={pagination}
                                    setFilterDataInput={setPagination}
                                    totalPage={totalPage}
                                />
                            </div>


                            {/* <div className='userTablePagination'>
                                <label>Rows per page:</label>
                                <select value={rowPerPage} onChange={(e) => { setRowPerPage(e.target.value); setCurrentPage(0) }}>
                                    <option value={10}>10</option>
                                    <option value={50}>50</option>
                                    <option value={100}>100</option>
                                    <option value={500}>500</option>
                                    <option value={1000}>1000</option>
                                    <option value={1500}>1500</option>
                                </select>
                                <span className='currentPagespan'> Current Page {currentPage + 1}/{Math.ceil(totalPage / rowPerPage)} </span>
                                {
                                    currentPage !== 0 &&
                                    (<button className='paginationButton' onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 0}>
                                        Previous
                                    </button>)
                                }

                                {
                                    Math.ceil(totalPage / rowPerPage) !== 1 && Math.ceil(totalPage / rowPerPage) != currentPage + 1 &&
                                    (<button className='paginationButton'
                                        onClick={() => setCurrentPage(currentPage + 1)}
                                        disabled={currentPage + 1 >= totalPage / rowPerPage}
                                    >
                                        Next
                                    </button>)
                                }
                            </div> */}

                        </div>
                        : <h1>No Data Found</h1>
                    }

                    </> :
                        <>
                            <div className='loaderImage'>
                                <img src={loader} alt='loader' />
                            </div>
                        </>
                    }
                </div>
            </div>


            <Dialog
                open={open}
                onClose={() => handleBlockDelete()}
                TransitionComponent={Transition}
                className="dialogs"
            >
                <div className='cross_d'>
                    <span onClick={() => setOpen(false)}>╳</span>
                </div>
                <div className='categoryEditBox'>

                    <p>
                        Are your Sure you want to send pending Earning
                    </p>
                    <div className='note_h_p'>
                        <h4>Note:</h4>
                        <p>Selecting "No" And Submiting will mark this transaction as "Invalid"</p>
                    </div>
                    <div className="p_dia_flex">
                        <div className='head_30'>
                            <p>Transfer</p>
                        </div>
                        <div className='d_main_70'>
                            <span>
                                <input type="radio"
                                    checked={userData?.statusType === 1}
                                    onChange={() => {
                                        setUserData({ ...userData, statusType: 1 })
                                    }}
                                />
                                <p>Yes</p>
                            </span>
                            <span>
                                <input type="radio"
                                    checked={userData?.statusType === 4}
                                    onChange={() => {
                                        setUserData({ ...userData, statusType: 4 })
                                    }}
                                />
                                <p>No</p>
                            </span>
                        </div>
                    </div>
                    {userData?.statusType === 1 && <div className='pen_input'>
                        <p>Deduction in percentage</p>
                        <input
                            type="number"
                            placeholder='Deduction'
                            className=''
                            value={userData?.deduction}
                            onChange={(e) => {
                                setUserData({ ...userData, deduction: e.target.value })
                            }}
                        />
                    </div>}

                    <div className='p_e_submit' onClick={() => { updatePendingEarning() }}>
                        <p>Submit</p>
                    </div>

                </div>
            </Dialog>


            <Dialog
                open={isOpen}
                onClose={() => handleDelete()}
                TransitionComponent={Transition}
                className="dialogs"
            >
                <div className='par_dialog_box'>
                    <>
                        <div className="part-dia-m-div">
                            <p>Deduction</p>
                            <input
                                type="number"
                                placeholder='winning'
                                className='p_input_box'
                                value={userWinning?.winningAmt}
                                onChange={(e) => {
                                    setUserWinning({ ...userWinning, winningAmt: e.target.value })
                                }}
                            />
                            <p>Admin Message</p>
                            <input
                                type="text"
                                className='p_input_box'
                                placeholder='message'
                                value={userWinning?.adminMessage}
                                onChange={(e) => {
                                    setUserWinning({ ...userWinning, adminMessage: e.target.value })
                                }}
                            />
                        </div>
                        {
                            <div className='editButton' onClick={() => { handlePanding() }}>Submit</div>
                        }
                    </>
                </div>
            </Dialog>


        </>
    )
}

export default PendingEarning