import React from 'react'

const InvalidUrlPage = () => {
    return (
        <>
            <div className="invalid-url-page">
                <h1>404</h1>
                <h3>Oops! Invalid Url: That page can't be found </h3>
            </div>
        </>
    )
}

export default InvalidUrlPage
