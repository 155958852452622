import React, { useEffect } from 'react'
import SideBar from './SideBar'
import axiosClient from '../axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function Partners() {
    const [partners, setPartners] = useState([]);

    const getPartners = () => {
        axiosClient
            .get("/partnersList")
            .then((res) => setPartners(res.data.response))
            .catch((error) =>
                console.log(error)
            );
    }

    const navigate = useNavigate();

    useEffect(() => {
        getPartners();
    }, [])

    return (
        <>
            <SideBar handleSideBar={true} activeTab={"partners"} />
            <div className="screen">
                <div className='addPartnerButton'>
                    <h2 className='screenHeading'>All Partners</h2>
                    <button className='tSubnitButton' onClick={() => navigate("/AddPartner")} >Add Partner</button>
                </div>

                <div className='brandsDisplayBox'>
                    {
                        partners.map((ele, index) => {
                            return (
                                <React.Fragment key={ele._id}>
                                    <div className='displayBox' onClick={() => window.open(`/partners/${ele._id}`)}>
                                        <img src={ele.image} alt="partnerLogoPic" />
                                        <p>{ele.name}</p>
                                    </div>
                                </React.Fragment>
                            )
                        })
                    }
                </div>
            </div>
        </>
    )
}

export default Partners