
import React, { useEffect, useState ,useCallback} from 'react'
import { ReactComponent as Edit } from "../assets/images/edit.svg";
import moment from 'moment';
import Pagination from './Pagination';
import axiosClient from '../axios';
import SideBar from './SideBar';
import Slide from '@mui/material/Slide';
import Dialog from '@mui/material/Dialog';
import { useNavigate } from 'react-router-dom';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function PayPal() {

    const [rowPerPage, setRowPerPage] = useState(100);
    const [currentPage, setCurrentPage] = useState(0);

    const [pagination, setPagination] = useState({
        page: 0,
        limit: 100
    });

    const [totalPage, setTotalPage] = useState(10)
    const [data, setData] = useState([])
    const [open, setOpen] = useState(false);
    const [fieldValue, setFieldValue] = useState({
        status: 0
    })

    const [editStatus, setEditStauts] = useState({
        paypalEmail: "",
        status: 0,
        message: "",
        amount: 0
    })

const navigate = useNavigate();

    function getPaypalData() {
        axiosClient
            .get(`/getPayPalData?status=${fieldValue?.status}&page=${currentPage}&limit=${rowPerPage}`)
            .then((res) => {
                setData(res?.data?.paypalData)
            })
            .catch((error) =>
                console.log(error)
            );
    }

    const getPayDetail = (e) => {
        let obj = { ...e }
        console.log("obj",e)
        setEditStauts(obj)

    }

    const updatedPayPalDetail = () => {
        const formData = {
            id: editStatus._id,
            status: editStatus?.status,
            message: editStatus?.message,
        }
        console.log({
            id: editStatus._id,
            status: editStatus?.status,
            message: editStatus?.message,
        })
        axiosClient
            .post(`/editedPaypalDetail`, formData)
            .then((res) => {
                    alert(res?.data?.message)
                    getPaypalData()
                
            })
            .catch((error) =>
                console.log(error)
            );
    }


    const toggleForm = () => {
        setOpen(!open);
    };
    const handleDelete = () => {
        setOpen(false);
    }

    const onFilterChange = useCallback((event) => {
        // setIsLoading(false)
        axiosClient
          .get(`/getPayPalData?searchValue=${event.target.value}`)
          .then((res) => {
           setData(res?.data?.paypalData)
          })
          .catch((error) =>
            console.log(error)
          );
      }, []);

    const handleFieldChange = (e) => setFieldValue({ ...fieldValue, status: e.target.value });

    useEffect(() => {
        getPaypalData()
    }, [fieldValue ,currentPage,rowPerPage])

    return (
        <>
            <SideBar handleSideBar={true} activeTab={"paypal"} />
            <div className="screen">
                <h1>PayPal</h1>
                <div className='paypalMain'>

                    <div className='selectFiltor'>
                        <select className='paypal-select' value={fieldValue?.status} onChange={handleFieldChange}>
                            <option value={0}>select Field</option>
                            <option value={1}>Approved</option>
                            <option value={2}>Reject</option>
                            <option value={3}>Credited</option>
                        </select>
                    </div>

                    <div className="paypalSearchBar">
                        <input
                            type="text"
                            placeholder="Search..."
                        onChange={onFilterChange}
                        />
                    </div>
                </div>

                <div className='profileInfoBox table'>
                    <>
                        <div style={{ width: '100%' }} className="container app-body">
                            <div className='tabel-scrol'>
                                <table id="users">
                                    <thead>
                                        <tr>
                                        <th style={{ width: '20px' }}>S.NO.</th>
                                        <th style={{ width: '75px' }}>Name</th>
                                        <th style={{ width: '130px' }}>Paypal Email</th>
                                        <th style={{ width: '70px' }}>Amount</th>
                                        <th style={{ width: '130px' }}>Date</th>
                                        <th style={{ width: '50px' }}>Status</th>
                                        <th style={{ width: '70px' }}>Message</th>
                                        <th style={{ width: '50px' }}>Update Status</th>
                                        <th style={{ width: '130px' }}>Updated At</th>
                                        </tr>
                                    </thead>
                                    {data?.map((val, key) => {
                                        return (
                                            <tbody key={key}>
                                                <tr>
                                                <td>{(rowPerPage * currentPage) + (key + 1)}</td>
                                                <td onClick={() => window.open(`/users/transaction/${val.user_id}`)} >{val?.name}</td>
                                                <td>{val?.paypalEmail}</td>
                                                <td>{val?.amount}</td>
                                                <td>{moment.utc(val?.time).local().format("MMM Do YY, h:mm:ss a")}</td>
                                                <td>{val?.status == 1? "Approved" :val?.status == 2 ?"Reject": val?.status == 3?"Credited":"" }</td>
                                                <td>{val?.message}</td>
                                                <td>{<Edit className='whiteRound' onClick={() => { getPayDetail(val); toggleForm() }} ></Edit>}</td>
                                                <td>{moment.utc(val?.updated_at).local().format("MMM Do YY, h:mm:ss a")}</td>
                                                </tr>
                                            </tbody>)
                                    })
                                    }
                                </table>
                            </div>

                            <div>
                                <Pagination
                                    filterDataInput={pagination}
                                    setFilterDataInput={setPagination}
                                    totalPage={totalPage}
                                />
                            </div>


                            {/* <div className='userTablePagination'>
                                <label>Rows per page:</label>
                                <select value={rowPerPage} onChange={(e) => { setRowPerPage(e.target.value); setCurrentPage(0) }}>
                                    <option value={10}>10</option>
                                    <option value={50}>50</option>
                                    <option value={100}>100</option>
                                    <option value={500}>500</option>
                                    <option value={1000}>1000</option>
                                    <option value={1500}>1500</option>
                                </select>

                                <span className='currentPagespan'> Current Page {currentPage + 1}/{Math.ceil(totalPage / rowPerPage)} </span>

                                {currentPage !== 0 && 
                                (<button className='paginationButton' onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 0}>
                                    Previous
                                </button>)}

                                {Math.ceil(totalPage / rowPerPage) !== 1 && Math.ceil(totalPage / rowPerPage) != currentPage+1 &&
                                (<button className='paginationButton'
                                    onClick={() => setCurrentPage(currentPage + 1)}
                                    disabled={currentPage + 1 >= totalPage / rowPerPage}
                                >
                                    Next
                                </button>)}
                            </div> */}
                            

                        </div>

                    </>
                </div>
            </div>

            <Dialog
                open={open}
                onClose={() => handleDelete()}
                TransitionComponent={Transition}
                className="dialogs"
            >
                <div className='categoryEditBox'>
                    <h3>Update Status</h3>
                    <div className="pRelative w300">
                        <input
                            type="email"
                            name="email"
                            disabled
                            placeholder='Enter Paypal Email'
                            onChange={(e) => {
                                setEditStauts({ ...editStatus, paypalEmail: e.target.value })
                            }}
                            value={editStatus?.paypalEmail}
                            className="paypalInput "
                        />
                    </div>
                    <div className='payPalselect'>
                        <span>Status</span>
                        <input
                            type="radio"
                            checked={editStatus.status === 1 ? true : false}
                            onChange={(e) => {
                                setEditStauts({ ...editStatus, status: 1 })
                            }}
                            value={editStatus?.status}

                        />
                        <span>Approved</span>

                        <input
                            type="radio"
                            checked={editStatus.status === 2 ? true : false}
                            onChange={(e) => {
                                setEditStauts({ ...editStatus, status: 2 })
                            }}

                            value={editStatus?.status}
                        />
                        <span>Reject</span>

                        <input
                            type="radio"
                            checked={editStatus.status === 3 ? true : false}
                            onChange={(e) => {
                                setEditStauts({ ...editStatus, status: 3 })
                            }}

                            value={editStatus?.status}
                        />
                        <span>Credited</span>
                    </div>
                    <div className="pRelative w300">
                        <input
                            type="text"
                            name="text"
                            placeholder='Enter Message'
                            onChange={(e) => {
                                setEditStauts({ ...editStatus, message: e.target.value })
                            }}
                            value={editStatus?.message}
                            className="paypalInput"
                        />
                    </div>
                    <div className="pRelative w300">
                        <input
                            type="text"
                            name="Amount"
                            placeholder='Amount'
                            disabled
                            value={editStatus?.amount}
                            className="paypalInput"
                        />
                    </div>

                    <div className='editButton' onClick={() => { setOpen(false); updatedPayPalDetail() }}>Submit</div>
                </div>
            </Dialog>
        </>
    )
}

export default PayPal