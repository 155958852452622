import React, { useState, useEffect } from 'react'
import SideBar from './SideBar'
import axiosClient from '../axios';
import { useNavigate } from 'react-router-dom';
import Pagination from './Pagination';
import Slide from '@mui/material/Slide';
import Dialog from '@mui/material/Dialog';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const ReferLinkStat = () => {
  const [referStat, setReferStat] = useState({})
  const [options, setOptions] = useState([])
  const [moreData, setMoreData] = useState({})
  const navigate = useNavigate();
  const [open, setOpen] = useState(false)

  const [pagination, setPagination] = useState({
    page: 0,
    limit: 10
  });
  const [totalLink, setTotalLink] = useState(10)


  const handleOpen = () => {
    setOpen(false)

  }

  const handleLinkReferal = () => {
    axiosClient.get(`/admin/referral_details?page=${pagination?.page}&limit=${pagination?.limit}`)
      .then((res) => {
        setReferStat(res?.data?.response)
      })
      .catch((error) => {
        console.log("error", error)
      })
  }

  const getOriginLink = () => {
    axiosClient.get(`/admin/origin_link?page=${pagination?.page}&limit=${pagination?.limit}`)
      .then((res) => {
        setOptions(res?.data?.response)
        setTotalLink(res?.data?.response?.length)
      })
      .catch((error) => console.log(error))
  }



  const handleMoreDetails = (id) => {
    axiosClient.get(`/admin/more_details?referThrough=${id}`)
      .then((res) => {
        console.log("response more Details", res)
        setMoreData(res?.data?.response[0])
      })
      .catch((err) => console.log(err))
  }

  useEffect(() => {
    handleLinkReferal()
  }, [])

  useEffect(() => {
    getOriginLink()
  }, [pagination?.page, pagination?.limit])

  return (
    <>
      <SideBar handleSideBar={true} activeTab={"referstat"} />
      <div className="screen">
        <div className="refer-stat-header">

          <div className="refer-stat-info-left">
            <h3>Total Data</h3>
            <div className='refer-stat-info'>
              <div className="refer-stat-para">
                <p className='card-box-para'>{referStat?.totalClick}</p>
                <p>Total Click</p>
              </div>
              <div className="refer-stat-para">
                <p className='card-box-para'>{referStat?.totalDownload}</p>
                <p>Total Download</p>
              </div>
              <div className="refer-stat-para">
                <p className='card-box-para'>{referStat?.totalRegister}</p>
                <p>Total Register</p>
              </div>
            </div>

          </div>

          <div className='profileInfoBox table'>
            <>
              <div style={{ width: '100%' }} className="container app-body">
                <div className='tabel-scrol'>
                  <table id="users">
                    <thead>
                      <tr>
                        <th style={{ width: '20px' }}>S.NO.</th>
                        <th style={{ width: '75px' }}>id</th>
                        <th style={{ width: '75px' }}>User Name</th>
                        <th style={{ width: '75px' }}>Total Click</th>
                        <th style={{ width: '75px' }}>Total Download</th>
                        <th style={{ width: '75px' }}>Total Register</th>

                        <th style={{ width: '75px' }}>More Details</th>
                      </tr>
                    </thead>
                    {options?.map((option, key) => {
                      return (
                        <tbody key={key}>
                          <tr>
                            <td>{(key + 1)}</td>
                            <td className='clickabuetd' onClick={() => window.open(`/users/transaction/${option._id}`)}>{option?._id}</td>
                            <td>{option?.userName}</td>
                            <td>{option?.totalClick}</td>
                            <td>{option?.totalDownload}</td>
                            <td>{option?.totalRegister}</td>


                            <td>{<button
                              className="refer-stat-submit"
                              onClick={() => {
                                setOpen(true)
                                // navigate(`/logsmoredetails/${option?._id}`)
                                handleMoreDetails(option?._id)
                              }}
                            >
                              More Details
                            </button>}</td>
                          </tr>
                        </tbody>)
                    })
                    }
                  </table>
                </div>
              </div>
            </>
          </div>
        </div>

        <div>
          <Pagination
            filterDataInput={pagination}
            setFilterDataInput={setPagination}
            totalPage={totalLink}
          />
        </div>
      </div >

      <Dialog
        open={open}
        onClose={() => handleOpen()}
        TransitionComponent={Transition}
        className="dialogs"
      >
        <h3 className='heading-refer-card'>More Details</h3>
        <div className="card-refer-item">
          <div className="refer-stat-para">
            <p className='card-box-para'>{moreData?.totalEarning?.toFixed(2) || 0}</p>
            <p>Total Earning</p>
          </div>
          <div className="refer-stat-para">
            <p className='card-box-para'>{moreData?.totalTaskCompleted || 0}</p>
            <p>Total Task Completed</p>
          </div>
          <div className="refer-stat-para">
            <p className='card-box-para'>{moreData?.totalTaskPerformed || 0}</p>
            <p>Total Task Performed</p>
          </div>
          <div className="refer-stat-para">
            <p className='card-box-para'>{moreData?.userCount || 0
            }</p>
            <p>UserCount</p>
          </div>

          <div className="refer-stat-para">
            <p className='card-box-para'>
              {(moreData?.userCount ? (moreData.totalEarning / moreData.userCount).toFixed(2) : 0)}
            </p>
            <p>Average Earning</p>
          </div>
          <div className="refer-stat-para">
            <p className='card-box-para'>
              {(moreData?.totalReferEarning ? moreData.totalReferEarning.toFixed(2) : 0)}
            </p>
            <p>Total Refer Earning</p>
          </div>

        </div>
      </Dialog>
    </>
  )
}

export default ReferLinkStat
