import React, { useState ,useEffect} from 'react'
import SideBar from './SideBar'
import axiosClient from '../axios';



function ReferalLeaderBoard() {
  const [data, setData] = useState([])
  console.log("data ,data" ,data)

  const handleReferStat = ()=>{
    axiosClient.get(`/admin/leaderBoard/referals_earning`)
    .then((res)=>{
      console.log("res of referal Leader board",res)
      setData(res?.data?.response?.leaderBoard)
    })
    .catch((error)=>console.log(error))
  }

  useEffect(()=>{
    handleReferStat()
  },[])
  
  return (
    <>
    <SideBar handleSideBar={true} activeTab={"Leaderboard"} />
            <div className="screen">
                <div className='dFlex'>
                    <h2 className='screenHeading catHead'> Referal Leaderboard</h2>
                </div>
      <div className='profileInfoBox table'>
        <div style={{ width: '100%' }} className="container app-body">
          {data?.length > 0 ? <>

            <div className="tabel-scrol">
              <table id="users">
                <thead>
                  <tr>
                    <th>S.NO.</th>
                    <th>Total Refers</th>
                    <th>R_Name</th>
                    <th>R_Id"</th>
                    <th>Rank</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.map((val, key) => (
                    <tr key={key}>
                      <td>{(key + 1)}</td>
                      <td>{val?.totalRefers}</td>
                      <td>{val?.r_name}</td>
                      <td>{val?.r_id}</td>
                      <td>{val?.rank}</td>
                    </tr>
                  ))}
                </tbody>
              </table>

            </div>
          </> :
            <>
              <h4 className='h5Heading'>No Data Available</h4>
            </>
          }
        </div>

      </div>
      </div>
    </>
  )
}

export default ReferalLeaderBoard